import BaseApi from "./_baseApi";

import { doRequest } from "./_helpers";
import { createSearchParams } from "react-router-dom";
import { SearchParams } from "../types/appTypes";
import { CsvDataUrl, Id } from "../types/apiTypes";

export default class AdminWorkerShifts extends BaseApi {
  /**
   * @param workerId
   * @param searchParams
   */
  public findManyForWorker(workerId: Id, searchParams: SearchParams = {}): Promise<WorkerType[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/workers/${workerId}/shifts${query}`,
    });
  }

  /**
   * @param workerId
   * @param searchParams
   */
  public exportToCsv(workerId: Id, searchParams: SearchParams = {}): Promise<CsvDataUrl>  {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/workers/${workerId}/shifts/csv${query}`,
    });
  }
}