import { Pane, TextInput, TextInputField } from "evergreen-ui";
import { format, isBefore, isValid, parse } from 'date-fns';
import 'react-day-picker/dist/style.css';
import { ChangeEventHandler, useEffect, useState } from "react";
import { DayPickerPopover } from "./DateRangeInput";

export function useDatePickerLogic({ validationError, setValidationError, setDateValue, dateValue = null, toValue = null, disabled = false}) {
    const [dateInputValue, setDateInputValue] = useState<string | null>()
    const [toInputValue, setToInputValue] = useState<string | null>()

    useEffect(() => {
        if (!dateInputValue && dateValue) {
            setDateInputValue(format(dateValue, 'dd-MM-y'));
            validateFrom(dateValue);
        }

    }, [dateValue, toValue])

    const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setDateInputValue(e.target.value);

        const date = parse(e.target.value, 'dd-MM-y', new Date());

        if (!isValid(date)) {
            return;
        }

        setDateValue(date);
        validateFrom(date);
    };

    const validateFrom = (date: Date) => {
        return true;
    }

    const handleSelect = (value) => {
        setDateValue(value)
        setDateInputValue(format(value, 'dd-MM-y'))

        validateFrom(value);
    }

    return {
        dateInputValue,
        handleSelect,
        handleChange,
    }
}

const DatePicker = function({ dateValue, setDateValue, defaultMonth = new Date(), datePickerOptions = {}, disabled = false, ...rest }) {
    const [validationError, setValidationError] = useState<string | null>(null);

    const {
        handleSelect,
        handleChange,
        dateInputValue,
    } = useDatePickerLogic({
        validationError, setValidationError, dateValue, setDateValue,
    });
    
    return <Pane className={"flex items-center"}>
            <TextInput
                disabled={disabled}
                marginBottom={0}
                height={40}
                maxWidth={200}
                placeholder="dd-mm-yyyy"
                value={dateInputValue ?? ""}
                onChange={handleChange}
                isInvalid={!!validationError}
                {...rest}
            />

            <Pane>
                {!disabled &&
                    <DayPickerPopover
                        value={dateValue}
                        defaultMonth={defaultMonth}
                        setValue={handleSelect}
                        {...datePickerOptions}
                    />
                }
            </Pane>
        </Pane>
}

export default DatePicker;
