import { ChevronLeftIcon, IconButton } from 'evergreen-ui';
import { useNavigate } from "react-router-dom";

export default function BackButton({ navigationPath, onLeave = undefined }: { navigationPath: string, onLeave?: Function}) {
    const navigate = useNavigate();

    function onClick() {
        if (onLeave) {
            onLeave();
        } else {
            navigate(navigationPath)
        }
    }

    return <IconButton
        onClick={onClick}
        appearance="minimal"
        icon={ChevronLeftIcon}
        type="button"
    />
}