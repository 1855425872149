import { Alert, Button, Card, FormField, majorScale, Pane, TextInputField, } from "evergreen-ui";
import Block from "src/components/common/Block";
import { useContext, useEffect, useState } from "react";
import { useDoRequest } from "../../lib/request-hooks";
import { useApi } from "../../context/AxiosContext";
import { useSearchParams } from "react-router-dom";
import { OnBoardingContext } from "../../components/layouts/OnBoardingLayout";
import UserCreationForm from "./UserCreationForm";

function useRegisterTokenForm() {
    const { apiInstance } = useApi();

    const {
        handle,
        setIsLoading: setIsSubmitting,
        isLoading: isSubmitting,
        validationErrors,
        setValidationErrors,
        errorMessage,
    } = useDoRequest();

    const [ token, setToken] = useState<string>("");

    const doSubmit = async () => {
        const request = apiInstance!.invites.findOne(token);

        return await handle(request);
    }

    return {
        token,
        setToken,
        doSubmit,
        submitContext: {
            doSubmit,
            handle,
            setIsSubmitting,
            isSubmitting,
            errorMessage,
            validationErrors,
        },
    }
}

export default function RegisterTokenForm() {
    const {
        state,
        onCompleteTokenVerification,
        onCompleteUserCreation,
    } = useContext(OnBoardingContext);

    const hasInvite = !!state?.invite;

    return <Card elevation={1} className={"p-8"}>
        {!hasInvite && onCompleteTokenVerification &&
          <TokenVerificationCard
            onComplete={onCompleteTokenVerification}
          />
        }
        {hasInvite && onCompleteUserCreation &&
          <UserCreationForm
            onComplete={onCompleteUserCreation}
          />
        }
    </Card>
}

function TokenVerificationCard({ onComplete }) {
    const { apiInstance } = useApi();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        token,
        setToken,
        doSubmit,
        submitContext,
    } = useRegisterTokenForm();

    useEffect(() => {
        const token = searchParams.get('token');

        if (token) {
            setToken(token);

            const request = apiInstance!.invites.findOne(token);

            submitContext.handle(request).then((invite) => {
                onComplete({token, invite});
            })
        }
    }, [searchParams])

    const submit = async () => {
        const invite = await doSubmit();

        onComplete({token, invite});
    }

    return <Pane className={'flex flex-col gap-5 justify-center align-items'}>
        <Block className="flex flex-col">
            <Pane marginY={20}>
                <Alert>Your here because you've received an secret register token. Use that code to register here.
                </Alert>
            </Pane>

            <FormField label={"Register token"}>
                <TextInputField
                    marginBottom={0}
                    inputHeight={40}
                    name="Register token"
                    isInvalid={!!submitContext.validationErrors?.token}
                    validationMessage={submitContext.validationErrors?.token?.join(', ')}
                    label=""
                    placeholder="AXDEF ... "
                    value={token}
                    onChange={(e) => setToken(e.target.value)}
                />
            </FormField>
        </Block>

        {submitContext.errorMessage &&
          <Alert
            intent="danger"
            title={submitContext.errorMessage}
            marginBottom={32}
          />
        }

        <Block className={"flex justify-end"}>
            <Button
                onClick={submit}
                isLoading={!!submitContext.isSubmitting}
                height={majorScale(5)}>Validate token</Button>
        </Block>
    </Pane>
}