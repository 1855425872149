import PropTypes from 'prop-types';
import {
    Button, ButtonProps,
    CrossIcon,
    Heading,
    IconButton,
    Pane,
    SelectMenu,
    SelectMenuProps,
    Text
} from "evergreen-ui";
import { classNames } from "../../lib/functions";
import { ReactNode } from "react";
import { SelectOption } from "../../types/apiTypes";

interface SelectInputFieldProps extends Partial<Omit<SelectMenuProps, 'selected'>> {
    title: string;
    emptySelectText?: string;
    options: SelectOption[],
    selected: SelectOption | null,
    setSelected: (value: any) => void;
    validationMessage?: string;
    required?: boolean;
    children?: ReactNode;
    buttonProps?: ButtonProps;
}

const SelectInputField = function({ buttonProps = undefined, required = false, title, validationMessage = '', emptySelectText, options, selected, setSelected, ...rest }: SelectInputFieldProps) {
    return (
        <Pane className="flex flex-col">
            <SelectMenu
                title={title}
                options={options}
                selected={selected?.value}
                titleView={({ close }) => {
                    return (
                        <Pane
                            alignItems="center"
                            borderBottom="default"
                            boxSizing="border-box"
                            display="flex"
                            padding={8}
                        >
                            <Pane flex="1" display="flex" alignItems="center">
                                <Heading size={400}>{title}</Heading>
                            </Pane>
                            {selected &&
                              <Button
                                appearance="minimal"
                                height={24}
                                onClick={() => {
                                    setSelected(null);
                                    close()
                                }}>Clear</Button>
                            }
                            <IconButton icon={CrossIcon} appearance="minimal" height={24} onClick={close} />
                        </Pane>
                    )
                }}
                onSelect={(item) => setSelected(item)}
                {...rest}
            >
                <Button
                    paddingX={40}
                    height={40}
                    maxWidth={200}
                    className={
                    classNames(
                        validationMessage ? "!border-red-500" : ""
                    )}
                    {...buttonProps}
                ><Text className="!text-xs">{selected?.label || emptySelectText}</Text></Button>
            </SelectMenu>

            { validationMessage &&
                <Text color="red">{validationMessage}</Text>
            }
        </Pane>
    )
}

SelectInputField.propTypes = {
    title: PropTypes.string,
    emptySelectText: PropTypes.string,
    options: PropTypes.array,
    setSelected: PropTypes.func,
    selected: PropTypes.object
}


export default SelectInputField;
