import { IconButton, MenuIcon, Pane } from "evergreen-ui";
import { Outlet, useLocation } from "react-router-dom";
import SidebarLeft from "../common/SidebarLeft";
import MainNavigationMenu from "../nav/MainNavigationMenu";
import { useApp } from "../../context/AppContext";
import { useEffect, useLayoutEffect, useState } from "react";
import { classNames } from "../../lib/functions";
import { omit } from "lodash";

function CollapseActions({ isOpen, setIsOpen }) {
  const isOpenClasses = "";
  const isClosedClasses = "";

  const classes = classNames(isOpen ? isOpenClasses: isClosedClasses, "px-4 shadow bg-blue-50 py-3");

  return <Pane className={classes}>
    <Pane className="flex items-center" onClick={() => setIsOpen(true)} >
      <IconButton
        disabled={false}
        icon={MenuIcon}
        onClick={() => {
          setIsOpen(false);
        }}
        type="button"
      />
      <Pane className="px-5 max-w-[15rem] text-3xl">
        <img src={"/logo.png"}  alt="Grofalex logo" />
      </Pane>
    </Pane>
  </Pane>
}

export default function Layout() {
  const {
    sidebarIsOpen,
    setSidebarIsOpen,
    contentRef,
    layoutProps
  } = useApp();

  const location = useLocation();

  const layoutPropsCombined = {
    overflowX: 'auto',
    overflowY: 'auto',
    width: '100%',
    ...layoutProps,
  }

  const isOpenClasses = "sm:flex";
  const isClosedClasses = "";

  const classes = classNames(sidebarIsOpen ? isOpenClasses: isClosedClasses, "");
  const contentClasses = classNames(sidebarIsOpen ? "max-h-screen px-5 sm:px-0":  "pt-0");

  // close automatically sidebar when window is resized and gets too small
  useEffect(() => {
    function resize() {
      if (window.innerWidth <= 640 && sidebarIsOpen) {
        setSidebarIsOpen(false)
      } else if (window.innerWidth >= 640) {
        setSidebarIsOpen(true)
      }
    }

    window.addEventListener("resize", resize);

    return function cleanup() {
      window.removeEventListener('resize', resize)
    }
  }, []);

  // scroll to top on route change
  useLayoutEffect(() => {
    if (contentRef.current && layoutPropsCombined.scrollToTop) {
      contentRef.current.scrollTo({
        top: 0,
      });
    }
  }, [location.pathname]);

  return <Pane className={classes}>
      {!sidebarIsOpen &&
        <CollapseActions
          isOpen={sidebarIsOpen}
          setIsOpen={setSidebarIsOpen}
        />
      }

      {sidebarIsOpen &&
        <SidebarLeft isOpen={sidebarIsOpen} setIsOpen={setSidebarIsOpen}>
          <MainNavigationMenu />
        </SidebarLeft>
      }

      <Pane ref={contentRef} {...omit(layoutPropsCombined, 'scrollToTop')} className={contentClasses}>
        <Outlet />
      </Pane>
    </Pane>
}