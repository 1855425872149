import { useTranslation } from "react-i18next";
import { useReportForm } from "../../../../pages/worker-shifts/WorkerShiftReportPage";
import { FormField, Pane, Text, Select } from "evergreen-ui";
import { ReportFormFieldProps } from "src/types/appTypes";

const temperatureOptions = Array.from({ length: 70 }, (v, k) => k - 20).map((value) => ({
    value,
    label: value + "°C",
}));

export function Temperature({ reportKey, editMode }: ReportFormFieldProps) {
    const { t } = useTranslation();
    const { onChange, submitContext, formState } = useReportForm();

    function setValue(value) {
        onChange(reportKey.key, value);
    }

    return <Pane className="grow">
        <FormField
            validationMessage={submitContext.validationErrors?.temperature?.join(', ')}
            hint={editMode ? "in °C": null}
            label={t('report_fields.' + reportKey.key)}>
            { editMode ?
                <Select
                    disabled={!editMode}
                    onChange={e => setValue(e.target.value)}
                    //@ts-ignore
                    value={formState.reportFields.temperature?.value ?? 10}
                    height={40}
                    width="100%"
                >{ temperatureOptions.map((option) => (
                    <option key={option.value} value={ option.value }>
                        { option.label}
                    </option>
                )) }
                </Select>
                :
                <Text>{formState?.reportFields?.temperature?.value ?? 0 }°C</Text>
            }
        </FormField>
    </Pane>
}
