import { CrossIcon, Heading, IconButton, Pane, TextInputField } from "evergreen-ui";
import { DateRangeLogic, DayPickerPopover } from "../../../components/formfields/DateRangeInput";
import { useEffect, useState } from "react";
import { endOfMonth, format, formatISO, parseISO, startOfMonth } from "date-fns";
import { FilterMapType } from "../../../types/appTypes";
import { KeyObjectType } from "crypto";

interface DateRangeFilterProps {
    filterMap: FilterMapType;
    handleInput: any;
    startAt?: Date | null;
    endAt?: Date | null;
    defaultMonth?: Date
    datePickerOptions?: any;
    fromLabel?: string;
    toLabel?: string;
    fromDateFilter?: keyof FilterMapType,
    toDateFilter?: keyof FilterMapType,
}

export default function DateRangeFilter({ filterMap, handleInput, startAt, endAt, datePickerOptions, defaultMonth, fromLabel, toLabel, fromDateFilter, toDateFilter, }: DateRangeFilterProps) {
    const [ validationErrors, setValidationErrors ] = useState({});

    const [fromDate, setFromDate] = useState<Date | null | undefined>(startAt ?? startOfMonth(new Date()))
    const [toDate, setToDate] = useState<Date | null | undefined>(endAt ?? endOfMonth(new Date()))

    useEffect(() => {
        if (!startAt) {
            setFromDate(null);
        }

        if (!endAt) {
            setToDate(null);
        }
    }, [startAt, endAt]);

    function setFromDateFilter(value) {
        if (value) {
            setFromDate(value);
        }

        let newValue = value ? formatISO(value) : null;

        const fromDateFilterKey = fromDateFilter ?? filterMap.fromDate;

        if (!newValue) {
            // @ts-ignore
            newValue = fromDateFilterKey.default;

            if (newValue) {
                const date = parseISO(newValue);

                setFromDate(date)
                setFromInputValue(format(date, 'dd-MM-yy'))
            }
        }

        handleInput(fromDateFilter, newValue);
    }

    function setToDateFilter(value) {
        if (value) {
            setToDate(value)
        }

        let newValue = value ? formatISO(value) : null;

        const toDateFilterKey = toDateFilter ?? filterMap.toDate;

        if (!newValue) {
            // @ts-ignore
            newValue = toDateFilterKey.default;

            if (newValue) {
                const date = parseISO(newValue);

                setToDate(date);
                setToInputValue(format(date, 'dd-MM-yy'))
            }
        }

        handleInput(toDateFilterKey, newValue);
    }

    const {
        setFromInputValue,
        setToInputValue,
        fromInputValue,
        toInputValue,
        handleFromChange,
        handleToChange,
        handleFromSelect,
        handleToSelect,
        validateFormat,
    } = DateRangeLogic({
            validationErrors,
            setValidationErrors,
            fromValue: fromDate,
            setFromValue: setFromDateFilter,
            toValue: toDate,
            setToValue: setToDateFilter,
        }
    );

    return <Pane className="pl-2 flex gap-2">
        <Pane className="flex flex-col gap-1">
            <Pane className="flex justify-between h-4">
                <Heading className={fromDate ? "!text-black !font-bold": ""} size={200}>{fromLabel ?? 'Vanaf:'}</Heading>

                {fromDate &&
                  <IconButton
                    icon={CrossIcon}
                    appearance="minimal" height={24}
                    onClick={() => setFromDateFilter(null)}
                  />
                }
            </Pane>

            <Pane className="flex gap-1">
                <TextInputField
                    key={fromDate?.toDateString()}
                    marginBottom={0}
                    inputHeight={40}
                    marginTop={-8}
                    onBlur={() => fromDate ? handleFromSelect(fromDate): null}
                    label=""
                    maxWidth={200}
                    placeholder="dd-mm-yyyy"
                    value={fromInputValue ?? ""}
                    onChange={handleFromChange}
                />

                <Pane className={"pt-2"} >
                    <DayPickerPopover
                        value={fromDate}
                        defaultMonth={defaultMonth}
                        setValue={handleFromSelect}
                        {...datePickerOptions}
                    />
                </Pane>
            </Pane>
        </Pane>

        <Pane className="flex flex-col gap-1">
            <Pane className="flex justify-between h-4">
                <Heading className={toDate ? "!text-black !font-bold": ""} size={200}>{toLabel ?? 'Tot en met:'}</Heading>

                {toDate &&
                  <IconButton
                    icon={CrossIcon}
                    appearance="minimal"
                    height={24}
                    onClick={() => setToDateFilter(null)}
                  />
                }
            </Pane>

            <Pane className="flex gap-1">
                <TextInputField
                    key={toDate?.toDateString()}
                    marginBottom={0}
                    inputHeight={40}
                    marginTop={-8}
                    onBlur={() => toDate ? handleToSelect(toDate): null}
                    label=""
                    maxWidth={200}
                    placeholder="dd-mm-yyyy"
                    value={toInputValue ?? ""}
                    onChange={handleToChange}
                />

                <Pane className={"pt-2"} >
                    <DayPickerPopover
                        value={toDate}
                        defaultMonth={defaultMonth}
                        setValue={handleToSelect}
                        {...datePickerOptions}
                    />
                </Pane>
            </Pane>
        </Pane>
    </Pane>
}

