import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { ActivityType, Timeslot } from "../types/apiTypes";

export default class Resources extends BaseApi {
  findAllActivityTypeOptions(): Promise<ActivityType[]> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/resources/activity-type-options`
    });
  }
  findAllTimeslots(): Promise<Timeslot[]> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/resources/timeslots`
    });
  }
}