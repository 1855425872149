import { Card, Heading, Link, Pane, Text } from "evergreen-ui";
import { ShiftReport } from "src/types/apiTypes";

import { classNames } from "src/lib/functions";
import { useTranslation } from "react-i18next";
import { isEmpty } from 'lodash';
import { useMemo } from "react";
import { __r, REPORTS_DETAIL_PAGE } from "../../../RouteMap";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import DateValue from "../../../components/common/DateValue";

export default function AreaResultsTable({ areas, template, ...rest }) {
    return <Pane className="flex gap-2 flex-wrap lg:flex-nowrap">
            <Pane className="pb-2">
                {areas && template &&
                  <ResultsTable
                    template={template}
                    areas={areas}
                  />
                }
            </Pane>
        {/*))}*/}
    </Pane>
}

function ResultsTable({areas, template, ...rest }) {
    return <Pane {...rest} className="planner-rounds-table w-full table">
        <AreaResultsTableHeaderRow template={template} />

        { areas.map((area, index) => (
            <AreaResultsTableRow
                key={'area-table-row-' + area.id + index}
                index={index}
                area={area}
                template={template}
            />
        )) }
    </Pane>
}

function AreaResultsTableHeaderRow({ template, ...rest }) {
    return (
        <Pane className="planner-header-row py-5">
            {/* the first cell with the topic */}
            <AreaResultsHeaderCell key={'header-topic'} className="planner-th planner-header-topic">
                <Heading size={100}>
                    Activity / Activity
                </Heading>
            </AreaResultsHeaderCell>
        </Pane>
    )
}

function AreaResultsHeaderCell(props) {
    const { children, className, ...rest } = props;
    const classes = classNames(className, "py-2 px-2 text-center")

    return <Pane className={classes} {...rest}>
        { children }
    </Pane>
}

/**
 * @param rounds
 * @param area
 * @constructor
 */
function AreaResultsTableRow({ area, index, template }) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const areaActivityTypes = useMemo(() => {
        return area.areaActivityTypes.filter(v => !isEmpty(v.shiftReports))
    }, [area])

    function navToReport(report: ShiftReport) {
        navigate({
            pathname: __r(REPORTS_DETAIL_PAGE, { shiftReportId: report.id }),
        }, {
            state: {
                backPath: window.location.pathname + window.location.search
            }
        })
    }

    return <>
        <Pane className="tr planner-table-topic-row bg-gray-50">
            {/* the first cell with the area name and projct */}
            <Pane className="planner-table-topic-row-cell planner-table-cell w-[5rem] bg-white text-center border-b border-t border-l py-2">
                <Text className="!font-bold !text-xs">
                    { area.name }
                </Text>

                <Heading size={100}>{area.project.name}</Heading>
            </Pane>

            {index === 0 &&
              <Pane
                className="planner-table-topic-row-cell planner-table-cell w-[5rem] bg-white text-center border-b border-t border-l py-2">
                <Text className="!text-xs !font-bold">
                  Veldwerker
                </Text>
              </Pane>
            }

            {index === 0 &&
              <>
                <Pane
                  className="planner-table-topic-row-cell planner-table-cell w-[5rem] bg-white text-center border-b border-t border-l py-2">
                  <Text className="!text-xs !font-bold">
                    Datum
                  </Text>
                </Pane>
              </>
            }

            {index === 0 &&
              <>
                <Pane
                  className="planner-table-topic-row-cell planner-table-cell w-[5rem] bg-white text-center border-b border-t border-l py-2">
                  <Text className="!text-xs !font-bold">
                    Veldbezoek
                  </Text>
                </Pane>
              </>
            }

            {index === 0 && template.keys.map((field) => (
                <>
                    <Pane key={field.key} className="planner-table-topic-row-cell planner-table-cell w-[5rem] bg-white text-center border-b border-t border-l py-2">
                        <Text className="!text-xs !font-bold">
                            {t('report_fields.' + field.key)}
                        </Text>
                    </Pane>
                </>
            ))}


            {index === 0 &&
              <Pane
                className="planner-table-topic-row-cell planner-table-cell w-[5rem] bg-white text-center border-b border-t border-l py-2">
                <Text className="!text-xs !font-bold">
                  Rapport
                </Text>
              </Pane>
            }

        </Pane>

        { areaActivityTypes.map((entry, index) => (
            entry.shiftReports.map((report) => (
                <Card key={'row-shift-report-' + entry.id + report.id} className="planner-table-row px-5 w-full">
                    <Pane className='table-cell planner-table-row-cell planner-table-cell text-center px-2 py-2 border-b border-l bg-white items-center'>
                        <Text>
                            { entry.activityType.name }
                        </Text>
                    </Pane>

                    <Pane className='table-cell planner-table-row-cell planner-table-cell border-t text-center px-2 py-2 border-b border-l bg-white items-center'>
                        <Text className="!text-xs">
                            { report.worker.user.firstName} {report.worker.user.lastName}
                        </Text>
                    </Pane>

                    <Pane className='table-cell planner-table-row-cell planner-table-cell border-t text-center px-2 py-2 border-b border-l bg-white items-center'>
                        <Text className="!text-xs">
                            { format(parseISO(report.startedAt), 'dd-MM-yy') }
                        </Text>
                    </Pane>

                    <Pane className='table-cell planner-table-row-cell planner-table-cell border-t text-center px-2 py-2 border-b border-l bg-white items-center'>
                        <Text className="!text-xs">
                            <DateValue date={report.startedAt} formatStr='p' />
                            -
                            <DateValue date={report.endedAt} formatStr='p' />
                        </Text>
                    </Pane>

                    { template.keys.map((field) => (
                    <Pane className="table-cell planner-table-row-cell planner-table-cell  border-t text-center px-2 py-2 border-b border-l bg-white items-center">
                        <Text className="!text-xs truncate ...">
                            { report.reportFields[field.key]?.valueFormatted || "-"}
                        </Text>
                    </Pane>
                    ))}

                    <Pane className='table-cell planner-table-row-cell planner-table-cell border-t text-center px-2 py-2 border-b border-l bg-white items-center'>
                        <Link onClick={() => navToReport(report)} className="!text-xs hover:cursor-pointer">
                            Rapport inzien
                        </Link>
                    </Pane>
                </Card>
            ))
        ))}
    </>
}
