import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { PostData, SearchParams } from "../types/appTypes";
import { Area, CsvDataUrl, Id } from "../types/apiTypes";

export default class AdminAreas extends BaseApi {
    findOne(id: Id): Promise<Area> {
      return doRequest(this._axiosInstance!, {
        method: 'get',
        url: `/admin/areas/${id}`
      });
    }

    update(id: Id, data: PostData): Promise<Area> {
        return doRequest(this._axiosInstance!, {
            method: 'put',
            url: `/admin/areas/${id}`,
            data: data,
        });
    }

    createForProject(projectId: Id, data: PostData): Promise<Area> {
      return doRequest(this._axiosInstance!, {
        method: 'post',
        url: `/admin/projects/${projectId}/areas`,
        data: data,
      });
    }

    findManyResults(searchParams : SearchParams = {}): Promise<Area[]> {
        const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

        return doRequest(this._axiosInstance!, {
            method: 'get',
            url: `/admin/areas/results${query}`,
        });
    }

    exportResultsCsv(searchParams : SearchParams = {}): Promise<CsvDataUrl> {
        const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

        return doRequest(this._axiosInstance!, {
            method: 'post',
            url: `/admin/areas/results/csv${query}`,
        });
    }

    findManyForProject(projectId: Id, searchParams: SearchParams = {}): Promise<Area[]> {
      const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

      return doRequest(this._axiosInstance!, {
        method: 'get',
        url: `/admin/projects/${projectId}/areas${query}`,
      });
    }

    remove(id: Id): Promise<{ success: boolean}> {
      return doRequest(this._axiosInstance!, {
        method: 'delete',
        url: `/admin/areas/${id}`,
      });
    }
}