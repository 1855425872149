import { useLoadResource } from "src/lib/request-hooks";
import { parseISO } from "date-fns";
import { useApi } from "../context/AxiosContext";
import { useState } from "react";
import { Timeslot } from "../types/apiTypes";
import { isEmpty, mapKeys } from 'lodash';

export type TimeslotMapById = {
    [key: string]: Timeslot;
}

export default function useTimeslotResources() {
    const { apiInstance } = useApi();
    const [ timeslots, setTimeslots ] = useState<Timeslot[]>([]);
    const [ timeslotMapById, setTimeslotMapById ] = useState<TimeslotMapById>({});

    const find = () => apiInstance!.resources.findAllTimeslots();

    function setData(timeslots) {
        const value = timeslots.map((timeslot) => {
            return {
                ...timeslot,
                ...(timeslot.availableFrom ? {availableFrom: parseISO(timeslot.availableFrom)} : {}),
                ...(timeslot.availableUntil ? {availableUntil: parseISO(timeslot.availableUntil)} : {}),
            }
        })

        setTimeslots(value);
        setTimeslotMapById(mapKeys(value, 'id'))
    }

    return {
        ...useLoadResource(find, setData, isEmpty(!timeslots)),
        timeslots,
        timeslotMapById,
    };
}
