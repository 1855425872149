import { useApi } from "../context/AxiosContext";
import { useState } from "react";
import { useLoadResource } from "../lib/request-hooks";
import { ActivityTypeOption } from "../types/apiTypes";

export default function useActivityTypeOptions() {
    const { apiInstance } = useApi();
    const [ activityTypeOptions, setActivityTypeOptions ] = useState<ActivityTypeOption[]>([]);

    const find = () => apiInstance!.resources.findAllActivityTypeOptions();

    return {
        ...useLoadResource(find, setActivityTypeOptions, !activityTypeOptions.length),
        activityTypeOptions,
    };
}

