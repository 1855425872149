import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { AreaActivityType, Id } from "../types/apiTypes";

export default class AdminAreaActivityTypes extends BaseApi {
  findOne(id: Id): Promise<AreaActivityType> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/area-activity-types/${id}`
    });
  }
}