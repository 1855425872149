import { Heading, Pane, SearchInput } from "evergreen-ui";
import { useState } from "react";
import useDelayedAction from "src/hooks/useDelayedAction";
import { FilterProps } from "./types";

export default function AddressFuzzyFilter({ filterMap, handleInput }: FilterProps) {
    const [name, setName] = useState<string>('');

    const delayedAction = useDelayedAction(200);

    function searchAddress(name: string) {
        delayedAction(() => {
            if (name) {
                handleInput(filterMap.addressName, name)
            } else {
                handleInput(filterMap.addressName, null)
            }

        })

        setName(name)
    }

    return <Pane className="flex flex-col gap-1">
        <Pane className="flex justify-between h-4">
            <Heading className={name ? "!text-black !font-bold": ""} size={200}>
                Naam gebied
            </Heading>
        </Pane>
        <SearchInput
            onChange={(e) => searchAddress(e.target.value)}
            value={name}
            size="small"
            height={38}
            placeholder="..."
        />
    </Pane>
}

