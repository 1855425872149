import { Card, EmptyState, EyeOpenIcon, majorScale, SearchIcon, } from "evergreen-ui";
import PageTitle from "../../components/common/PageTitle";
import { useApi } from "../../context/AxiosContext";
import { useState } from "react";
import { useLoadResource } from "../../lib/request-hooks";
import { WorkerShift } from "../../types/apiTypes";
import Page from "../../components/common/Page";
import { __r, WORKER_SHIFTS_DETAIL_PAGE } from "../../RouteMap";
import { useNavigate } from "react-router-dom";
import useTimeslotResources from "../../hooks/useTimeslotResources";
import { addMonths } from "date-fns";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { TableLoadingSpinner } from "../../components/table/StandardTable";
import Actions from "../../components/common/Actions";
import { SunTimesDialog } from "../worker-availability/_components/SunTimesDialog";
import { WorkerShiftDetails } from "./_components/WorkerShiftDetails";

function useWorkerShifts() {
    const { apiInstance } = useApi();

    const searchParams = new URLSearchParams(window.location.search);
    const [shifts, setShifts] = useState<WorkerShift[]>([]);

    const find = () => apiInstance!.workerShifts.findMany(searchParams);

    return {
        ...useLoadResource(find, setShifts, true),
        shifts,
    }
}

export default function WorkerShiftsListPage() {
    const { timeslotMapById } = useTimeslotResources();
    const { shifts, isLoading } = useWorkerShifts();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [sunTimesDialogIsOpen, setSunTimesDialogIsOpen] = useState<boolean>(false);

    function navigateToShiftDetailPage(workerShift: WorkerShift) {
        navigate(__r(WORKER_SHIFTS_DETAIL_PAGE, { shiftId: workerShift.originalShiftEntryId }));
    }

    return <Page>
        <SunTimesDialog
            isOpen={sunTimesDialogIsOpen}
            close={() => setSunTimesDialogIsOpen(false)}
            fromDate={new Date()}
            toDate={addMonths(new Date(), 3)}
        />
        <PageTitle
            actions={
                <Actions>
                    <Actions.Button
                        textOverflow={"ellipsis"}
                        onClick={() => setSunTimesDialogIsOpen(true)}
                        iconBefore={EyeOpenIcon}
                        height={majorScale(4)}>{t('worker_availability_page.sun_times')}
                    </Actions.Button>
                </Actions>
            }
        >
            Geplande diensten
        </PageTitle>

        { shifts.map((workerShift) => (
            <WorkerShiftDetails
                elevation={1}
                onClick={() => navigateToShiftDetailPage(workerShift)}
                workerShift={workerShift}
                timeslotMapById={timeslotMapById}
                showMap={false}
            />
        ))}

        {isLoading &&
          <TableLoadingSpinner title={"Geplande diensten ophalen..."}/>
        }

        { isLoading === false && isEmpty(shifts) && <EmptyShiftsState /> }
    </Page>
}

function EmptyShiftsState () {
    return <Card elevation={1} padding={16} marginBottom={16}>
        <EmptyState
            background="light"
            title="Je bent nog voor geen enkele dienst ingepland."
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="Als de dienstverantwoordelijke je inplant, dan zie je hier je diensten"
        />
    </Card>
}

