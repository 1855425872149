import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { ApiAxiosResponse, Id, ShiftReport } from "../types/apiTypes";
import { PostData, SearchParams } from "../types/appTypes";

export default class AdminShiftReports extends BaseApi {
  findOne(id: Id): Promise<ShiftReport> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/shifts/reports/${id}`
    });
  }

  update(id: Id, data: PostData): Promise<ShiftReport> {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/admin/shifts/reports/${id}`,
      data: data,
    });
  }

  reject(shiftReportId: Id, data: PostData): Promise<ShiftReport> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/shifts/reports/${shiftReportId}/reject`,
      data: data,
    });
  }

  approve(shiftReportId: Id, data: PostData): Promise<ShiftReport> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/shifts/reports/${shiftReportId}/approve`,
      data: data,
    });
  }

  findMany(searchParams: SearchParams = {}): Promise<ShiftReport[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/shifts/reports${query}`
    });
  }
}