import { AreaActivityType } from "../../types/apiTypes";
import { Checkbox, Pane, Paragraph, Spinner, TextInputField } from "evergreen-ui";
import { useActivityTypes } from "../../pages/projects/activity-types/ActivityTypesPage";
import { isEmpty } from 'lodash';
import { memo, useEffect, useMemo } from "react";

export type ActivityTypesSelectProps = {
    projectId: string,
    selected: Partial<AreaActivityType>[],
    setSelected: Function,
    enableAll: boolean,
}

const ActivityTypesSelect = memo(function ActivityTypesSelect({ selected = [], setSelected, enableAll = false }: ActivityTypesSelectProps) {
    const {
        activityTypes,
        isLoading,
    } = useActivityTypes();

    const areaActivityTypes = useMemo(() => {
        return activityTypes.map(v => {
            return {
                ...v,
            }
        })
    }, [activityTypes, selected]);

    function setChecked(isChecked, item) {
        if (isChecked) {
            const newArr = selected.filter((v => v.id !== item.id));
            setSelected(newArr);
        } else {
            setSelected([...selected, item]);
        }
    }

    function setNWorkers(item, value) {
        if (value === '') {
            value = 0;
        }

        item.nWorkers = parseInt(value);
        setSelected([...selected]);
    }

    function selectedItem(item) {
        return selected.find((v => v.id === item.id));
    }

    useEffect(() => {
        if (enableAll && isLoading === false && !isEmpty(areaActivityTypes)) {
            setSelected([...areaActivityTypes]);
        }
    }, [ isLoading, enableAll ])

    return <Pane>
        {isLoading === true &&
          <Pane className="flex justify-end">
            <Spinner size={20}/>
          </Pane>
        }
        <Pane className="flex flex-col gap-3">
            {!isEmpty(areaActivityTypes) &&
                areaActivityTypes.map((item, index) => (
                    <AreaActivityTypeItem
                        key={index}
                        areaActivityType={item}
                        setChecked={setChecked}
                        selectedItem={selectedItem(item)}
                        // isChecked={isChecked(item)}
                        setNWorkers={setNWorkers}
                    />
                ))
            }
        </Pane>
    </Pane>
});

function AreaActivityTypeItem({areaActivityType, setChecked, selectedItem, setNWorkers}) {
    return <Pane className="cursor-pointer" onClick={() => setChecked(!!selectedItem, areaActivityType)} key={areaActivityType.id}>
        <Pane className="flex w-full items-center border h-[64px] pl-3">
            <Pane className="flex w-[90%] gap-2 justify-between items-center">
                <Pane className="min-w-[10rem] max-w-[10rem] flex gap-2 items-center" >
                    <span className="font-bold text-sm text-gray-700">
                        {areaActivityType.name}
                    </span>
                </Pane>
                <Paragraph className="flex-6">
                    # Rounds <span className="font-bold text-gray-700">{areaActivityType.numberOfRounds}</span>
                </Paragraph>
                <Paragraph className="flex-6">
                    # Days Between <span
                    className="font-bold text-gray-700">{areaActivityType.minDaysBetweenRounds}</span>
                </Paragraph>
                <TextInputField
                    onClick={(e) => e.stopPropagation()}
                    disabled={!selectedItem}
                    value={selectedItem?.nWorkers}
                    onChange={(e) => setNWorkers(selectedItem, e.target.value)}
                    marginBottom={0}
                    inputHeight={40}
                    maxWidth={50}
                />
            </Pane>
            <Checkbox
                className="flex-2 mx-3"
                marginLeft="auto"
                checked={!!selectedItem}
                onClick={(e) => (e.preventDefault())}
            />
        </Pane>
    </Pane>
}

export default ActivityTypesSelect;
