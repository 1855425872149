import {
  Heading,
  Text,
  Pane,
  StatusIndicator,
  Tab,
  TabNavigation,
  SettingsIcon,
  BriefcaseIcon,
  MapMarkerIcon, useTheme, IconButton, ChevronLeftIcon
} from "evergreen-ui";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import Block from "../common/Block";
import { useApi } from "../../context/AxiosContext";
import { useLoadResource } from "../../lib/request-hooks";
import { useCallback, useState } from "react";
import { Project } from "../../types/apiTypes";
import TopSubNav from "../common/TopSubNav";
import Page from "../common/Page";

const useProject = function () {
  const { projectId } = useParams();
  const [ project, setProject ] = useState<Project>();
  const { apiInstance } = useApi();

  const find = () => apiInstance!.adminProjects.findOne(projectId!);

  const setData = (project) => {
    setProject(project);
    return Promise.resolve();
  }

  return {
    ...useLoadResource(find, setData, !!projectId),
    project
  };
}

export default function ProjectLayout() {
  const { projectId } = useParams();
  const theme = useTheme();
  const { project } = useProject();

  const menuItems = [
    {
      label: 'Gebieden',
      pathname: `/projects/${projectId}/areas`,
      icon: <MapMarkerIcon />
    },
    {
      label: 'Activiteiten',
      pathname: `/projects/${projectId}/activity-types`,
      icon: <BriefcaseIcon />
    },
    {
      label: 'Project Settings',
      pathname: `/projects/${projectId}/settings`,
      icon: <SettingsIcon />
    },
  ];

  return (
      <Pane className={"py-2 pt-5"}>
        <Pane className="px-5 flex gap-1">
          <Link to={"/projects"}>
            <Pane className="flex">
              <IconButton
                  appearance="minimal"
                  icon={ChevronLeftIcon}
                  type="button"
              />
              <Heading size={800} color={theme.colors.gray900} className="hover:underline cursor-pointer">
                Projects
              </Heading>
            </Pane>
          </Link>
          <Heading size={800}>/</Heading>
          <Heading className="!text-2xl" size={100}>{project?.name}</Heading>
        </Pane>

        <TopSubNav marginY={20}
            menuItems={menuItems} />

        <Page>
          <Outlet />
        </Page>
      </Pane>
  );
}