import { ReactNode, useRef, useState } from "react";
import { AppContext } from "../context/AppContext";

export type AxiosProviderProps = {
  children?: ReactNode;
}

export function AppProvider({ children }: AxiosProviderProps) {
  const [initialized, setInitialized] = useState<boolean>(false);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const [sidebarIsOpen, setSidebarIsOpen] = useState(window.innerWidth >= 640);
  const [layoutProps, setLayoutProps] = useState({});

  return <AppContext.Provider value={{ sidebarIsOpen, setSidebarIsOpen, layoutProps, setLayoutProps, initialized, setInitialized, contentRef }}>
    {children}
  </AppContext.Provider>
}