import { useTranslation } from "react-i18next";
import { useReportForm } from "../../../../pages/worker-shifts/WorkerShiftReportPage";
import { FormField, Pane, Text, Select } from "evergreen-ui";
import { ReportFormFieldProps } from "src/types/appTypes";

const cloudinessOptions = Array.from({ length: 5 }, (v, k) => k).map((value) => ({
    value: value * 25,
    label: value * 25 + "%",
}));

export function Cloudiness({ reportKey, editMode }: ReportFormFieldProps ) {
    const { t } = useTranslation();
    const { onChange, submitContext, formState } = useReportForm();

    function setValue(value) {
        onChange(reportKey.key, value);
    }

    return <Pane className="grow">
        <FormField
            validationMessage={submitContext.validationErrors?.cloudiness?.join(', ')}
            hint={editMode ? "in %": null}
            label={t('report_fields.' + reportKey.key)}>
            {editMode ?
                <Select
                    disabled={!editMode}
                    onChange={e => setValue(e.target.value)}
                    isInvalid={!!submitContext.validationErrors?.cloudiness}
                    value={formState?.reportFields?.cloudiness?.value ?? 0}
                    height={40}
                    width="100%"
                >{cloudinessOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
                </Select>
                :
                <Text>{formState?.reportFields?.cloudiness?.value ?? 0}%</Text>
            }
        </FormField>
    </Pane>
}

