/**
 *
 * @param classes
 * @returns {string}
 */
import SunCalc from 'suncalc';

import { addDays, addHours, addMinutes, formatDistance, isAfter, isBefore } from "date-fns";
import { AreaActivityType, JwtDecoded, Shift, Timeslot } from "../types/apiTypes";
import { createSearchParams } from "react-router-dom";
import { TimeslotMapById } from "../hooks/useTimeslotResources";
import { ShiftAugmented } from "../pages/planner/_components/PlannerRoundsItem";
import { isEmpty } from "lodash";

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const interpolateMinutes = (minutes, precision = 5) => {
    return Math.round(minutes / precision) * precision;
}

export function shortenString(string, maxLength = 20) {
    if (string.length > maxLength) {
        return string.substring(0, maxLength).concat('...');
    } else {
        return string;
    }
}

export function formatDateDiff(leftDate: Date, rightDate: Date) {
    return formatDistance(leftDate, rightDate, { addSuffix: true });
}

export async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}

export function calcEndTime(inputDate: Date, timeslot: Timeslot, useDuration = false) {
    // TODO: maybe get suntimes from area lat lng
    if (timeslot.relativeTo === 'sunrise') {
        const shiftDate = addDays(inputDate, 1);
        const sunTime = SunCalc.getTimes(shiftDate, 52.0809856, 5.127684);
        
        // add duration
        let result;

        if (useDuration) {
            result = addMinutes(addHours(sunTime.sunrise, timeslot.start), timeslot.duration * 60)
        } else {
            result = addMinutes(sunTime.sunrise, timeslot.end * 60)
        }

        const minutes = interpolateMinutes(result.getMinutes());

        result.setMinutes(minutes);

        return result;
    } else if (timeslot.relativeTo === 'sunset') {
        const shiftDate = addDays(inputDate, 1);
        const sunTime = SunCalc.getTimes(shiftDate, 52.0809856, 5.127684);

        let result;

        if (useDuration) {
            result = addMinutes(addMinutes(sunTime.sunset, timeslot.start * 60), timeslot.duration * 60)
        } else {
            result = addMinutes(sunTime.sunset, timeslot.end * 60);
        }

        const minutes = interpolateMinutes(result.getMinutes());

        result.setMinutes(minutes);

        return result;
    } else if (timeslot.relativeTo === 'midnight') {
        inputDate = addHours(inputDate, timeslot.start)

        if (useDuration) {
            inputDate = addMinutes(inputDate, timeslot.duration * 60)
        } else {
            inputDate = addMinutes(inputDate, timeslot.end * 60);
        }
        
        return inputDate;
    }
}

/**
 * Calculate the start time of a shift using a timeslot
 *
 * @param inputDate
 * @param timeslot
 */
export function calcStartTime(inputDate: Date, timeslot: Timeslot) {
    if (timeslot.relativeTo === 'sunrise') {
        const shiftDate = addDays(inputDate, 1);
        const sunTime = SunCalc.getTimes(shiftDate, 52.0809856, 5.127684);

        // add hours
        const result = addHours(sunTime.sunrise, timeslot.start)
        const minutes = interpolateMinutes(result.getMinutes());

        result.setMinutes(minutes);

        return result;
    } else if (timeslot.relativeTo === 'sunset') {
        const shiftDate = addDays(inputDate, 1);
        const sunTime = SunCalc.getTimes(shiftDate, 52.0809856, 5.127684);
        // add hours
        const result = addHours(sunTime.sunset, timeslot.start);

        const minutes = interpolateMinutes(result.getMinutes());

        result.setMinutes(minutes);

        return result;
    } else if (timeslot.relativeTo === 'midnight') {
        inputDate.setMinutes(0);
        inputDate = addHours(inputDate, timeslot.start);

        return inputDate;
    }
}

export const jwtDecode = (token): JwtDecoded => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const getRange = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export function dateIsBeforeTimeslotWindow(timeslot: Timeslot, date: Date) {
    return (timeslot?.availableFrom && isBefore(date, timeslot?.availableFrom));
}

export function dateIsAfterTimeslotWindow(timeslot: Timeslot, date: Date) {
    return  timeslot?.availableUntil && isAfter(date, timeslot?.availableUntil);
}

export function roundNumber(value: number, precision = 2) {
    if (!value) return 0;
    return Number(value.toFixed(precision));
}

export function pushQueryState(queryParams) {
    const url = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${createSearchParams(queryParams)}`;
    window.history.pushState({path:url },'', url);
}

/**
 * Augment shifts with timeslot
 *
 * @param shifts
 * @param timeslotMapById
 */
export function augmentShifts(shifts: Shift[], timeslotMapById: TimeslotMapById): ShiftAugmented[] {
    const result = [] as ShiftAugmented[];

    for (const shift of shifts) {
        //@ts-ignore
        result.push({
                ...shift,
                timeslot: timeslotMapById[shift.timeslotUid]
            }
        )
    }

    return result;
}

/**
 * @param areaActivityTypes
 * @param timeslotMapById
 */
export function augmentAreaActivities(areaActivityTypes: AreaActivityType[], timeslotMapById: TimeslotMapById) {
    const result = [];

    for (const areaActivityType of areaActivityTypes) {
        // @ts-ignore
        result.push({
            ...areaActivityType,
            ...(areaActivityType.shifts ? { shiftsOfPeriod: augmentShifts(areaActivityType.shifts, timeslotMapById) }: {}),
            ...(areaActivityType.allShifts ? { allShifts: augmentShifts(areaActivityType.allShifts, timeslotMapById) }: {}),
        });
    }

    return result;
}

const Console = prop => (
    console[Object.keys(prop)[0]](...Object.values(prop))
        ,null // ➜ React components must return something
)

export const formatDiff = (duration: any) => {
    const days = duration.days ? `${duration.days} dagen ` : null;

    if (days) {
        return `ongeveer ${days}`;
    }

    return `${duration.hours} uur ${(duration.minutes ||  0) >= 1 ? `${duration.minutes} minuten` : ''}`
}

export const formatDiffStrict = (duration: any) => {
    const result: string[] = [];

    if (duration.days) {
        result.push(`${duration.days} dagen`);
    }

    if (duration.hours) {
        result.push(`${duration.hours} uur`);
    }

    if (duration.minutes) {
        result.push(`${duration.minutes} minuten`);
    }

    if (isEmpty(result) && duration.seconds) {
        result.push(`${duration.seconds} seconden`);
    }

    return result.join(' ');

    // return `${duration.hours} uur ${(duration.minutes ||  0) >= 1 ? `${duration.minutes} minuten` : ''}`
}
