import { useTranslation } from "react-i18next";
import { useReportForm } from "../../../../pages/worker-shifts/WorkerShiftReportPage";
import { FormField, Text, Pane, Select } from "evergreen-ui";
import { ReportFormFieldProps } from "src/types/appTypes";
import { useMemo } from "react";

export const windDirectionOptions = [
    { value: "N", label: "Noord" },
    { value: "NW", label: "Noord-West" },
    { value: "W", label: "West" },
    { value: "SW", label: "Zuid-West" },
    { value: "S", label: "Zuid" },
    { value: "SE", label: "Zuid-Oost" },
    { value: "E", label: "Oost" },
    { value: "NE", label: "Noord-Oost" },
];

export function WindDirection({ reportKey, editMode }: ReportFormFieldProps) {
    const { t } = useTranslation();

    const { onChange, submitContext, formState } = useReportForm();

    function setValue(value) {
        onChange(reportKey.key, value);
    }

    const valueLabel = useMemo(() => {
        return windDirectionOptions.find(v => v.value === formState?.reportFields?.windDirection?.value)?.label ?? "-";
    }, [formState.reportFields?.windDirection]);

    return <Pane className="grow">
        <FormField
            label={t('report_fields.' + reportKey.key)}
        >
            {editMode ?
                <Select
                    disabled={!editMode}
                    onChange={e => setValue(e.target.value)}
                    isInvalid={!!submitContext.validationErrors?.windDirection}
                    //@ts-ignore
                    value={formState.reportFields.windDirection?.value ?? ""}
                    height={40}
                    width="100%"
                >{windDirectionOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
                </Select>
                :
                <Text>{valueLabel}</Text>
            }
        </FormField>
    </Pane>
}
