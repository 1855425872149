import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import WorkerAvailability from "./_workerAvailability";
import { Id } from "../types/apiTypes";
import { PostData } from "../types/appTypes";

export default class AdminWorkerAvailability extends BaseApi {
  public findForWorker(workerId: Id, periodId: Id): Promise<WorkerAvailability> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/workers/${workerId}/availability/${periodId}`,
    });
  }

  public findForPeriod(periodId: Id): Promise<WorkerAvailability[]> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/workers/period/${periodId}/availability`,
    });
  }

  public syncAvailability(workerId: Id, data: PostData): Promise<WorkerAvailability[]> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/workers/${workerId}/availability`,
      data: data,
    });
  }
}