import { Heading, Pane, SideSheet, Text } from "evergreen-ui";
import { useParams } from "react-router-dom";
import { useApi } from "src/context/AxiosContext";
import { useLoadResource } from "src/lib/request-hooks";
import { useMemo, useState } from "react";
import { eachDayOfInterval, formatISO, parseISO } from "date-fns";
import { DateFormat } from "src/components/common/DateFormat";
import { mapKeys } from "lodash";
import useTimeslotResources from "src/hooks/useTimeslotResources";
import { useTranslation } from "react-i18next";
import { WorkerAvailabilityType } from "../../../types/apiTypes";

interface WorkerAvailabilityForPeriod {
    availabilityPerDay: WorkerAvailabilityType[];
    shiftsPerDay: Record<string, number>;
}

function useAvailabilityForPeriod() {
    const { apiInstance } = useApi();
    const { periodId } = useParams();

    const [availabilityGroupedByDay, setAvailabilityGroupedByDay] = useState<Record<string, WorkerAvailabilityType>>({});
    const [shiftsPerDay, setShiftsPerDay] = useState<Record<string, number>>({});

    const find = () => apiInstance!.adminWorkerAvailability.findForPeriod(periodId!);

    function setData(data: WorkerAvailabilityForPeriod) {
        const { availabilityPerDay, shiftsPerDay } = data;

        const availabilityPerDayGrouped = mapKeys(availabilityPerDay, function(av) {
            return formatISO(parseISO(av.date)) + '|' + av.timeslotUid;
        })

        setAvailabilityGroupedByDay(availabilityPerDayGrouped);
        setShiftsPerDay(shiftsPerDay);
    }

    return {
        ...useLoadResource(find, setData, !!periodId),
        shiftsPerDay,
        availabilityGroupedByDay,
    }
}

export function AvailabilityPerDayTable({ period }) {
    const { t } = useTranslation();

    const { availabilityGroupedByDay, shiftsPerDay } = useAvailabilityForPeriod();
    const { timeslots } = useTimeslotResources();

    const dayInterval = useMemo(() => {
        return eachDayOfInterval({
            start: parseISO(period.startAt),
            end: parseISO(period.endAt)
        })
    }, [period])

    function getAvailabilityForDay(day: Date, timeslotUid: string): number {
        return availabilityGroupedByDay[formatISO(day) + '|' + timeslotUid]?._count.date || 0;
    }

    function getShiftsPerDay(day: Date, timeslotUid: string): number {
        return shiftsPerDay[formatISO(day) + '|' + timeslotUid] || 0;
    }

    function getTotalPerDay(day: Date, timeslotUid: string) {
        return getAvailabilityForDay(day, timeslotUid) - getShiftsPerDay(day, timeslotUid);
    }

    return <Pane className="px-[20px] w-full">
                <table className="full-width-table availability-per-day-table">
                    <thead>
                    <th className="w-9"></th>
                    { dayInterval.map(day => (
                        timeslots.map(timeslot => (
                            <th className="w-3">
                                <Pane className="flex flex-col">
                                    <DateFormat
                                        date={day}
                                        formatStr={'iiiii'}
                                    />
                                    <Text className="lowercase !text-xs">{ t(timeslot.label)[0] }</Text>
                                </Pane>
                            </th>
                        ))
                    ))}
                    </thead>
                    <tbody>

                    <tr>
                        <td className="text-xs !w-3">Availability</td>
                        { dayInterval.map(day => (
                            timeslots.map(timeslot => (
                                <td className="w-3">
                                    <Pane className="flex flex-col">
                                        <Text className="lowercase !text-xs">{ getAvailabilityForDay(day, timeslot.id) }</Text>
                                    </Pane>
                                </td>
                            ))
                        ))
                        }
                    </tr>

                    <tr>
                        <td className="text-xs !w-3">Shifts</td>
                        { dayInterval.map(day => (
                            timeslots.map(timeslot => (
                                <td className="w-3">
                                    <Pane className="flex flex-col">
                                        <Text className="lowercase !text-xs">{ getShiftsPerDay(day, timeslot.id) }</Text>
                                    </Pane>
                                </td>
                            ))
                        ))
                        }
                    </tr>

                    <tr>
                        <td className="text-xs !h-0 !w-3"></td>
                        { dayInterval.map(() => (
                            timeslots.map(() => (
                                <td className="!h-0 border-b"></td>
                            ))
                        ))
                        }
                    </tr>

                    <tr>
                        <td className="text-xs !w-3">#</td>
                        { dayInterval.map(day => (
                            timeslots.map(timeslot => (
                                <td className="w-3">
                                    <Pane className="flex flex-col">
                                        <Text className="lowercase !text-xs">{ getTotalPerDay(day, timeslot.id) }</Text>
                                    </Pane>
                                </td>
                            ))
                        ))
                        }
                    </tr>
                    </tbody>
                </table>
            </Pane>
}

export default function AvailabilityPerDayTableSheet({ setIsShown, period } ) {
    return <SideSheet isShown={true} onCloseComplete={() => setIsShown(false)}>
        <Heading paddingX={20} marginY={40}>Beschikbaarheid per dag</Heading>

        <AvailabilityPerDayTable period={period} />
    </SideSheet>
}