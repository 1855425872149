import { CaretDownIcon, CaretUpIcon, Pane, Table } from "evergreen-ui";
import { isEmpty } from 'lodash';
import { useMemo } from "react";
import { classNames } from "../../lib/functions";

const SortIconMap = {
    'asc': <CaretDownIcon />,
    'desc': <CaretUpIcon />,
    'default': <></>,
}

function getSortByMap(sortMap) {
    const result = {};

    for (const item of sortMap) {
        const [field, order] = item.split(':');
        result[field] = order;
    }

    return result;
}

const useSortBy = function({ sortMap, sortBy: sortByField, doSortBy }) {
    const onSortBy = () => {
        if (sortMap[sortByField]) {
            if (sortMap[sortByField] === 'desc') {
                delete sortMap[sortByField];
            } else {
                sortMap[sortByField] = sortMap[sortByField] === 'asc' ? 'desc': 'asc';
            }
        } else {
            sortMap[sortByField] = 'asc';
        }

        const sortBySerialized = !isEmpty(sortMap) ? Object.keys(sortMap).map((key) => {
            return `${key}:${sortMap[key]}`;
        }): [];

        if (!isEmpty(sortBySerialized)) {
            doSortBy(sortBySerialized.join(','))
        } else {
            doSortBy(null);
        }
    }

    return {
        onSortBy,
    }
}

export default function HeaderCell(props) {
    const sortMap = useMemo(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const sortBy = queryParams.get('sortBy');
        const sortFields = sortBy ? sortBy.split(','): [];

        return getSortByMap(sortFields);
    }, [window?.location.search]);

    const sortOrder = useMemo(() => {
        return sortMap[props.sortBy];
    }, [sortMap]);

    const {
        sortBy,
        children,
        doSortBy,
        ...restProps
    } = props;

    const { onSortBy } = useSortBy({
        sortMap,
        sortBy,
        doSortBy,
    });

    return <Table.HeaderCell
        rightView={sortBy ? <Pane className="ml-2">{SortIconMap[sortOrder] ?? SortIconMap.default}</Pane>: null}
        onClick={onSortBy} {...restProps} className={classNames(sortOrder ? "underline !font-bold": "", "hover:underline cursor-pointer")}>
        {children}
    </Table.HeaderCell>
}