import PageTitle from "../../components/common/PageTitle";
import { useApi } from "../../context/AxiosContext";
import { createContext, Fragment, useContext, useEffect, useReducer, useState } from "react";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { ReportFieldMap, ReportTemplate, WorkerShift } from "../../types/apiTypes";
import Page from "../../components/common/Page";
import { useNavigate, useParams } from "react-router-dom";
import { isEmpty } from "lodash";
import {
    Alert,
    BanCircleIcon,
    EmptyState,
    FileCard,
    FormField,
    Heading,
    majorScale,
    Pane,
    Paragraph,
    Text, toaster,
    UploadIcon,
} from "evergreen-ui";
import Divider from "../../components/common/Divider";
import Actions from "../../components/common/Actions";
import { useTranslation } from "react-i18next";
import Media from "../../api/_media";
import MultiFileUploader from "../../components/formfields/MultiFileUploader";
import DateTimeRangeInput from "../../components/formfields/DateTimeRangeInput";
import { addDays, parseISO } from "date-fns";
import { useWorkerShift } from "./WorkerShiftDetailPage";
import useTimeslotResources from "../../hooks/useTimeslotResources";
import { DateFormat } from "../../components/common/DateFormat";
import useShiftReport from "../../hooks/useShiftReport";
import { calcEndTime, calcStartTime } from "../../lib/functions";
import { ReportFieldsRow } from "../../components/shared/report/components/ReportFieldsRow";
import { WORKER_SHIFTS_PLANNED_PAGE } from "src/RouteMap";
import { ShiftReportSummary } from "../../components/shared/report/ShiftReportSummary";
import { TableLoadingSpinner } from "../../components/table/StandardTable";
import { InfoBox } from "../projects/activity-types/ActivityTypeEditPage";

interface FormContextType {
    onChange: (key: string, value: any) => void;
    setDirty: () => void;
    formState: {
        isDirty: boolean;
        reportFields?: ReportFieldMap;
    },
    submitContext: {
        isSubmitting:boolean | null;
        validationErrors: any;
        submitErrorMessage: string | null;
        setIsSubmitting: Function;
    },
}

export function shiftReportFormReducer(state, action) {
    switch (action.type) {
        case "setDirty":
            return {
                ...state,
                isDirty: true,
            }
        case "setReportFields":
            return {
                ...state,
                reportFields: action.reportFields,
            }
        case "onChange":
            return {
                ...state,
                isDirty: true,
                reportFields: {
                    ...state.reportFields,
                    [action.key]: action.value,
                }
            }
        default:
            return state;
    }
}

export const ShiftFormContext = createContext({
    formState: {
        isDirty: false,
    },
    onChange: (key, value) => {},
    submitContext: {},
} as FormContextType);

export function useReportForm() {
    return useContext(ShiftFormContext);
}

const reportFieldDefaultValueMap = {
    celsius: 0,
    cloudiness: 0,
    temperature: 18,
    precipitation: 'dry',
    windSpeed: 0,
    windDirection: 'N',
    notes: '',
    animalNest: false,
}

function getDefaultReportFieldValues(template) {
    const defaultReportFields = {};

    for (const section of template.reportTemplateSections) {
        for (const reportKey of section.reportKeys) {
            const key = reportKey.key;

            defaultReportFields[key] = {
                value: reportFieldDefaultValueMap[key] ?? null,
            }
        }
    }

    return defaultReportFields;
}

export function useWorkerShiftReportTemplate({ shiftReport, afterSubmit }) {
    const { apiInstance } = useApi();
    const { shiftId } = useParams();

    const [template, setTemplate] = useState<ReportTemplate | null>(null);

    const [startedAt, setStartedAt] = useState<Date | null>(null)
    const [endedAt, setEndedAt] = useState<Date | null>(null)

    const [ mediaFiles, setMediaFiles ] = useState<Media[]>([])
    const [ uploadingMediaFiles, setUploadingMediaFiles ] = useState<Media[]>([])

    const {
        setIsLoading: setIsSubmitting,
        isLoading: isSubmitting,
        validationErrors,
        errorMessage: submitErrorMessage,
        setValidationErrors,
        handle,
    } = useDoRequest();

    const findTemplate = () => apiInstance!.workerShiftReport.getTemplate();

    const [formState, dispatch] = useReducer(shiftReportFormReducer, {
        isDirty: false,
        reportFields: {},
    });

    function setTemplateData(template) {
        setTemplate(template);

        if (!shiftReport && isEmpty(formState.reportFields)) {
            const defaultReportFields = getDefaultReportFieldValues(template)

            dispatch({ type: 'setReportFields', reportFields: defaultReportFields});
        }
    }

    const templateResource = useLoadResource(findTemplate, setTemplateData, !template)

    const doSubmit = async () => {
        const data = {
            ...formState,
            media: [...mediaFiles, ...uploadingMediaFiles],
            startedAt,
            endedAt,
        }

        let request;

        if (shiftReport) {
            request = apiInstance!.workerShiftReport.updateShiftReport(shiftReport.id!, data);
        } else {
            request = apiInstance!.workerShiftReport.createForWorkerShift(shiftId!, data);
        }

        const result = await handle(request);

        afterSubmit(result)
    }

    const submitContext = {
        doSubmit,
        setIsSubmitting,
        isSubmitting,
        submitErrorMessage,
        validationErrors,
        setValidationErrors,
    };

    const formContext = {
        formState,
        submitContext,
        setDirty: () => dispatch({ type: 'setDirty' }),
        onChange: (key, value) => {
            dispatch({ type: 'onChange', key: key, value: { value } });
        },
    };

    useEffect(() => {
        if (shiftReport) {
            dispatch({ type: 'setReportFields', reportFields: shiftReport.reportFields});
            // setStartedAt(shiftReport.workerShift.startedAt);
            // setEndedAt(shiftReport.workerShift.endedAt);
            setMediaFiles(shiftReport.media);
        }

    }, [shiftReport]);

    function uploadFn(formData) {
        return apiInstance!.media.uploadToShiftReportTmp(formData);
    }

    return {
        template,
        templateResource,
        formState,
        formContext,
        uploadingMediaFiles,
        setUploadingMediaFiles,
        mediaFiles,
        setMediaFiles,
        uploadFn,
        setStartedAt,
        setEndedAt,
        startedAt,
        endedAt,
    }
}

export default function WorkerShiftReportPage() {
    const navigate = useNavigate();
    const { timeslotMapById } = useTimeslotResources();

    const {
        isLoading,
        shiftReport,
        refresh,
    } = useShiftReport();

    function afterSubmit() {
        toaster.success('Raport ingediend! Wacht op goedkeuring, bij afkeuring zie je de dienst weer terug in deze lijst zodat je hem kan aanpassen.', { duration: 10 });

        navigate(WORKER_SHIFTS_PLANNED_PAGE)
    }

    return <Page>
        <PageTitle backPath={WORKER_SHIFTS_PLANNED_PAGE}>Rapport</PageTitle>


        <Pane className="max-w-2xl">
            {isLoading &&
              <TableLoadingSpinner />
            }
            {isLoading === false && shiftReport &&
              <>
                  {shiftReport.notes &&
                    <Alert className="p-2" intent="warning">
                      <Pane className="py-4">
                        <Heading size={500}>Feedback:</Heading>
                        <Paragraph size={400}>{shiftReport.notes}</Paragraph>
                      </Pane>
                    </Alert>
                  }
                <ShiftReportSummary shiftReport={shiftReport} />
              </>
            }

            {isLoading === false && timeslotMapById &&
              <ReportForm
                editMode={(shiftReport && shiftReport.status === 'rejected') ?? true}
                shiftReport={shiftReport}
                afterSubmit={afterSubmit}
                timeslotMapById={timeslotMapById}
              />
            }

        </Pane>
    </Page>
}


export function ReportForm({ editMode, shiftReport, afterSubmit, timeslotMapById }) {
    const { t } = useTranslation();

    const {
        template,
        formContext,
        uploadingMediaFiles,
        setUploadingMediaFiles,
        mediaFiles,
        setMediaFiles,
        uploadFn,
        setStartedAt,
        setEndedAt,
        startedAt,
        endedAt,
    } = useWorkerShiftReportTemplate({ shiftReport, afterSubmit: afterSubmit });

    const { workerShift, isLoading } = useWorkerShift( {
        setData: (workerShift: WorkerShift) => {
            const timeslot = workerShift.shift.timeslot;

            if (!workerShift.startedAt) {
                // set default to shift date start if no start time is set
                const shiftDate = parseISO(workerShift.shift.date);
                const startTime = calcStartTime(shiftDate, timeslot);
                const endTime = calcEndTime(shiftDate, timeslot, true);

                setStartedAt(startTime ?? shiftDate);
                setEndedAt(endTime ?? shiftDate);
            } else {
                setStartedAt(parseISO(workerShift.startedAt));
                setEndedAt(parseISO(workerShift.endedAt));
            }
        }
    } );

    const duration = workerShift?.shift?.timeslot?.duration;
    const sections = template?.reportTemplateSections || [];

    return <ShiftFormContext.Provider value={ formContext }>
        {workerShift && !shiftReport && !workerShift.reportIsSubmittable && isLoading === false &&
          <EmptyState
            background="light"
            title="Je kunt het Rapport nog niet indienen. Deze dienst is in afwachting van de start."
            orientation="horizontal"
            icon={<BanCircleIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
          />
        }

        {workerShift && workerShift.reportIsSubmittable && isLoading === false &&
          <>
            <Divider small={true} title="Observatietijd">
                {editMode && startedAt && endedAt ?
                    <Pane>
                        <DateTimeRangeInput
                            maxWidth={150}
                            fromValue={startedAt}
                            datePickerOptions={{
                                disabled: {
                                    before: parseISO(workerShift.shift.date),
                                    after: addDays(parseISO(workerShift.shift.date), 1),
                                },
                                defaultMonth: parseISO(workerShift.shift.date),
                            }}
                            setFromValue={(v) => {
                                formContext.setDirty();
                                setStartedAt(v)
                            }}
                            toValue={endedAt}
                            setToValue={(v) => {
                                formContext.setDirty();
                                setEndedAt(v)
                            }}
                        />

                        <Paragraph className="py-2" color="muted" size={300}>
                            We vullen automatisch de min. starttijd + de maximale tijd van een dienst, in dit geval {duration} uur.
                        </Paragraph>
                        <InfoBox description="Let op, als de eindtijd op de volgende dag ligt, gebruik dan de datumkiezer en kies de volgende dag."></InfoBox>
                    </Pane>
                    :
                    <Pane className="flex gap-2">
                        <Pane>
                            <Heading size={300}>Starttijd:</Heading>
                            <DateFormat
                                size={400}
                                formatStr="PPPPp"
                                date={startedAt!}
                            />
                        </Pane>

                        <Pane>
                            <Heading size={300}>Eindtijd:</Heading>
                            <DateFormat
                                size={400}
                                formatStr="PPPPp"
                                date={endedAt!}
                            />
                        </Pane>
                    </Pane>
                }
            </Divider>

              {sections.map(section => (
                  <Pane className="py-2" key={section.key}>
                      <Divider small={true} title={t("report_fields." + section.key)}>
                          <ReportFieldsRow
                              editMode={editMode}
                              section={section}
                          />
                      </Divider>
                  </Pane>
              ))}

            <Divider small={true} title="Bestanden">
                {editMode &&
                  <FormField
                    isInvalid={true}
                  >
                    <MultiFileUploader
                      uploadFn={uploadFn}
                      mediaFiles={uploadingMediaFiles}
                      setMediaFiles={(v) => {
                          formContext.setDirty();
                          setUploadingMediaFiles(v)
                      }}
                      setIsLoading={formContext.submitContext.setIsSubmitting}
                    />
                      {formContext.submitContext.validationErrors?.media &&
                        <Pane>
                          <Text color="red">{formContext.submitContext.validationErrors?.media?.join(', ')}</Text>
                        </Pane>
                      }
                  </FormField>
                }

              <FormField label={"Attached media"}>
                <AttachedShiftReportMediaFiles
                  editMode={editMode}
                  mediaFiles={mediaFiles}
                  setMediaFiles={(v) => {
                      formContext.setDirty();
                      setMediaFiles(v)
                  }}
                />
              </FormField>
            </Divider>

            <Actions>
                {editMode &&
                  <Actions.Button
                    iconBefore={UploadIcon}
                    onClick={formContext.submitContext.doSubmit}
                    isLoading={!!formContext.submitContext.isSubmitting}
                    disabled={!formContext.formState.isDirty}
                    appearance="primary"
                    intent="success"
                    height={majorScale(5)}>Rapport indienen
                  </Actions.Button>
                }
            </Actions>
          </>
        }
    </ShiftFormContext.Provider>
}

export function AttachedShiftReportMediaFiles({ mediaFiles, setMediaFiles, editMode }) {
    const handleRemove = (file) => {
        const updatedFiles = mediaFiles.filter((existingFile) => existingFile !== file)
        setMediaFiles(updatedFiles)
    }

    // async function onClick(media) {
    //     const src = media.url;
    //
    //     const response = await axios.get(src, {
    //         headers: {
    //             "Referrer-Policy": "origin-when-cross-origin"
    //         },
    //         withCredentials: true,
    //     })
    //
    //     const redirectUrl = response.data?.url;
    //
    //     const a = document.createElement('a');
    //
    //     a.target = "_top";
    //     a.style.display = 'none';
    //     a.href = redirectUrl;
    //
    //     // the filename you want
    //
    //     document.body.appendChild(a);
    //     a.click();
    //
    //     window.URL.revokeObjectURL(redirectUrl);
    // }

    return (
        <>
            {mediaFiles.map((media, index) => (
                <Fragment key={`${media.originalFileName}-${index}`}>
                    <a rel="noreferrer" href={media.src} className="hover:underline cursor-pointer" target="_blank">
                        <FileCard
                            isInvalid={false}
                            name={media.originalFileName}
                            //@ts-ignore
                            onRemove={editMode ? (e) => {
                                e.preventDefault();
                                handleRemove(media)
                            }: undefined}
                            sizeInBytes={media.size}
                            type={media.mimeType}
                            isLoading={false}
                            src={media.src}
                        />
                    </a>
                </Fragment>
            ))}

            { isEmpty(mediaFiles) &&
              <Paragraph>Geen bestanden...</Paragraph>
            }
        </>
    );
}
