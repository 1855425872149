import {
    Avatar,
    EmptyState,
    FilterIcon,
    Heading,
    majorScale,
    Pane,
    SearchIcon,
    Spinner,
    Table,
    Text
} from "evergreen-ui";
import StandardTable from "../../components/table/StandardTable";
import { useApi } from "../../context/AxiosContext";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { useEffect, useState } from "react";
import { Bookkeeping, WorkerApiType } from "../../types/apiTypes";
import { useWindowSearchParams } from "src/hooks/useWindowSearchParams";
import Page from "../../components/common/Page";
import PageTitle from "../../components/common/PageTitle";
import { useTranslation } from "react-i18next";
import Actions from "../../components/common/Actions";
import BookkeepingFilters from "./_components/BookkeepingFilters";
import { useDoFilter } from "../../hooks/useDoFilter";
import { endOfMonth, formatISO, startOfMonth } from "date-fns";
import DurationValue from "../../components/common/DurationValue";
import TravelDistanceValue from "../../components/common/TravelDistanceValue";
import { FilterMapType } from "../../types/appTypes";
import { __r, ADMIN_WORKER_SHIFT_HISTORY_PAGE } from "../../RouteMap";
import { useNavigate } from "react-router-dom";

function useWorkerBookkeeping() {
    const searchParams = useWindowSearchParams();

    const { apiInstance } = useApi();
    const [bookkeeping, setBookkeeping] = useState<Bookkeeping [] | []>([]);

    const [initialized, setInitialized] = useState<boolean>(false);
    const [workerId, setWorkerId] = useState<number | null>(null);
    const [projectId, setProjectId] = useState<number | null>(null);
    const [projectType, setProjectType] = useState<string | null>(null);

    const [fromApprovedAtDate, setFromApprovedAtDate] = useState<string | null>(searchParams.get('fromApprovedAtDate'));
    const [toApprovedAtDate, setToApprovedAtDate] = useState<string | null>(searchParams.get('toApprovedAtDate'));

    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);

    const filterMap: FilterMapType = {
        fromApprovedAtDate: {
            setter: setFromApprovedAtDate,
            topic: 'fromApprovedAtDate',
            value: fromApprovedAtDate,
            default: formatISO(startOfMonth(new Date())),
        },
        toApprovedAtDate: {
            setter: setToApprovedAtDate,
            topic: 'toApprovedAtDate',
            value: toApprovedAtDate,
            default: formatISO(endOfMonth(new Date())),
        },
        fromDate: {
            setter: setFromDate,
            topic: 'fromDate',
            value: fromDate,
            default: null,
        },
        toDate: {
            setter: setToDate,
            topic: 'toDate',
            value: toDate,
            default: null,
        },
        workerId: {
            setter: setWorkerId,
            topic: 'workerId',
            value: workerId,
        },
        projectId: {
            setter: setProjectId,
            topic: 'projectId',
            value: projectId,
        },
        projectType: {
            setter: setProjectType,
            topic: 'projectType',
            value: projectType,
        },
    };

    const find = () => apiInstance!.adminWorkerBookkeeping.findMany(searchParams);

    const loadResourceLogic = useLoadResource(find, setData, initialized);

    const { fetchData, setDefaultFilters, setFilter, doSortBy, handleInput, doFilter } = useDoFilter( {
        searchParams,
        setInitialized,
        initialized,
        filterMap,
        loadResourceLogic,
        find,
        setData,
        setMeta: () => {},
    });

    useEffect(() => {
        if (!initialized) {
            setDefaultFilters();
        }
    }, [initialized])


    function setData(bookkeeping: Bookkeeping[]) {
        setBookkeeping(bookkeeping)
    }

    return {
        ...loadResourceLogic,
        bookkeeping,
        doFilter,
        doSortBy,
        fetchData,
        filterMap,
        handleInput,
        setData,
        setDefaultFilters,
        setFilter,
    }
}

function useExportLogic() {
    const searchParams = useWindowSearchParams();
    const { apiInstance } = useApi();

    const {
        handle,
        isLoading,
    } = useDoRequest();

    async function doExport() {
        const request = apiInstance!.adminWorkerBookkeeping.exportToCsv(searchParams);

        return await handle(request)
    }

    return {
        doExport,
        isLoading,
    }
}

export default function BookkeepingExportPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const filterLogic = useWorkerBookkeeping();

    const {
        isLoading,
        meta,
        refresh,
        bookkeeping,
    } = filterLogic;

    const {
        doExport,
        isLoading: isExporting,
    } = useExportLogic()

    async function doExportCb() {
        const { url } = await doExport();
        const a = document.createElement('a');

        a.target = "_blank";
        a.style.display = 'none';
        a.href = url;

        // the filename you want
        a.download = 'export.csv';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    function navToShiftHistory(workerId: number) {
        const url = __r(ADMIN_WORKER_SHIFT_HISTORY_PAGE, {workerId})

        navigate({
            pathname: url,
            search: window.location.search,
        }, {
            state: {
                backPath: window.location.pathname + window.location.search,
            },
        })
    }

    return <Page>
        <PageTitle
            actions={
                <Actions>
                    <Actions.Button
                        textOverflow={"ellipsis"}
                        appearance="primary"
                        intent="success"
                        isLoading={isExporting}
                        onClick={doExportCb}
                        height={majorScale(4)}>Exporteer
                    </Actions.Button>
                </Actions>
            }
        >
            <Pane className="flex gap-1">
                {t('admin_bookkeeping_page.title')}
                {isLoading &&
                  <Pane className="flex justify-end">
                    <Spinner size={30} />
                  </Pane>
                }
            </Pane>
        </PageTitle>

        <Pane className="flex flex-col gap-2">
            <Pane className="flex gap-1 items-center">
                <FilterIcon color="muted" size={majorScale(2)} />
                <Heading size={300}>Filters</Heading>
            </Pane>
            <Pane className={'w-8 h-[1px] bg-gray-200'} />
            <BookkeepingFilters filterLogic={filterLogic} />
            <Pane className={'w-8 h-[1px] bg-gray-200'} />
        </Pane>


        <StandardTable minHeight={400} refresh={refresh} meta={meta} isLoading={isLoading} data={bookkeeping} emptyState={<EmptyWorkersState />}>
            <Table.Head className="!pr-0">
                <StandardTable.HeaderCell>Name</StandardTable.HeaderCell>
                <StandardTable.HeaderCell>Gewerkt</StandardTable.HeaderCell>
                <StandardTable.HeaderCell>Reistijd</StandardTable.HeaderCell>
                <StandardTable.HeaderCell>Kilometers</StandardTable.HeaderCell>
                <StandardTable.HeaderCell>Aantal Diensten</StandardTable.HeaderCell>
                <StandardTable.HeaderCell>Aantal Nachtdiensten</StandardTable.HeaderCell>
                <StandardTable.HeaderCell>Carpool &gt; 1 persoon</StandardTable.HeaderCell>
            </Table.Head>
            { bookkeeping.map((row) => (
                <Table.Row key={row.id} isSelectable onSelect={() => navToShiftHistory(row.id)}>
                    <Table.Cell>
                        <Pane className="flex gap-2 items-center">
                            <Avatar
                                className="hover:opacity-90"
                                src={row.user!.profilePictureThumbnail ?? ""}
                                name={""}
                                size={20}
                            />
                            <Text>
                                {row.user.firstName} {row.user.lastName}
                            </Text>
                        </Pane>
                    </Table.Cell>
                    <Table.TextCell>
                        <DurationValue seconds={row.aggregations.shiftDuration} />
                    </Table.TextCell>
                    <Table.TextCell>
                        <DurationValue seconds={row.aggregations.travelDuration} />
                    </Table.TextCell>
                    <Table.TextCell>
                        <TravelDistanceValue meters={row.aggregations.distanceToArea} />
                    </Table.TextCell>
                    <Table.TextCell>
                        {row.aggregations.shiftCount}
                    </Table.TextCell>
                    <Table.TextCell>
                        {row.aggregations.nightShiftCount}
                    </Table.TextCell>
                    <Table.TextCell>
                        {row.aggregations.inCarpoolCount}
                    </Table.TextCell>
                </Table.Row>
            ))}
        </StandardTable>
    </Page>
}

function EmptyWorkersState() {
    return (
        <EmptyState
            background="light"
            title="Geen resultaten gevonden"
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="Alleen als er goedgekeurde rapporten zijn die matched met de huidige filter, dan worden hier de resultaten getoond per veldwerker in die periode"
        />
    )
}
