import { useState } from "react";
import { useLoadResource } from "src/lib/request-hooks";
import { isEmpty } from 'lodash';
import { WorkerApiType } from "../types/apiTypes";
import { useApi } from "../context/AxiosContext";


export default function useWorkers({ workerId, setWorker }: { workerId: string | number, setWorker: Function }) {
    const { apiInstance } = useApi();
    const [ workers, setWorkers ] = useState<WorkerApiType[]>([]);

    const searchParams = new URLSearchParams();
    searchParams.set('limit', '1000');

    const find = () => apiInstance!.adminWorkers.findMany(searchParams);
    const findOne = () => apiInstance!.adminWorkers.findOne(workerId);

    function setWorkerOption(v) {
        setWorker({
            value: v.id,
            label: v.user.firstName + ' ' + v.user.lastName
        })
    }

    // find the selected worker.
    useLoadResource(findOne, setWorkerOption, !!workerId);

    return {
        ...useLoadResource(find, setWorkers, isEmpty(workers)),
        workers,
    };
}

