import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { PostData, SearchParams } from "../types/appTypes";
import { Id } from "../types/apiTypes";

export default class AdminWorkers extends BaseApi {
  public findOne(id: Id): Promise<WorkerType> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/workers/${id}`
    });
  }

  public create(data: PostData): Promise<WorkerType> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/workers`,
      data: data,
    });
  }

  public update(id: number, data: PostData): Promise<WorkerType> {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/admin/workers/${id}`,
      data: data,
    });
  }

  public findMany(searchParams: SearchParams = {}): Promise<WorkerType[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/workers${query}`,
    });
  }

  public remove(id: number): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/admin/workers/${id}`,
    });
  }
}