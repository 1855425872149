import { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { User } from "../types/apiTypes";

export interface HasChildren {
  children: JSX.Element;
}

export interface AuthProviderProps extends HasChildren {

}

export function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState<User | null>(null);
  const [jwt, setJwt] = useState<string | null>(null);

  const init = async () => {
    const jwt = localStorage.getItem('@grofalex-portal:jwt') || null;

    if (jwt) {
      setJwt(jwt);
    }
  }

  const setJwtCb = (jwt: string | null, setInLocalStorage = true) => {
    setJwt(jwt);

    if (setInLocalStorage) {
      if (jwt) {
        localStorage.setItem('@grofalex-portal:jwt', jwt)
      } else {
        localStorage.removeItem('@grofalex-portal:jwt')
      }
    }
  }

  useEffect(() => {
    init();
  }, [])

  return (
      <AuthContext.Provider value={{jwt, setJwt: setJwtCb, setUser, user}}>
        {children}
      </AuthContext.Provider>
  )
}
