import { Card, EmptyState, Heading, Pane, PersonIcon, Pill, SearchIcon, Text } from "evergreen-ui";
import { AreaActivityType } from "src/types/apiTypes";

import { classNames, augmentAreaActivities, roundNumber } from "src/lib/functions";
import { usePlannerContext } from "src/context/PlannerContext";
import PlannerRoundsItem, { ShiftAugmented } from "./PlannerRoundsItem";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { isEmpty } from 'lodash';
import { useMemo } from "react";
import { TbSum, TbUrgent } from "react-icons/tb";
import { IoIosPerson } from "react-icons/io";

interface AreaActivityTypeWithShiftsMap extends AreaActivityType {
    shiftsOfPeriod: ShiftAugmented[],
    allShifts: ShiftAugmented[],
}

export default function PlannnerForPeriodTable({ planning, prevPeriod, nextPeriod, period, ...rest }) {
    const { t } = useTranslation();

    const periodStartDate = parseISO(period.startAt);

    return <Pane className="flex gap-2 flex-wrap lg:flex-nowrap">
            <Pane className="pb-2">
                {planning &&
                  <PlannerTable
                    startDate={periodStartDate}
                    period={period}
                    planning={planning}
                  />
                }
            </Pane>
        {/*))}*/}
    </Pane>
}

function PlannerTable({ startDate, period, planning, ...rest }) {
    // const weekIndex = Array.from(Array(1).keys());
    return <Pane {...rest} className="planner-rounds-table w-full table">
        <PlannerTableHeaderRow weekStartDate={startDate} />

        { planning.map((plan, index) => (
            <PlannerRoundsTableRow
                key={plan.id}
                period={period}
                startDate={startDate}
                plan={plan}
            />
        )) }
    </Pane>
}

function PlannerTableHeaderRow({ ...rest }) {
    return (
        <Pane className="planner-header-row py-5">
            {/* the first cell with the topic */}
            <PlannerHeaderCell key={'header-topic'} className="planner-th planner-header-topic">
                <Heading size={100}>Activiteit</Heading>
            </PlannerHeaderCell>

            <th className="">
                <Heading size={100}>Dienst</Heading>
            </th>
        </Pane>
    )
}

function PlannerHeaderCell(props) {
    const { children, className, ...rest } = props;

    const classes = classNames(className, "py-2 px-2 text-center")

    return <Pane className={classes} {...rest}>
        { children }
    </Pane>
}

/**
 * @param rounds
 * @param plan
 * @constructor
 */
function PlannerRoundsTableRow({ startDate, period, plan }) {
    const { timeslotMapById } = usePlannerContext();

    const areaActivityTypesAugmented = useMemo(() => {
        return augmentAreaActivities(plan.areaActivityTypes, timeslotMapById) as AreaActivityTypeWithShiftsMap[];
    }, [plan.areaActivityTypes, timeslotMapById]);

    return <>
        <Pane className="tr planner-table-topic-row bg-gray-50">
            {/* the first cell with the area name and projct */}
            <Pane className="planner-table-topic-row-cell planner-table-cell w-[5rem] bg-white text-center border-b border-t border-l py-2">
                { plan?.distanceKm >= 0 && <Heading size={200}>{roundNumber(plan.distanceKm)}KM</Heading> }

                <Text className="!font-bold !text-xs">
                    { plan.name }
                </Text>

                <Heading size={100}>{plan.project.name}</Heading>

                <Pane className="flex gap-1 justify-center items-center">
                    <TbSum />
                    <Heading className="!text-xs !font-normal">
                        { roundNumber(plan.maxUrgency, 2) }
                    </Heading>
                </Pane>
            </Pane>
            <Pane className="table-cell border-t"/>
        </Pane>

        { areaActivityTypesAugmented.map((entry, index) => (
            <Card key={'row-' + entry.id} className="planner-table-row px-5 w-full">
                <Pane className='table-cell planner-table-row-cell planner-table-cell text-center px-2 py-4 border-b border-l bg-white items-center'>
                    <Pane className="h-full">
                        <Pane className="flex flex-col min-h-full h-full">
                            <Pane className="grow">
                                <Text>
                                    { entry.activityType.name }
                                </Text>
                            </Pane>

                            <Pane className="shrink flex gap-1 justify-center items-center">
                                <TbUrgent />
                                <Heading className="!text-xs !font-normal">
                                    { roundNumber(entry.activityType.urgency, 2) }
                                </Heading>
                            </Pane>
                        </Pane>
                    </Pane>
                </Pane>

                <Pane className="table-cell">
                    <Pane className="flex flex-wrap lg:flex-nowrap min-w-lg">
                        { entry.shiftsOfPeriod.map((shift, index) => (
                            <PlannerRoundsItem
                                key={'item' + entry.activityType.id + (shift?.id ?? index)}
                                entry={entry}
                                shifts={entry.allShifts ?? []}
                                startDate={startDate}
                                index={index}
                                shift={shift ?? null}
                                areaActivityType={entry}
                                plan={plan}
                                period={period}
                            />
                        ))}

                        <PlannerRoundsItem
                            key={'item' + entry.activityType.id + entry.shifts.length +  'empty-shift'}
                            entry={entry}
                            shifts={entry.allShifts ?? []}
                            startDate={startDate}
                            index={entry.allShifts.length}
                            shift={null}
                            areaActivityType={entry}
                            plan={plan}
                            period={period}
                        />
                    </Pane>
                </Pane>
            </Card>
        ))}
    </>
}
