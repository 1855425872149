import { useParams } from "react-router-dom";
import { useApi } from "../../../context/AxiosContext";

export default function usePublishPlanning() {
    const { periodId } = useParams();
    const { apiInstance } = useApi();

    function doPublishPlanning() {
        return apiInstance!.adminPlanning.publishForPeriod(periodId!);
    }

    return {
        doPublishPlanning,
    }
}

