import { CalendarIcon, Heading, Pane, SendMessageIcon, UserIcon, useTheme } from "evergreen-ui";
import { Outlet } from "react-router-dom";
import TopSubNav from "../common/TopSubNav";

const menuItems = [
    {
        label: 'Gebruikers',
        pathname: '/admin/users',
        icon: <UserIcon />
    },
    {
        label: 'Uitnodigingen',
        pathname: `/admin/invites`,
        icon: <SendMessageIcon />
    },
];

export default function UsersLayout() {
    return (
        <Pane className="h-full">
            <TopSubNav menuItems={menuItems} />

            <Pane className="h-full">
                <Outlet />
            </Pane>
        </Pane>
    );
}