import { doRequest } from "./_helpers";
import { User } from "../types/apiTypes";
import BaseApi from "./_baseApi";
import { LoginData } from "../types/apiDataTypes";

export type LoginResponse = {
  jwt: string,
  user: User,
}

export type authApi = {
  login: Function,
  profile: Function,
}

export default class Auth extends BaseApi {
  login(data: LoginData): Promise<LoginResponse> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: '/auth/local',
      withCredentials: true,
      data: data,
    })
  }

  /**
   * This call should be done regularly to get a set-Cookie from the backend.
   * That cookie only valid for an hour and allows images to be downloaded / viewed by the browser.
   */
  ping(): Promise<LoginResponse> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: '/ping',
      withCredentials: true,
    })
  }

  profile() {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: '/profile',
      withCredentials: true,
    })
  }
}