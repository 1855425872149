import { ReportKey, ReportTemplateSection } from "../../../../types/apiTypes";
import { createElement, ReactNode, useMemo } from "react";
import { BanCircleIcon, CrossIcon, Pane, TickCircleIcon, TimeIcon } from "evergreen-ui";
import { WindDirection } from "./WindDirection";
import { ReportNotes } from "./ReportNotes";
import { Temperature } from "./Temperature";
import { Precipitation } from "./Precipitation";
import { Cloudiness } from "./Cloudiness";
import { WindSpeed } from "./WindSpeed";
import { AnimalNest } from "./AnimalNest";

const ReportFieldMap = {
    windDirection: WindDirection,
    notes: ReportNotes,
    temperature: Temperature,
    precipitation: Precipitation,
    cloudiness: Cloudiness,
    windSpeed: WindSpeed,
    animalNest: AnimalNest,
}

export const ReportStatusIconMap = {
    rejected: <CrossIcon size={14} color="danger" />,
    declined: <CrossIcon size={14} color="danger" />,
    cancel: <BanCircleIcon size={14} color="danger" />,
    approved: <TickCircleIcon size={14} color="success" />,
    pending: <TimeIcon size={14} color="muted" />,
}

export function ReportFieldsRow({ section, editMode }: { section: ReportTemplateSection, editMode: boolean}) {
    const rows = [] as ReactNode[][];

    const supportedKeys = useMemo(() => {
        return section.reportKeys.filter(r => ReportFieldMap[r.key]);
    }, [section]);

    for (let i = 0; i < supportedKeys.length; i += 2) {
        const row = [] as ReactNode[];

        row.push(
            <ReportFormField
                key={supportedKeys[i].key}
                reportKey={supportedKeys[i]}
                editMode={editMode}
            />
        )

        if ((i + 1) in supportedKeys) {
            row.push(
                <ReportFormField
                    key={supportedKeys[i + 1].key}
                    reportKey={supportedKeys[i + 1]}
                    editMode={editMode}
                />
            )
        }

        rows.push(row)
    }

    return <Pane>
        { rows.map(row => (
            <Pane className="flex w-full flex-wrap gap-2 py-2">
                { row.map(field => field) }
            </Pane>
        ))}
    </Pane>
}

function ReportFormField({ reportKey, editMode }: { reportKey: ReportKey, editMode: boolean }) {
    return <>
        {reportKey.key in ReportFieldMap &&
            createElement(ReportFieldMap[reportKey.key], {reportKey, editMode })
        }
    </>
}

