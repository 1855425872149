import { useNavigate, useParams } from "react-router-dom";
import {
    Avatar,
    Button,
    CalendarIcon,
    CrossIcon,
    DriveTimeIcon,
    EditIcon,
    EyeOpenIcon,
    Heading,
    IconButton, KnownVehicleIcon,
    Pane,
    Popover,
    Position,
    Text,
    TimeIcon
} from "evergreen-ui";
import {
    __r,
    ADMIN_USERS_EDIT_PAGE,
    ADMIN_WORKER_AVAILABILITY_PAGE,
    PLANNER_PERIOD_PLANNING_WORKER_SHIFT_REQUEST_OVERLAY
} from "src/RouteMap";
import WorkerItem, { WorkerItemProps } from "src/components/shared/worker/WorkerItem";
import { memo, useEffect, useMemo, useState } from "react";
import { WorkerApiType } from "src/types/apiTypes";
import { DisplayAddressSimple } from "src/components/shared/filters/AddressFilter";
import DurationValue from "../../../components/common/DurationValue";
import TravelDistanceValue from "../../../components/common/TravelDistanceValue";

interface WorkerItemWithPopoverProps extends WorkerItemProps {
    setPopupShownId?: (id: number | null) => void;
    popupShownId?: number | null;
}

const WorkerItemWithPopover = function ({ worker, setDriver = undefined, setIsDriving = undefined, onClick = undefined, onDelete = undefined, setPopupShownId = undefined, popupShownId = undefined, ...rest }: WorkerItemWithPopoverProps) {
    const navigate = useNavigate();
    const { periodId } = useParams();

    const [isShown, setIsShown] = useState<boolean>(false);
    const hasWorkerShiftRequest = !!worker.workerShiftRequest;

    function navToDialog(workerShiftRequest) {
        navigate({
            pathname: __r(PLANNER_PERIOD_PLANNING_WORKER_SHIFT_REQUEST_OVERLAY, {
                workerShiftRequestId: workerShiftRequest.id,
                periodId: periodId,
            }),
        });
    }

    function navToAvailability(worker: WorkerApiType) {
        navigate(
            __r(ADMIN_WORKER_AVAILABILITY_PAGE, {workerId: worker.id, periodId}), {
                state: {
                    backPath: window.location.pathname + window.location.search,
                },
            })
    }

    function onClickPopover(e) {
        e.stopPropagation();
        setIsShown(true);

        if (setPopupShownId) {
            setPopupShownId(worker.id)
        }
    }

    function onCloseComplete() {
        setIsShown(false);
    }

    useEffect(() => {
        if (popupShownId !== worker.id) {
            setIsShown(false);
        }
    }, [popupShownId])

    function goToDetailPage() {
        navigate({
            pathname: __r(ADMIN_USERS_EDIT_PAGE, {
                userId: worker.user?.id,
            }),
        });
    }

    return <Popover
        bringFocusInside
        key={worker.id}
        isShown={isShown}
        onClose={onCloseComplete}
        shouldCloseOnExternalClick={true}
        shouldCloseOnEscapePress={true}
        position={Position.TOP}
        content={
            <Pane onClick={(e) => {e.stopPropagation()}} className="gap-2" height={300} width={280} paddingX={20} paddingY={20} display="flex" flexDirection="column">
                <Pane className="flex">
                    <Pane className="grow">
                        <Text className="!font-bold py-1 flex gap-1 !text-xs overflow-ellipsis pr-1">
                            <DriveTimeIcon />
                            <TravelDistanceValue meters={worker.distanceToArea} />
                        </Text>

                        <Text className="!font-bold py-1 flex gap-1 !text-xs overflow-ellipsis pr-1">
                            <TimeIcon color="muted" size={14} />
                            <DurationValue seconds={worker.travelDuration} />
                        </Text>
                    </Pane>

                    <Pane className="shrink">
                        <IconButton
                            marginLeft="auto"
                            icon={CrossIcon}
                            appearance="minimal"
                            height={24}
                            onClick={(e) => { e.stopPropagation(); onCloseComplete(); } }
                        />
                    </Pane>
                </Pane>
                <Pane className="grow">
                    <Pane className="flex gap-1">
                        <Pane className="flex flex-col">
                            <Text className="!font-bold !text-xs overflow-ellipsis capitalize pr-1">
                                {`${worker.user?.firstName}`}
                            </Text>
                            <Text className="!font-bold !text-xs overflow-ellipsis capitalize">
                                {`${worker.user?.lastName}`}
                            </Text>
                            <Heading size={200} className="!text-xs overflow-ellipsis pr-1">
                                {`${worker.user?.email}`}
                            </Heading>
                            <Text className="!text-xs overflow-ellipsis capitalize pr-1">
                                {`${worker.phone ?? '-'}`}
                            </Text>
                        </Pane>

                        <Avatar
                            className="hover:opacity-90 ml-auto"
                            src={worker.user!.profilePictureThumbnail ?? ""}
                            size={60}
                        />
                    </Pane>
                </Pane>
                <DisplayAddressSimple address={worker.address} />

                {worker.carpool?.driver &&
                  <Pane>
                    <Pane className="pl-2 py-4 bg-gray-50">
                      <Pane className="flex gap-1 items-center">
                        <KnownVehicleIcon color="green" />
                          <Heading size={100}>Carpool bestuurder:</Heading>
                      </Pane>
                      <Pane>
                            <span className="!font-bold !text-xs overflow-ellipsis capitalize pr-1">
                                {`${worker.carpool.driver?.user?.firstName}`}
                            </span>
                        <span className="!font-bold !text-xs overflow-ellipsis capitalize">
                                {`${worker.carpool.driver?.user?.lastName}`}
                            </span>
                      </Pane>
                    </Pane>
                  </Pane>
                }
                <Button
                    paddingX={40}
                    height={40}
                    iconBefore={CalendarIcon}
                    intent="success"
                    appearance="primary"
                    onClick={(e) => { e.stopPropagation(); navToAvailability(worker) }}>Beschikbaarheid</Button>

                {hasWorkerShiftRequest &&
                  <Button
                    paddingX={40}
                    height={40}
                    appearance="primary"
                    intent="warning"
                    onClick={(e) => { e.stopPropagation(); navToDialog(worker.workerShiftRequest)}}
                    iconBefore={EyeOpenIcon}
                  >Bekijk verzoek...</Button>
                }

                <Button
                    paddingX={40}
                    height={40}
                    onClick={(e) => { e.stopPropagation(); goToDetailPage()}}
                    iconBefore={EditIcon}
                >Bewerken</Button>
            </Pane>
        }
    >
        <a className="relative">
            <WorkerItem
                {...rest}
                setIsDriving={setIsDriving}
                setDriver={setDriver}
                onDelete={onDelete}
                onClick={onClickPopover}
                key={'workeritem' + worker.id}
                worker={worker}
            />
        </a>
    </Popover>
}

export default memo(WorkerItemWithPopover);
