import {
    Avatar, BookIcon,
    CalendarIcon,
    DocumentIcon,
    GitRepoIcon,
    Heading,
    InboxIcon,
    LogOutIcon,
    Menu,
    Pane,
    PersonIcon,
    Popover,
    Position,
    Text,
    UserIcon
} from "evergreen-ui";
import { useAuth } from "../../context/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { classNames } from "../../lib/functions";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { difference, isEmpty, last } from 'lodash';
import {
    ADMIN_BOOKKEEPING_EXPORT_PAGE,
    ADMIN_PROJECTS_PAGE,
    ADMIN_USERS_PAGE,
    REPORTS_NEW_LIST_PAGE,
    WORKER_SHIFTS_PLANNED_PAGE
} from "../../RouteMap";
import { useApp } from "../../context/AppContext";

const menuItems = [
    {
        label: 'Planner',
        pathname: '/planner',
        icon: <CalendarIcon />,
        role: 'admin',
    },
    {
        label: 'Projecten',
        pathname: ADMIN_PROJECTS_PAGE,
        icon: <GitRepoIcon />,
        role: 'admin',
    },
    {
        label: 'Rapporten',
        pathname: REPORTS_NEW_LIST_PAGE,
        icon: <DocumentIcon />,
        role: 'reports',
    },
    {
        label: 'Boekhouding',
        pathname: ADMIN_BOOKKEEPING_EXPORT_PAGE,
        icon: <BookIcon />,
        role: 'admin',
    },
    {
        label: 'Gebruikers',
        pathname: ADMIN_USERS_PAGE,
        icon: <UserIcon />,
        role: 'admin',
    },
];

const workerMenuItems = [
    {
        label: 'navigation.your_shifts',
        pathname: WORKER_SHIFTS_PLANNED_PAGE,
        icon: <GitRepoIcon />,
    },
    {
        label: 'navigation.requests',
        pathname: '/periods',
        icon: <InboxIcon />,
    },
]

const MenuItem = function({ location, item }) {
    const { setSidebarIsOpen } = useApp();

    function onClickLink() {
        if (window.innerWidth <= 640) {
            setSidebarIsOpen(false)
        }
    }

    const { t } = useTranslation();

    const isSelected = useCallback((tabItem) => {
        const subLocsTab = tabItem.pathname.split('/');
        const subLocsHere = location.pathname.split('/')

        const diff = difference(subLocsHere, subLocsTab);

        return last(subLocsTab) === subLocsHere[subLocsTab.length - 1] || isEmpty(diff);
    }, [location.pathname])

    const className = classNames(isSelected(item) ? '!bg-gray-100': '');

    return (
        <Link to={item.pathname} onClick={onClickLink}>
            <Menu.Item
                className={className}
                icon={item?.icon}>{t(item.label)}
            </Menu.Item>
        </Link>
    )
}

function SidebarMenu() {
    const location = useLocation();
    const { user } = useAuth();

    return <Menu>
        <Menu.Group>
            {user!.role === 'admin' &&
                menuItems.map((item) => (
                    <MenuItem
                        key={item.pathname}
                        location={location}
                        item={item}
                    />
                ))
            }

            {user!.worker &&
                workerMenuItems.map((item) => (
                    <MenuItem
                        key={item.pathname}
                        location={location}
                        item={item}
                    />
                ))
            }
        </Menu.Group>
        <Menu.Divider />
    </Menu>
}

function SidebarContent() {
    return <>
        <Pane className="px-5 py-10 max-w-[15rem] text-3xl">
            <img src={"/logo.png"}  alt="Grofalex logo" />
        </Pane>
        <Pane>
            <SidebarMenu />
        </Pane>
    </>
}

function AccountSettingsMenu() {
    const { user, setUser, setJwt } = useAuth();

    const logout = () => {
        setJwt!(null);
        setUser!(null);
    }

    const isMobile = window.innerWidth <= 640;

    return <Menu>
            <Menu.Group>
                <Popover
                    bringFocusInside
                    statelessProps={{ style: { zIndex: 99 } }}
                    position={isMobile ? Position.TOP: Position.RIGHT}
                    content={
                        <Pane
                            width={200}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            flexDirection="column"
                        >
                            <Menu>
                                <Menu.Group>
                                    <Link to={'/account-settings'}>
                                        <Menu.Item icon={PersonIcon}>Account Settings</Menu.Item>
                                    </Link>
                                </Menu.Group>
                                <Menu.Divider />
                                <Menu.Group>
                                    <Menu.Item
                                        onClick={logout}
                                        icon={LogOutIcon}
                                        intent="danger">Logout</Menu.Item>
                                </Menu.Group>
                            </Menu>
                        </Pane>
                    }>
                    <Menu.Item className="justify-self-end" height={80} paddingTop={20} paddingBottom={20}>
                        <Heading>
                            { user?.profilePictureThumbnail ?
                                <Pane className="flex gap-3 items-center">
                                    <Avatar
                                        className="hover:opacity-90"
                                        src={user!.profilePictureThumbnail ?? ""}
                                        name={"You"}
                                        size={50}
                                    />

                                    <Text className="!font-bold">{user?.firstName} { user?.lastName }</Text>
                                </Pane>
                                :
                                <Avatar name={`${user?.firstName}} ${ user?.lastName }`} size={40} >
                                    <Text className="!font-bold">{user?.firstName} { user?.lastName }</Text>
                                </Avatar>
                            }
                        </Heading>
                    </Menu.Item>
                </Popover>
            </Menu.Group>
        </Menu>
}

export default function MainNavigationMenu() {
    const isMobile = window.innerWidth <= 640;

    return <Pane className="grow flex flex-col">
        <Pane className="grow">
            <SidebarContent />
        </Pane>

        <Pane className={classNames(isMobile ? "pb-20": "", "shrink")}>
            <Menu.Divider />
            <AccountSettingsMenu />
        </Pane>
    </Pane>
}