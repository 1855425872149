import { BanCircleIcon, TickCircleIcon } from "evergreen-ui";
import MoonIcon from "../common/illustrations/MoonIcon";
import SunriseIcon from "../common/illustrations/SunriseIcon";
import SunsetIcon from "../common/illustrations/SunsetIcon";

export const TimeslotIconMap = {
    moon: MoonIcon,
    sunrise: SunriseIcon,
    sunset: SunsetIcon,
}

export const ShiftStatusIconMap = {
    pending: TickCircleIcon,
    published: TickCircleIcon,
    planned: TickCircleIcon,
    cancelled: BanCircleIcon,
}
