import {
    Alert,
    Button,
    Card,
    FileCard,
    FormField,
    Heading,
    majorScale,
    Pane,
    Paragraph,
    Spinner,
    TextareaField,
    TextInputField,
    toaster,
    TrashIcon
} from "evergreen-ui";
import { useNavigate, useParams } from "react-router-dom";
import { Fragment, useState } from "react"
import { useApi } from "src/context/AxiosContext";
import { useDoRequest, useLoadResource } from "src/lib/request-hooks";
import Block from "src/components/common/Block";
import Form from "src/components/common/Form";
import Actions from "src/components/common/Actions";
import { Address, Area, AreaActivityType, Media } from "src/types/apiTypes";
import ActivityTypesSelect from "src/components/activity-types/ActivityTypesSelect";
import MultiFileUploader from "src/components/formfields/MultiFileUploader";
import { isEmpty } from 'lodash';
import Divider from "src/components/common/Divider";
import { useApp } from "src/context/AppContext";
import PageTitle from "src/components/common/PageTitle";
import MapBoxAddressInputField from "src/components/formfields/MapBoxAddressInputField";

function useAreaForm() {
    const { areaId, projectId } = useParams();

    // only retrieve data when we have an areaId.
    const shouldDoRequest = !!areaId;
    const { apiInstance } = useApi();

    const {
        handle,
        setIsLoading: setIsSubmitting,
        isLoading: isSubmitting,
        validationErrors,
        setValidationErrors,
        errorMessage: submitErrorMessage,
    } = useDoRequest();

    const find = () => apiInstance!.adminAreas.findOne(areaId!);
    const remove = () => apiInstance!.adminAreas.remove(areaId!);

    const [ name, setName ] = useState<string>("");
    const [ address, setAddress] = useState<Address | null>(null);
    const [ notes, setNotes ] = useState<string>("");
    const [ activityTypes, setActivityTypes] = useState<Partial<AreaActivityType>[]>([]);
    const [ mediaFiles, setMediaFiles ] = useState<Media[]>([])
    const [ uploadingMediaFiles, setUploadingMediaFiles ] = useState<Media[]>([])

    const doSubmit = async () => {
        const data = {
            name,
            activityTypes,
            addresses: address ? [address]: null,
            media: [...mediaFiles, ...uploadingMediaFiles],
            notes: notes ?? null,
        }

        let request;

        if (areaId) {
            request = apiInstance!.adminAreas.update(areaId, data);
        } else {
            request = apiInstance!.adminAreas.createForProject(projectId!, data);
        }

        await handle(request);

        toaster.success('Saved!');
    }

    const setData = (area: Area) => {
        setName(area.name);
        setAddress(area.addresses?.[0] || []);
        setActivityTypes(area.activityTypes);
        setMediaFiles(area.media);
        setNotes(area.notes);
    }

    function doRemove() {
        if (window.confirm("Weet je zeker dat je dit gebied wilt verwijderen?"))

        return remove()
    }

    function uploadFn(formData) {
        return apiInstance!.media.uploadToAreaTmp(formData);
    }

    function resetForm() {
        setName("");
        setAddress(null);
        // setActivityTypes([]);
        setMediaFiles([]);
        setNotes("");
        setIsSubmitting(false);
        setValidationErrors({});
        setUploadingMediaFiles([])
    }

    return {
        ...useLoadResource(find, setData, shouldDoRequest),
        name,
        setName,
        address,
        setAddress,
        activityTypes,
        setActivityTypes,
        mediaFiles,
        setMediaFiles,
        uploadFn,
        uploadingMediaFiles,
        setUploadingMediaFiles,
        notes,
        setNotes,
        doRemove,
        submitContext: {
            doSubmit,
            setIsSubmitting,
            isSubmitting,
            submitErrorMessage,
            validationErrors
        },
        resetForm,
    };
}

export default function AreaEditPage() {
    const navigate = useNavigate();
    const { areaId, projectId } = useParams();
    const { contentRef } = useApp();

    const {
        name,
        setName,
        activityTypes,
        isLoading,
        setActivityTypes,
        submitContext,
        address,
        setAddress,
        mediaFiles,
        setMediaFiles,
        uploadingMediaFiles,
        setUploadingMediaFiles,
        notes,
        setNotes,
        resetForm,
        refreshCount,
        setRefreshCount,
        uploadFn,
        doRemove,
    } = useAreaForm();

    async function doRemoveCb() {
        await doRemove();
        navigate(`/projects/${projectId}/areas`)
    }

    async function submit() {
        await submitContext.doSubmit();
        navigate(`/projects/${projectId}/areas`)
    }

    async function submitAndNew() {
        try {
            await submitContext.doSubmit();

            toaster.success('Saved!');

            resetForm();
            setRefreshCount(refreshCount + 1);

            // if an area id was present, we need to go the new url.
            if (areaId) {
                navigate(`/projects/${projectId}/areas/add`)
            }
        } finally {
            if (contentRef.current) {
                contentRef.current.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }
        }
    }

    return <Block>
        <Block className="flex gap-2 w-full">
            <PageTitle marginBottom={20}>{ !areaId ? "Maak een nieuw ": "Wijzig "}
                Gebied
            </PageTitle>

            {isLoading &&
              <Block className="flex justify-end">
                <Spinner size={20} />
              </Block>
            }
        </Block>

        {isLoading === false &&
          <Form>
            <>
              <TextInputField
                required
                name="name"
                label="Naam"
                placeholder="Naam gebied..."
                isInvalid={!!submitContext.validationErrors?.name}
                validationMessage={submitContext.validationErrors?.name?.join(', ')}
                value={name}
                onChange={(e) => setName(e.target.value)}
                marginBottom={0}
                inputHeight={50}
              />

              <Divider title={"Adres"}>
                <FormField
                  validationMessage={submitContext.validationErrors?.addresses?.join(', ')}
                  label={"Adres van dit gebied"}>
                    <MapBoxAddressInputField
                      showLatLng={true}
                      setAddress={setAddress}
                      adjustPin={true}
                      address={address}
                    />
                  {/*<AddressSelectInputField*/}
                  {/*  address={address}*/}
                  {/*  setAddress={setAddress}*/}
                  {/*/>*/}
                </FormField>
              </Divider>

              <Divider title={"Media"}>
                <FormField label={"Media"}>
                  <AttachedMediaFiles
                    mediaFiles={mediaFiles}
                    setMediaFiles={setMediaFiles}
                  />
                </FormField>

                <FormField>
                  <MultiFileUploader
                    uploadFn={uploadFn}
                    key={refreshCount}
                    mediaFiles={uploadingMediaFiles}
                    setMediaFiles={setUploadingMediaFiles}
                    setIsLoading={submitContext.setIsSubmitting}
                  />
                </FormField>
              </Divider>

              <TextareaField
                name="notes"
                label="Notities"
                placeholder="..."
                isInvalid={!!submitContext.validationErrors?.notes}
                validationMessage={submitContext.validationErrors?.notes?.join(', ')}
                value={notes || ""}
                onChange={(e) => setNotes(e.target.value)}
                marginBottom={0}
                inputHeight={40}
                className="!text-base"
              />

              <Divider title={"Activities"}>
                <FormField label={"Selecteer de activiteiten"}>
                  <ActivityTypesSelect
                    projectId={projectId!}
                    selected={activityTypes}
                    setSelected={setActivityTypes}
                    enableAll={!areaId}
                  />
                </FormField>
              </Divider>

                {submitContext.submitErrorMessage &&
                  <Pane>
                    <Alert
                      marginTop={10}
                      intent="danger"
                      title={submitContext.submitErrorMessage}
                    />
                  </Pane>
                }

              <Actions marginTop={10} marginBottom={10}>
                <Actions.Button
                  onClick={() => navigate(`/projects/${projectId}/areas`)}
                  disabled={!!submitContext.isSubmitting}
                  height={majorScale(5)}>Cancel
                </Actions.Button>

                <Actions.Button
                  onClick={submit}
                  isLoading={!!submitContext.isSubmitting}
                  appearance="primary"
                  intent="success"
                  height={majorScale(5)}>Opslaan
                </Actions.Button>

                <Actions.Button
                  onClick={submitAndNew}
                  isLoading={!!submitContext.isSubmitting}
                  appearance="primary"
                  height={majorScale(5)}>Opslaan en nieuw
                </Actions.Button>
              </Actions>

                {areaId &&
                  <Card title={"Danger zone"} elevation={1} className={"mt-8 p-8"}>
                    <Pane className="flex items-center w-full">
                      <Heading size={400}>Danger zone</Heading>
                      <Button
                        onClick={doRemoveCb}
                        iconBefore={<TrashIcon/>}
                        intent="danger"
                        appearance="primary"
                        marginLeft='auto'
                        height={majorScale(5)}>Delete
                      </Button>
                    </Pane>
                  </Card>
                }
            </>
          </Form>
        }
    </Block>
}

function AttachedMediaFiles({ mediaFiles, setMediaFiles }) {
    const handleRemove = (file) => {
        const updatedFiles = mediaFiles.filter((existingFile) => existingFile !== file)
        setMediaFiles(updatedFiles)
    }

    // async function onClick(media) {
    //     const src = media.src;
    //
    //     const response = await axios.get(src, {
    //         withCredentials: true,
    //     })
    //
    //     const redirectUrl = response.request.responseURL;
    //
    //     const a = document.createElement('a');
    //
    //     a.target = "_blank";
    //     a.style.display = 'none';
    //     a.href = redirectUrl;
    //
    //     // the filename you want
    //
    //     document.body.appendChild(a);
    //     a.click();
    //
    //     window.URL.revokeObjectURL(redirectUrl);
    // }
    return (
        <>
            {mediaFiles.map((media, index) => (
                <Fragment key={`${media.originalFileName}-${index}`}>
                    <a rel="noreferrer" className="hover:underline cursor-pointer" href={media.src} target="_top">
                        <FileCard
                            isInvalid={false}
                            name={media.originalFileName}
                            //@ts-ignore
                            onRemove={(e) => { e.preventDefault(); handleRemove(media) }}
                            sizeInBytes={media.size}
                            type={media.mimeType}
                            isLoading={false}
                            src={media.src}
                        />
                    </a>
                </Fragment>
            ))}

            { isEmpty(mediaFiles) &&
                <Paragraph>Nog geen bestanden geüpload...</Paragraph>
            }
        </>
    );
}