import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { Id, Invite } from "../types/apiTypes";
import { PostData, SearchParams } from "../types/appTypes";

export default class AdminInvites extends BaseApi {
  findOne(id: Id): Promise<Invite> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/invites/${id}`
    });
  }

  inviteWorker(data: PostData): Promise<Invite> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/invites/invite-worker`,
      data: data,
    });
  }

  sendNew(id: Id): Promise<Invite> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/invites/${id}/send-new`,
    });
  }

  findMany(searchParams : SearchParams = {}): Promise<Invite[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/invites${query}`,
    });
  }

  update(id: Id, data: PostData): Promise<Invite> {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/admin/invites/${id}`,
      data: data,
    });
  }

  remove(id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/admin/invites/${id}`,
    });
  }
}