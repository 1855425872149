import { useApi } from "src/context/AxiosContext";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { WorkerApiType, WorkerShift } from "src/types/apiTypes";
import { useDoRequest, useLoadResource } from "src/lib/request-hooks";
import {
    Avatar, CrossIcon,
    EmptyState,
    FilterIcon,
    Heading,
    majorScale,
    Pane,
    SearchIcon,
    Table,
    Text,
    TickIcon
} from "evergreen-ui";
import Page from "src/components/common/Page";
import StandardTable, { TableLoadingSpinner } from "../../../components/table/StandardTable";
import { isEmpty } from "lodash";
import DateValue from "../../../components/common/DateValue";
import PageTitle from "../../../components/common/PageTitle";
import { __r, ADMIN_USERS_EDIT_PAGE } from "../../../RouteMap";
import DurationValue from "../../../components/common/DurationValue";
import TravelDistanceValue from "../../../components/common/TravelDistanceValue";
import AdminShiftHistoryFilters from "./components/AdminShiftHistoryFilters";
import { FilterMapType } from "../../../types/appTypes";
import { endOfMonth, formatISO, startOfMonth } from "date-fns";
import { useWindowSearchParams } from "../../../hooks/useWindowSearchParams";
import { useDoFilter } from "../../../hooks/useDoFilter";
import { WorkerShiftAggregations } from "src/pages/worker-shifts/_components/WorkerShiftAggregations";
import Actions from "src/components/common/Actions";

function useWorker() {
    const  { apiInstance } = useApi();
    const { workerId } = useParams();

    const [worker, setWorker] = useState<WorkerApiType | null>(null);

    const find = () => apiInstance!.adminWorkers.findOne(workerId!);

    function setData(worker: WorkerApiType) {
        setWorker(worker);
    }

    return {
        ...useLoadResource(find, setData, !!workerId && !worker),
        worker,
    }
}

function useWorkerShifts() {
    const searchParams = useWindowSearchParams();
    const  { apiInstance } = useApi();
    const { workerId } = useParams();

    const [workerShifts, setWorkerShifts] = useState<WorkerShift[]>([]);

    const find = () => apiInstance!.adminWorkerShifts.findManyForWorker(workerId!, searchParams);

    const [initialized, setInitialized] = useState<boolean>(false);
    const [projectId, setProjectId] = useState<number | null>(null);
    const [fromDate, setFromDate] = useState<string | null>(searchParams.get('fromDate'));
    const [toDate, setToDate] = useState<string | null>(searchParams.get('toDate'));

    const [fromApprovedAtDate, setFromApprovedAtDate] = useState<string | null>(searchParams.get('fromApprovedAtDate'));
    const [toApprovedAtDate, setToApprovedAtDate] = useState<string | null>(searchParams.get('toApprovedAtDate'));

    const [projectType, setProjectType] = useState<string | null>(null);

    const filterMap: FilterMapType = {
        fromApprovedAtDate: {
            setter: setFromApprovedAtDate,
            topic: 'fromApprovedAtDate',
            value: fromApprovedAtDate,
            default: formatISO(startOfMonth(new Date())),
        },
        toApprovedAtDate: {
            setter: setToApprovedAtDate,
            topic: 'toApprovedAtDate',
            value: toApprovedAtDate,
            default: formatISO(endOfMonth(new Date())),
        },
        fromDate: {
            setter: setFromDate,
            topic: 'fromDate',
            value: fromDate,
            default: null,
        },
        toDate: {
            setter: setToDate,
            topic: 'toDate',
            value: toDate,
            default: null,
        },
        projectId: {
            setter: setProjectId,
            topic: 'projectId',
            value: projectId,
        },
        projectType: {
            setter: setProjectType,
            topic: 'projectType',
            value: projectType,
        },
    };

    function setData(workerShifts: WorkerShift[]) {
        setWorkerShifts(workerShifts);
    }

    const loadResourceLogic = useLoadResource(find, setData, initialized);

    const { fetchData, setDefaultFilters, setFilter, doSortBy, handleInput, doFilter } = useDoFilter( {
        searchParams,
        setInitialized,
        initialized,
        filterMap,
        loadResourceLogic,
        find,
        setData,
        setMeta: () => {},
    });

    useEffect(() => {
        if (!initialized) {
            setDefaultFilters();
        }
    }, [initialized])

    return {
        ...loadResourceLogic,
        // ...useLoadResource(find, setData, !!workerId),
        workerShifts,
        doFilter,
        doSortBy,
        fetchData,
        filterMap,
        handleInput,
        setData,
        setDefaultFilters,
        setFilter,
    }
}

function useExportLogic() {
    const searchParams = useWindowSearchParams();
    const { apiInstance } = useApi();

    const { workerId } = useParams();

    const {
        handle,
        isLoading,
    } = useDoRequest();

    async function doExport() {
        const request = apiInstance!.adminWorkerShifts.exportToCsv(workerId!, searchParams);

        return await handle(request)
    }

    return {
        doExport,
        isLoading,
    }
}

export default function AdminWorkerShiftHistoryPage() {
    const {
        isLoading,
        worker,
    } = useWorker();

    const location = useLocation();
    const filterLogic = useWorkerShifts();

    const {
        doExport,
        isLoading: isExporting,
    } = useExportLogic()

    async function doExportCb() {
        const { url } = await doExport();
        const a = document.createElement('a');

        a.target = "_blank";
        a.style.display = 'none';
        a.href = url;

        // the filename you want
        a.download = 'export.csv';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    const {
        isLoading: workerShiftsLoading,
        meta,
        refresh,
        workerShifts,
    } = filterLogic;

    if (isLoading) {
        return <Page>
            <TableLoadingSpinner title={"Data laden.."} />
        </Page>
    }

    if (!worker) {
        return <Page>
            <EmptyState
                background="light"
                title="Geen veldwerker gevonden"
                orientation="horizontal"
                icon={<SearchIcon color="#C1C4D6"/>}
                iconBgColor="#EDEFF5"
                description=""
            />
        </Page>
    }

    const { backPath } = location?.state ?? {};
    const bPath =  backPath ?? __r(ADMIN_USERS_EDIT_PAGE, { userId: worker?.user.id });

    return <Page>
        <PageTitle
            actions={
                <Actions.Button
                    textOverflow={"ellipsis"}
                    appearance="primary"
                    intent="success"
                    isLoading={isExporting}
                    onClick={doExportCb}
                    height={majorScale(4)}>Exporteer
                </Actions.Button>
            }
            backPath={bPath}>
            Gelopen diensten.
        </PageTitle>

        <Pane className="py-2">
            <Pane className="flex flex-col items-center justify-center" >
                <Avatar
                    className="hover:opacity-90"
                    src={worker.user.profilePicture ?? ""}
                    name={""}
                    size={100}
                />
                <Pane className="flex flex gap-1 py-4">
                    <Text>{worker.user.firstName}</Text>
                    <Text>{worker.user.lastName}</Text>
                </Pane>

                <Pane>
                    <WorkerShiftAggregations
                        workerShifts={workerShifts}
                    />
                </Pane>
            </Pane>

            <Pane className="flex flex-col gap-2">
                <Pane className="flex gap-1 items-center">
                    <FilterIcon color="muted" size={majorScale(2)} />
                    <Heading size={300}>Filters</Heading>
                </Pane>
                <Pane className={'w-8 h-[1px] bg-gray-200'} />
                <AdminShiftHistoryFilters filterLogic={filterLogic} />
                <Pane className={'w-8 h-[1px] bg-gray-200'} />
            </Pane>

            <StandardTable refresh={refresh} meta={meta} isLoading={isLoading} data={workerShifts} emptyState={<EmptyWorkerShiftState />}>
                <Table.Head>
                    <StandardTable.HeaderCell>Goedgekeurd</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Gelopen op</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Reistijd</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Gewerkt</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Opdrachtgever</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Gebied</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Activiteit</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>KM</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Start</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Eind</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Bestuurder</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Carpool &gt; 1</StandardTable.HeaderCell>
                    <StandardTable.HeaderCell>Nachtdienst</StandardTable.HeaderCell>
                </Table.Head>
                <Table.Body height="auto">
                    {workerShifts.map((workerShift) => (
                        <Table.Row key={workerShift.id}>
                            <Table.TextCell><DateValue date={workerShift.shiftReport.approvedAt} /></Table.TextCell>
                            <Table.TextCell><DateValue date={workerShift.startedAt} /></Table.TextCell>
                            <Table.TextCell>
                                <DurationValue seconds={workerShift.travelDuration} />
                            </Table.TextCell>
                            <Table.TextCell>
                                <DurationValue seconds={workerShift.shiftDuration} />
                            </Table.TextCell>
                            <Table.TextCell>{workerShift.shift.project?.name}</Table.TextCell>
                            <Table.TextCell>{workerShift.shift.area?.name}</Table.TextCell>
                            <Table.TextCell>{workerShift.shift.areaActivityType?.activityType.name}</Table.TextCell>
                            <Table.TextCell>
                                <TravelDistanceValue meters={workerShift.travelDistance} />
                            </Table.TextCell>
                            <Table.TextCell><DateValue date={workerShift.startedAt} formatStr='p' /></Table.TextCell>
                            <Table.TextCell><DateValue date={workerShift.endedAt} formatStr='p'/></Table.TextCell>
                            <Table.TextCell>
                                {workerShift.isDriving ? <TickIcon />:<CrossIcon />}
                            </Table.TextCell>
                            <Table.TextCell>
                                {workerShift.moreThanOneInCarpool ? <TickIcon />:<CrossIcon />}
                            </Table.TextCell>
                            <Table.TextCell>
                                {workerShift.shift.isNightShift ?  <TickIcon />:<CrossIcon />}
                            </Table.TextCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </StandardTable>
        </Pane>
    </Page>
}

function EmptyWorkerShiftState() {
    return (
        <EmptyState
            background="light"
            title="Geen diensten gevonden"
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="Diensten verschijnen hier als een veldwerker diensten heeft gelopen."
        />
    )
}

