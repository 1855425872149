import { createContext, useContext } from "react";
import { User } from "../types/apiTypes";

export type SetUserFunction = (user: User | null) => void;
export type SetJwtFunction = (jwt: string | null, setInLocalStorage?: boolean | undefined) => void;

export type AuthContextType = {
  jwt: string | null,
  user: User | null,
  setUser: SetUserFunction | null,
  setJwt: SetJwtFunction | null,
}

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthContext = createContext({
  jwt: null,
  user: null,
  setUser: null,
  setJwt: null,
} as AuthContextType);
