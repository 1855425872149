import { classNames } from '../../lib/functions';
import Block from "./Block";
import { PaneProps, PolymorphicBoxProps, Spinner } from "evergreen-ui";
import { memo } from "react";

function Content(props) {
    const {
        className,
        children,
        onSubmit = null,
        staticIsLoading = false,
        ...rest
    } = props

    return <Block className={className} {...rest}>
        {staticIsLoading &&
          <Block className="flex justify-end">
            <Spinner size={20}/>
          </Block>
        }
        {staticIsLoading === false &&
            children
        }
    </Block>
}

// const ActivityTypesSelect = memo(function ActivityTypesSelect({ selected = [], setSelected, enableAll = false }: ActivityTypesSelectProps) {
//
// }
type FormTypeOwnProps = {
    children: JSX.Element | null;
    onSubmit?: Function | null;
    staticIsLoading?: boolean | null;
    className?: string | null;
    rest?: PaneProps,
}

type FormTypeProps = PolymorphicBoxProps<'div', FormTypeOwnProps>;

const Form = memo(function Form({ children, onSubmit = null, staticIsLoading = false, ...rest }: FormTypeProps) {
    const { className } = rest;

    const classes = classNames('flex flex-col gap-5 max-w-4xl', className);

    function onSubmitForm(e) {
        e.preventDefault();

        if (onSubmit) {
            onSubmit();
        }
    }

    if (onSubmit) {
        return <form onSubmit={(e) => onSubmitForm(e)}>
            <Content className={classes} {...rest}>{children}</Content>
        </form>
    } else {
        return <Content className={classes} {...rest}>{children}</Content>
    }
});

export default Form;
