import { Dialog, Heading, Text, Pane, Paragraph, toaster, TextareaField, Group, Button, FormField } from "evergreen-ui";
import { useApi } from "../../../context/AxiosContext";
import { useNavigate, useParams } from "react-router-dom";
import { WorkerShift, WorkerShiftRequest } from "../../../types/apiTypes";
import { useMemo, useState } from "react";
import { useDoRequest, useLoadResource } from "../../../lib/request-hooks";
import { __r, PLANNER_PERIOD_PLANNING_PAGE } from "../../../RouteMap";

function useWorkerShiftRequestForm() {
    const { workerShiftRequestId } = useParams();
    const {apiInstance} = useApi();
    const [workerShiftRequest, setWorkerShiftRequest] = useState<WorkerShiftRequest>();

    const [notes, setNotes] = useState<string>('');
    const [selectedValue, setSelectedValue] = useState('accepted')

    const acceptOptions = useMemo(
        () => [
            { label: 'Accepteren', value: 'accepted', intent: 'success' },
            { label: 'Afwijzen', value: 'declined', intent: 'danger' },
        ],
        []
    )

    const {
        handle,
        setIsLoading: setIsSubmitting,
        isLoading: isSubmitting,
        validationErrors,
        setValidationErrors,
        errorMessage: submitErrorMessage,
    } = useDoRequest();

    const doSubmit = async () => {
        const data = {
            notes: notes ?? null,
        }

        let request;

        if (selectedValue === 'accepted') {
            request = apiInstance!.adminWorkerShiftRequests.acceptRequest(workerShiftRequestId!, data);
        } else {
            request = apiInstance!.adminWorkerShiftRequests.declineRequest(workerShiftRequestId!, data);
        }

        await handle(request);

        toaster.success('Saved!');
    }

    function setData(workerShiftRequest: WorkerShiftRequest) {
        setNotes(workerShiftRequest.adminNotes ?? '');
        setSelectedValue(workerShiftRequest.status);
        setWorkerShiftRequest(workerShiftRequest)
    }

    const find = () => apiInstance!.adminWorkerShiftRequests.findOne(workerShiftRequestId!);

    return {
        ...useLoadResource(find, setData, !!workerShiftRequestId),
        workerShiftRequest,
        submitContext: {
            doSubmit,
            setIsSubmitting,
            isSubmitting,
            submitErrorMessage,
            validationErrors
        },
        notes,
        setNotes,
        doSubmit,
        acceptOptions,
        selectedValue,
        setSelectedValue,
    }
}

export default function WorkerShiftRequestDialog() {
    const navigate = useNavigate();
    const { periodId } = useParams();

    const {
        workerShiftRequest,
        submitContext,
        notes,
        setNotes,
        doSubmit,
        acceptOptions,
        selectedValue,
        setSelectedValue,
    } = useWorkerShiftRequestForm();

    function onCloseDialog() {
        navigate({
                pathname: __r(PLANNER_PERIOD_PLANNING_PAGE, {periodId})
            }, {
                state: {
                    refresh: true,
                }
            }
        )
    }

    async function onConfirm() {
        await doSubmit();
        setNotes('')
        onCloseDialog();
    }

    return <Dialog
        isShown={true}
        onConfirm={onConfirm}
        confirmLabel="Opslaan"
        title="Verzoek tot wijziging"
        onCloseComplete={onCloseDialog}
        shouldCloseOnOverlayClick={false}
    >
        <Pane>
            {workerShiftRequest &&
              <Pane className="flex flex-col gap-1">
                <Pane>
                  <Heading size={500}>Aanvraag:</Heading>
                  <Paragraph className="pt-2 !text-xs">
                      {workerShiftRequest.notes}
                  </Paragraph>
                </Pane>

                <FormField
                  label="Accepteren / afwijzen" className="pt-4">
                  <Group>
                      {acceptOptions.map(({ label, value, intent }) => (
                          <Button intent={intent} appearance={ selectedValue === value ? "primary": "default"} key={label} onClick={() => setSelectedValue(value)}>
                              {label}
                          </Button>
                      ))}
                  </Group>
                </FormField>

                <Pane>
                  <TextareaField
                    hint="Geef eventueel aan of je de aanvraag geaccepteerd of niet."
                    name="reactie"
                    label="Reactie"
                    placeholder="Ik zal deze keer..."
                    isInvalid={!!submitContext.validationErrors?.notes}
                    validationMessage={submitContext.validationErrors?.notes?.join(', ')}
                    value={notes || ""}
                    onChange={(e) => setNotes(e.target.value)}
                    marginTop={20}
                    marginBottom={0}
                    inputHeight={40}
                    className="!text-base"
                  />
                </Pane>
              </Pane>
            }
        </Pane>
    </Dialog>
}