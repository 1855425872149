import Page from "src/components/common/Page";
import PageTitle from "src/components/common/PageTitle";
import { EmptyState, FilterIcon, Heading, majorScale, Pane, RefreshIcon, SearchIcon, Spinner } from "evergreen-ui";
import { useWindowSearchParams } from "src/hooks/useWindowSearchParams";
import { isEmpty } from "lodash";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { useEffect, useState } from "react";
import { Area, ReportTemplate } from "../../types/apiTypes";
import { useApi } from "../../context/AxiosContext";
import AreaResultsTable from "./_components/AreaResultsTable";
import AreaResultsFilters from "./_components/AreaResultsFilters";
import { endOfMonth, formatISO, startOfMonth } from "date-fns";
import { TableLoadingSpinner } from "../../components/table/StandardTable";
import { useTranslation } from "react-i18next";
import { useDoFilter } from "../../hooks/useDoFilter";
import Actions from "../../components/common/Actions";
import { FilterMapType, HookOptions } from "../../types/appTypes";

const useShiftReportTemplate = function(options: HookOptions = {}) {
    const { apiInstance } = useApi();

    const [template, setTemplate] = useState<ReportTemplate>();
    const request = () => apiInstance!.workerShiftReport.getTemplate();

    const setData = (reports) => {
        setTemplate(reports);
    }

    return {
        ...useLoadResource(request, setData, true),
        template,
    };
}

const useAreas = function () {
    const searchParams = useWindowSearchParams();

    const [ areas, setAreas ] = useState<Area[] | []>([]);
    const { apiInstance } = useApi()

    const find = () => apiInstance!.adminAreas.findManyResults(searchParams);

    const setData = (areas) => {
        const filteredAreas = areas.filter(v => !isEmpty(v.areaActivityTypes))

        setAreas(filteredAreas);
    }

    const [initialized, setInitialized] = useState<boolean>(false);
    const [workerId, setWorkerId] = useState<number | null>(null);
    const [projectId, setProjectId] = useState<number | null>(null);
    const [projectType, setProjectType] = useState<string | null>(null);
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);

    const filterMap: FilterMapType = {
        fromDate: {
            setter: setFromDate,
            topic: 'fromDate',
            value: fromDate,
            default: formatISO(startOfMonth(new Date())),
        },
        toDate: {
            setter: setToDate,
            topic: 'toDate',
            value: toDate,
            default: formatISO(endOfMonth(new Date())),
        },
        workerId: {
            setter: setWorkerId,
            topic: 'workerId',
            value: workerId,
        },
        projectId: {
            setter: setProjectId,
            topic: 'projectId',
            value: projectId,
        },
        projectType: {
            setter: setProjectType,
            topic: 'projectType',
            value: projectType,
        },
    };

    const loadResourceLogic = useLoadResource(find, setData, initialized);

    const { fetchData, setDefaultFilters, setFilter, doSortBy, handleInput, doFilter } = useDoFilter( {
        searchParams,
        setInitialized,
        initialized,
        filterMap,
        loadResourceLogic,
        find,
        setData,
        setMeta: () => {},
    });

    useEffect(() => {
        if (!initialized) {
            setDefaultFilters();
        }
    }, [])

    return {
        ...loadResourceLogic,
        doFilter,
        doSortBy,
        fetchData,
        filterMap,
        handleInput,
        areas,
        setData,
        setDefaultFilters,
        setFilter,
    };
}

function useExportLogic() {
    const searchParams = useWindowSearchParams();
    const { apiInstance } = useApi();

    const {
        handle,
        isLoading,
    } = useDoRequest();

    async function doExport() {
        const request = apiInstance!.adminAreas.exportResultsCsv(searchParams);

        return await handle(request)
    }

    return {
        doExport,
        isLoading,
    }
}

export default function ShiftResultsPage() {
    const filterLogic = useAreas();

    const { template } = useShiftReportTemplate();

    const {
        areas,
        meta,
        isLoading,
        refresh,
    } = filterLogic;

    const {
        doExport,
        isLoading: isExporting,
    } = useExportLogic()

    async function doExportCb() {
        const { url } = await doExport();
        const a = document.createElement('a');

        a.target = "_blank";
        a.style.display = 'none';
        a.href = url;

        // the filename you want
        a.download = 'export.csv';

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(url);
    }

    return <Page>
        <PageTitle
            actions={
                <Actions>
                    <Actions.Button
                        textOverflow={"ellipsis"}
                        appearance="primary"
                        intent="success"
                        isLoading={isExporting}
                        onClick={doExportCb}
                        height={majorScale(4)}>Exporteer
                    </Actions.Button>

                    {refresh &&
                      <Actions.IconButton
                        appearance="minimal"
                        icon={RefreshIcon}
                        onClick={refresh}
                        type="button" />
                    }
                </Actions>
            }
        >
            <Pane className="flex gap-1">
                Resultaten
                {isLoading &&
                  <Pane className="flex justify-end">
                    <Spinner size={30} />
                  </Pane>
                }
            </Pane>
        </PageTitle>

        <Pane>
            <Pane className="flex flex-col gap-2">
                <Pane className="flex gap-1 items-center">
                    <FilterIcon color="muted" size={majorScale(2)} />
                    <Heading size={300}>Filters</Heading>
                </Pane>
                <Pane className={'w-8 h-[1px] bg-gray-200'} />
                <AreaResultsFilters filterLogic={filterLogic} />
                <Pane className={'w-8 h-[1px] bg-gray-200'} />
            </Pane>

            {isLoading &&
              <TableLoadingSpinner title={"Gebieden en rapporten aan het ophalen voor deze periode..."}/>
            }

            {!isLoading &&
              <AreaResultsTable
                template={template}
                areas={areas} />
            }

            {isLoading === false && isEmpty(areas) && <AreaActivityTypesEmptyState /> }
        </Pane>
    </Page>
}

function AreaActivityTypesEmptyState() {
    const { t } = useTranslation();

    return <EmptyState
        background="light"
        title={t('planner_page.empty_area_activities_title')}
        orientation="horizontal"
        icon={<SearchIcon color="#C1C4D6"/>}
        iconBgColor="#EDEFF5"
        description={t('planner_page.empty_area_activities_description')}
    />
}

