import { Alert, FormField, Heading, majorScale, Pane, Select, Spinner, TextInputField, toaster } from "evergreen-ui";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/common/Block";
import { useState } from "react";
import { useApi } from "../../context/AxiosContext";
import Form from "../../components/common/Form";
import Actions from "../../components/common/Actions";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { projectTypeOptions } from "../../components/config/project-types";
import Divider from "../../components/common/Divider";
import PageTitle from "../../components/common/PageTitle";
import Page from "../../components/common/Page";
import { __r, ADMIN_ACTIVITY_TYPE_ADD_PAGE } from "../../RouteMap";

const useProjectFormFields = function () {
    const { projectId } = useParams();
    const [name, setName] = useState<string>("");
    const [type, setType] = useState<string | null>('private');
    const [organisationName, setOrganisationName] = useState<string | null>();
    const navigate = useNavigate();

    const { handle, isLoading: isSubmitting, validationErrors, errorMessage: submitErrorMessage } = useDoRequest();
    const { apiInstance } = useApi();

    const find = () => apiInstance!.adminProjects.findOne(projectId!);

    const setData = (project) => {
        setName(project.name);
        setType(project.type)
        setOrganisationName(project.organisationName)
    }

    const doSubmit = async () => {
        const data = {
            name,
            type,
            organisationName,
        }

        let request;
        if (projectId) {
            request = apiInstance!.adminProjects.update(projectId, data);
        } else {
            request = apiInstance!.adminProjects.create(data);
        }

        const project = await handle(request);

        toaster.success('Saved!')

        if (projectId) {
            navigate(-1)
        } else {
            navigate(__r(ADMIN_ACTIVITY_TYPE_ADD_PAGE, { projectId: project.id }))
        }
    }

    return {
        ...useLoadResource(find, setData, !!projectId),
        name,
        setName,
        type,
        setType,
        organisationName,
        setOrganisationName,
        doSubmit,
        submitContext: {
            doSubmit,
            isSubmitting,
            submitErrorMessage,
            validationErrors
        },
    };
}

export default function ProjectsEditPage() {
    const navigate = useNavigate();

    const {
        name,
        setName,
        isLoading,
        type,
        setType,
        organisationName,
        setOrganisationName,
        doSubmit,
        submitContext,
    } = useProjectFormFields();

    return <Page>
        <Block className="flex gap-2 w-full">
            <PageTitle marginBottom={20}>Project settings</PageTitle>

            {isLoading &&
              <Block className="flex justify-end">
                <Spinner size={20}/>
              </Block>
            }
        </Block>

        <Form>
            <>
                <FormField>
                    <TextInputField
                        required
                        isInvalid={!!submitContext.validationErrors?.name}
                        validationMessage={submitContext.validationErrors?.name?.join(', ')}
                        marginBottom={0}
                        inputHeight={40}
                        label="Name"
                        name="name"
                        placeholder="Project X Y Z"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </FormField>

                <Divider title="Client details">
                    <FormField
                        required
                        validationMessage={submitContext.validationErrors?.type?.join(', ')}
                        marginBottom={25}
                        label="Client type *">
                        <Select
                            value={type ?? ""}
                            onChange={e => setType(e.target.value)}
                            width={240}
                            isInvalid={!!submitContext.validationErrors?.type}
                            height={40}>
                            { projectTypeOptions.map((option) => (
                                <option key={option.value} value={ option.value }>
                                    { option.label}
                                </option>
                            )) }
                        </Select>
                    </FormField>

                    <TextInputField
                        isInvalid={!!submitContext.validationErrors?.organisationName}
                        validationMessage={submitContext.validationErrors?.organisationName?.join(', ')}
                        inputHeight={40}
                        label="Organisation name"
                        name="organisationName"
                        placeholder="Company XYZ"
                        value={organisationName ?? ""}
                        onChange={(e) => setOrganisationName(e.target.value)}
                    />
                </Divider>

                {submitContext.submitErrorMessage &&
                  <Pane>
                    <Alert
                      marginTop={10}
                      intent="danger"
                      title={submitContext.submitErrorMessage}
                    />
                  </Pane>
                }

                <Actions marginTop={10} marginBottom={10}>
                    <Actions.Button
                        onClick={() => navigate(-1)}
                        disabled={!!submitContext.isSubmitting}
                        height={majorScale(5)}>Cancel
                    </Actions.Button>

                    <Actions.Button
                        onClick={doSubmit}
                        intent="success"
                        isLoading={!!submitContext.isSubmitting}
                        appearance="primary"
                        height={majorScale(5)}>Save
                    </Actions.Button>
                </Actions>
            </>
        </Form>
    </Page>
}