import { useMemo } from "react";

interface keyValue {
    [key: string]: string;
}

export const useWindowSearchParams = (defaults: keyValue = {}) => {
    return useMemo(() => {
        const searchParams = new URLSearchParams(window.location.search)

        for (const [key, value] of Object.entries(defaults)) {
            if (!searchParams.has(key)) {
                searchParams.set(key, value);
            }
        }

        return searchParams;
    }, [window?.location.search]);
}
