import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { Id, WorkerShiftRequest } from "../types/apiTypes";
import { PostData } from "../types/appTypes";

export default class AdminWorkerShiftRequests extends BaseApi {
  findOne(id: Id): Promise<WorkerShiftRequest> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/worker-shift-requests/${id}`
    });
  }

  declineRequest(id: Id, data: PostData): Promise<WorkerShiftRequest> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/worker-shift-requests/${id}/decline`,
      data: data,
    });
  }

  acceptRequest(id: Id, data: PostData): Promise<WorkerShiftRequest> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/worker-shift-requests/${id}/accept`,
      data: data,
    });
  }
}