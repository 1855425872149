import { CrossIcon, Heading, IconButton, Pane } from "evergreen-ui";
import { Project, SelectOption } from "../../../types/apiTypes";
import SelectInputField from "../../formfields/SelectInputField";
import { useMemo, useState } from "react";
import { useApi } from "../../../context/AxiosContext";
import { useLoadResource } from "../../../lib/request-hooks";

export function useProjectsForFiltering({ projectId, setProject }) {
    const { apiInstance } = useApi();
    const [ projects, setProjects ] = useState<Project[]>([]);

    const searchParams = new URLSearchParams();
    searchParams.set('limit', '1000');

    const find = () => apiInstance!.adminProjects.findMany(searchParams);
    const findOne = () => apiInstance!.adminProjects.findOne(projectId!);

    useLoadResource(findOne, setProject, projectId);

    return {
        ...useLoadResource(find, setProjects, true),
        projects,
    }
}
export default function ProjectFilter({ filterMap, handleInput }) {
    const [ project, setProject] = useState<SelectOption | null>(null);

    function setProjectOption(project) {
        setProject({
            value: project.id,
            label: project.name + ' (' + project.type + ')'
        })
    }

    const { projects } = useProjectsForFiltering({projectId: filterMap.projectId.value, setProject: setProjectOption });

    const projectOptions = useMemo(function() {
        const defaultOptions = [
            // {
            //     value: 'privat',
            //     label: 'Particulier'
            // },
            // {
            //     value: 'organisation',
            //     label: 'Organisatie'
            // },
        ];

        const projectAsOptions = projects.map(v => ({
            value: v.id,
            label: v.name + ' (' + v.type + ')'
        }));

        //@ts-ignore
        return defaultOptions.concat(projectAsOptions);
    }, [projects])

    function setSelected(value) {
        setProject(value)

        // if (['privat', 'organisation'].includes(value?.value)) {
        //     handleInput(filterMap.projectId, null);
        //     handleInput(filterMap.projectType, value.value);
        //
        //     return;
        // }

        handleInput(filterMap.projectType, null);
        handleInput(filterMap.projectId, value?.value ?? null);
    }

    return <Pane className="flex flex-col gap-1">
        <Pane className="flex justify-between h-4">
            <Heading className={project ? "!text-black !font-bold": ""} size={200}>Project</Heading>
            {project &&
              <IconButton icon={CrossIcon} appearance="minimal" height={24} onClick={() => setSelected(null)}/>
            }
        </Pane>
        <SelectInputField
            closeOnSelect
            selected={project}
            setSelected={setSelected}
            title="Select project"
            emptySelectText="Filter op een project..."
            options={projectOptions}
        />
    </Pane>
}
