import { ChevronDownIcon, ChevronUpIcon, Heading, IconButton, Pane, PaneProps } from 'evergreen-ui';
import { forwardRef, useState } from "react";

interface CollapseProps extends PaneProps {
    title?: string;
    defaultIsOpen?: boolean;
}

const Collapse = forwardRef<HTMLDivElement, CollapseProps>((props, ref) => {
    const { title, children, defaultIsOpen, ...rest } = props

    const [isOpen, setIsOpen] = useState<boolean>(defaultIsOpen ?? true);

    return (
        <Pane ref={ref} {...rest}>
            <Pane className={'w-full flex gap-2 items-center'}>
                <Pane className={'flex-1 h-[1px] bg-gray-200'} />
                <IconButton
                    onClick={() => setIsOpen(!isOpen)}
                    icon={isOpen ? ChevronUpIcon: ChevronDownIcon}
                    appearance="minimal"
                    type="button"
                />
                <Pane onClick={() => setIsOpen(!isOpen)} className="flex gap-1 items-center justify-center w-[7rem]">
                    { title &&
                      <Heading className={"text-center"} size={100}>{ title }</Heading>
                    }
                </Pane>
            </Pane>
            { isOpen && children }
        </Pane>
    )
});

export default Collapse;