import {
    Badge,
    InfoSignIcon,
    Pane,
    PercentageIcon,
    PersonIcon,
    Pill,
    Popover,
    Position,
    Text,
    useTheme
} from "evergreen-ui";
import { useNavigate } from "react-router-dom";
import { createElement, memo, useMemo, useState } from "react";
import { addDays, parseISO, subDays } from "date-fns";
import { isEmpty } from "lodash";

import DateValue from "src/components/common/DateValue";
import { Area, AreaActivityType, Period, Shift, Timeslot } from "src/types/apiTypes";
import { DateFormat } from "src/components/common/DateFormat";
import { __r, PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY } from "src/RouteMap";
import { classNames, roundNumber } from "src/lib/functions";
import WorkerItemWithPopover from "./WorkerItemWithPopover";
import { ShiftStatusIconMap, TimeslotIconMap } from "src/components/config/iconMaps";
import { usePlannerContext } from "../../../context/PlannerContext";

// a shift but with a little extra fields to make it easier to work with
export interface ShiftAugmented extends Shift {
    timeslot: Timeslot;
}

type PlannerRoundsItemProps = {
    areaActivityType: AreaActivityType;
    entry: any;
    index: number;
    period: Period;
    plan: Area[];
    shift: ShiftAugmented | null;
    shifts: ShiftAugmented[];
    startDate: Date;
}

/**
 * Split shifts into left and right side of the period end date.
 *
 * @param shifts
 * @param period
 */
function splitShifts(shifts: Shift[], period: Period): Shift[][] {
    if (!period) {
        return [[], []];
    }

    const periodEnd = parseISO(period.endAt);

    const leftShifts : Shift[] = [];
    const rightShifts : Shift[] = [];

    for (const shift of shifts) {
        const shiftDate = parseISO(shift.date);

        if (shiftDate <= periodEnd) {
            leftShifts.push(shift);
        } else {
            rightShifts.push(shift);
        }
    }

    return [
        leftShifts,
        rightShifts,
    ];
}

type useMinMaxStartDateProps = {
    shifts: Shift[];
    period: Period;
    shift: Shift | null;
    areaActivityType: AreaActivityType;
}

/**
 * @param shifts
 * @param period
 * @param shift
 * @param areaActivityType
 */
export function useMinMaxStartDate({ shifts, period, shift, areaActivityType }: useMinMaxStartDateProps) {
    const [leftShifts, rightShifts] = useMemo(() => splitShifts(shifts, period), [shifts, period]);

    const [leftStartDate, rightStartDate] = useMemo(() => {
        if (!period) {
            return [new Date(), new Date()];
        }

        if (!areaActivityType) {
            return [new Date(), new Date()];
        }

        const minDaysBetweenRounds = areaActivityType.activityType.minDaysBetweenRounds;

        const periodStart = parseISO(period.startAt);
        const periodEnd = parseISO(period.endAt);

        const left = shift ? leftShifts.filter(s => s.id !== shift?.id): leftShifts;

        const lastLeftShift = left[left.length - 1];
        const firstRightShift = rightShifts[0];

        let minStartDate = lastLeftShift ? addDays(parseISO(lastLeftShift.date), minDaysBetweenRounds) : periodStart;

        if (minStartDate < periodStart) {
            minStartDate = periodStart;
        }

        let maxStartDate = firstRightShift ? subDays(parseISO(firstRightShift.date), minDaysBetweenRounds): periodEnd;

        if (maxStartDate > periodEnd) {
            maxStartDate = periodEnd;
        }

        return [minStartDate, maxStartDate];
    }, [leftShifts, rightShifts, areaActivityType, period, shift]);

    const minStartDate = useMemo(() => {
        if (!period) {
            return new Date();
        }

        if (leftStartDate > parseISO(period.endAt)) {
            return leftStartDate;
        }

        if (rightStartDate < parseISO(period.startAt)) {
            return rightStartDate;
        }

        return leftStartDate;
    }, [period, leftStartDate, rightStartDate]);

    const isPlannableWithinPeriod = useMemo(() => {
        if (!period) {
            return false;
        }

        if (shift) {
            return true;
        }

        if (leftStartDate > parseISO(period.endAt) || rightStartDate < parseISO(period.startAt)) {
            return false;
        }

        return true;
    }, [period, shifts, leftStartDate])

    return {
        minStartDate,
        leftStartDate,
        rightStartDate,
        isPlannableWithinPeriod,
    }
}

export default function PlannerRoundsItem({index, shifts, shift, period, areaActivityType }: PlannerRoundsItemProps) {
    const navigate = useNavigate();

    const {
        popupShownId,
        setPopupShownId,
    } = usePlannerContext();

    const numberOfShifts = areaActivityType.activityType.numberOfShifts;
    const numberOfRounds = areaActivityType.activityType.numberOfRounds;

    const shiftIsDone = numberOfShifts >= numberOfRounds && !shift;

    const prevShift = useMemo(() => {
        if (isEmpty(shifts)) {
            return null;
        }

        // note: this works if they shifts are ordered in order of their rounds - we make sure they are.
        const myIndex = shift?.id ? (shifts ?? [])!.findIndex(v => v.id === shift.id): index;

        return shifts[myIndex - 1] ?? null;
    }, [shifts, shift, period?.id]);

    const {
        minStartDate,
        leftStartDate,
        isPlannableWithinPeriod,
    } = useMinMaxStartDate({shifts, period, shift, areaActivityType});

    function onClick() {
        if (!isPlannableWithinPeriod) {
            return;
        }

        const shiftId = shift?.id ?? 'add';

        navigate({
            pathname: __r(PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY, {
                areaActivityTypeId: areaActivityType.id,
                shiftId: shiftId
            }),
        }, {
            state: {
                backPath: window.location.pathname + window.location.search,
            }
        });
    }

    const borderClasses = useMemo(() => {
        if (areaActivityType.activityType.unplannedShiftCount === 0) {
            return '';
        }

        return classNames(
            areaActivityType.activityType.legRoom <= 10 ? "!border-4 !border-yellow-300" : "",
            areaActivityType.activityType.legRoom <= 5 ? "!border-4 !border-orange-300" : "",
            areaActivityType.activityType.legRoom <= 0 ? "!border-4 !border-red-600" : ""
        )
    }, [areaActivityType.activityType.legRoom]);

    const classes = useMemo(() => classNames(
        shift ? 'hover:inner-shadow-md hover:bg-blue-50 cursor-pointer': '',
        !shift && numberOfShifts >= numberOfRounds ? 'bg-gray-100 cursor-not-allowed shrink hover:bg-gray-100': '',
        isPlannableWithinPeriod ?
            'hover:inner-shadow-md grow hover:bg-blue-50 cursor-pointer': 'bg-gray-100 cursor-not-allowed shrink',
            'flex-1 plannable-item flex relative overflow-visible px-2 pb-1 items-center min-h-[5rem] items-stretch',
        borderClasses,
    ), [shift, isPlannableWithinPeriod, borderClasses]);

    return <Pane key={index} className={classes}>
        <Pane onClick={onClick} className="grow">
            {!shift ?
                <Pane className="pt-2 flex shrink items-center gap-1">
                    {shiftIsDone ?
                        <Text className="!text-xs">Alle shifts zijn gepland.</Text>
                        :
                        <>
                            {!isPlannableWithinPeriod && <Text className="!text-xs">De volgende shift kan pas gepland worden op: </Text>}
                            {minStartDate && <Text className="!text-xs">
                              <DateFormat className="!text-xs capitalize !font-bold" date={minStartDate} formatStr="eeeeee d MMM" />
                            </Text>}
                        </>
                    }

                </Pane>
                :
                <Pane className="flex grow relative h-full py-1 gap-4 overflow-hidden">
                    <Pane className="py-1 flex flex-col justify-between items-center h-full hover:underline !text-xs">
                        <Pane>
                            { createElement(ShiftStatusIconMap[shift.status], { color: shift.isPublished ? 'success': 'muted' }) }
                        </Pane>
                        <Pane>
                            { createElement(TimeslotIconMap[shift.timeslot.icon], { size: 24 }) }
                        </Pane>
                    </Pane>

                    <Text className="shrink !font-bold !text-xs capitalize">
                        <DateValue
                            formatStr="eeeeee d MMM"
                            date={shift.date}
                        />
                    </Text>

                    <Pane className="flex flex-wrap gap-1">
                        { shift.workers.map((worker) => (
                            <WorkerItemWithPopover
                                //@ts-ignore
                                color={shift.timeslot?.color}
                                key={'workeritem' + worker.id}
                                worker={worker}
                                setPopupShownId={setPopupShownId}
                                popupShownId={popupShownId}
                            />
                        ))}
                    </Pane>
                </Pane>
            }
        </Pane>
        <Pane className="shrink py-1">
            <ProgressInfo
                shift={shift}
                isPlannableWithinPeriod={isPlannableWithinPeriod}
                areaActivityType={areaActivityType}
            />
        </Pane>
    </Pane>
}


const ProgressInfo = memo(function ProgressInfo({ shift, areaActivityType, isPlannableWithinPeriod }: { isPlannableWithinPeriod: boolean, areaActivityType: AreaActivityType, shift: ShiftAugmented | null }) {
    const { colors } = useTheme();
    const [isShown, setIsShown] = useState<boolean>(false);

    function onClick(e) {
        setIsShown(!isShown)
    }

    function onCloseComplete() {
        setIsShown(false);
    }

    const numberOfShifts = areaActivityType.activityType.numberOfShifts;
    const numberOfRounds = areaActivityType.activityType.numberOfRounds;

    return <Pane>
        <Popover
            bringFocusInside
            isShown={isShown}
            position={Position.TOP}
            shouldCloseOnExternalClick={true}
            onClose={onCloseComplete}
            shouldCloseOnEscapePress={true}
            content={
                <Pane className="px-4" width={240} minHeight={200} display="flex" justifyContent="center" flexDirection="column">
                    <table className="data-table">
                        <thead>
                        <tr>
                            <th>Topic</th>
                            <th>Waarde</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>urgentie</td>
                            <td>{roundNumber(areaActivityType.activityType.urgency)}</td>
                        </tr>
                        <tr>
                            <td>totaal te plannen</td>
                            <td>{areaActivityType.activityType.numberOfRounds}</td>
                        </tr>
                        <tr>
                            <td>gepland</td>
                            <td>{areaActivityType.activityType.numberOfShifts}</td>
                        </tr>
                        <tr>
                            <td>ongepland</td>
                            <td>{areaActivityType.activityType.unplannedShiftCount}</td>
                        </tr>
                        <tr>
                            <td>dagen ertussen</td>
                            <td>{areaActivityType.activityType.minDaysBetweenRounds}</td>
                        </tr>
                        <tr>
                            <td>dagen speling</td>
                            <td>{areaActivityType.activityType.legRoom}</td>
                        </tr>
                        <tr>
                            <td>start</td>
                            <td>
                                <DateValue date={areaActivityType.activityType.startAt} formatStr="d MMM" />
                            </td>
                        </tr>
                        <tr>
                            <td>einde</td>
                            <td>
                                <DateValue date={areaActivityType.activityType.endAt} formatStr="d MMM" />
                            </td>
                        </tr>
                        <tr>
                            <td>dagen tot einde</td>
                            <td>{areaActivityType.activityType.daysUntilDeadline}</td>
                        </tr>
                        </tbody>
                    </table>
                </Pane>
            }>
            <a>
                <Pane onClick={onClick} className="flex justify-center py-2 px-2 border">
                    <InfoSignIcon color={colors.gray400} />
                </Pane>
            </a>
        </Popover>

        {shift &&
          <Pane>
            <Badge color={((shift?.workers?.length ?? 0) >= areaActivityType.nWorkers) ? "green" : "orange"}
                   className="shadow-md !lowercase">
              <Pane className="flex items-center gap-1">
                <PersonIcon size={10}/>
                <Pane className="flex gap-1">
                  <span>{(shift?.workers?.length ?? 0)}</span>
                  <span>/</span>
                  <span>{areaActivityType.nWorkers}</span>
                </Pane>
              </Pane>
            </Badge>
          </Pane>
        }
        <Pane>
            <Badge color={numberOfShifts >= numberOfRounds ? "green" : "orange"} className="min-w-[3rem] shadow-md !lowercase">
                <Pane className="flex items-center gap-1">
                    <PercentageIcon size={10} />
                    <Pane className="flex gap-1">
                        <span>{numberOfShifts}</span>
                        <span>/</span>
                        <span>{numberOfRounds}</span>
                    </Pane>
                </Pane>

            </Badge>
        </Pane>
    </Pane>
});

