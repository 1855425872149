import { IconButton, Text, Pagination, Pane, RefreshIcon, Spinner, Table, TableProps } from "evergreen-ui";
import Block from "src/components/common/Block";
import { isEmpty } from 'lodash';
import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams, createSearchParams } from "react-router-dom";
import HeaderCell from "./HeaderCell";
import { pushQueryState } from "../../lib/functions";

interface StandardTableProps extends TableProps {
    children: ReactNode;
    data: any;
    emptyState: ReactNode;
    isLoading: boolean | null;
    meta?: any,
    refresh?: Function | undefined,
    smallLoader?: boolean | undefined;
}

const usePagination = function({meta, refresh}) {
    const queryParams = new URLSearchParams(window.location.search)
    // const [searchParams] = useSearchParams();

    const page = queryParams.get('page') || meta?.page;
    const limit = queryParams.get('limit') || meta?.limit;

    const currentPage = page ? parseInt(page): 1;
    const currentLimit = limit ? parseInt(limit): 20;

    const totalPages = meta ? Math.ceil(meta.count / currentLimit): 0;

    const onPageChange = (pageNumber) => {
        const queryParams = new URLSearchParams(window.location.search)

        queryParams.set('page', `${pageNumber}`);
        queryParams.set('limit', `${currentLimit!}`);

        pushQueryState(queryParams);
        refresh()
    }

    const goToNextPage = () => {
        const queryParams = new URLSearchParams(window.location.search)

        queryParams.set('page', `${currentPage! + 1}`);
        queryParams.set('limit', `${currentLimit!}`);

        pushQueryState(queryParams);
        refresh()
    }

    const goToPreviousPage = () => {
        const queryParams = new URLSearchParams(window.location.search)

        queryParams.set('page', `${currentPage > 0 ? currentPage! - 1: 0}`);
        queryParams.set('limit', `${currentLimit!}`);

        pushQueryState(queryParams);
        refresh()
    }

    return {
        currentPage,
        goToNextPage,
        goToPreviousPage,
        meta,
        totalPages,
        onPageChange,
    }
}

const StandardTable = function({ children, data, isLoading, smallLoader = undefined, emptyState, meta = undefined, refresh = undefined, ...rest}: StandardTableProps) {
    const { onPageChange, totalPages, goToPreviousPage, goToNextPage, currentPage } = usePagination({ meta, refresh });

    return <Pane className="standard-table">
        <Pane className={'flex min-h-[48px] py-2 gap-2 justify-end'}>
            { smallLoader && isLoading && <Spinner size={16} /> }

            {refresh &&
              <IconButton
                appearance="minimal"
                icon={RefreshIcon}
                onClick={() => refresh()}
                type="button"
              />
            }

            {meta &&
              <Pagination
                onNextPage={goToNextPage!}
                onPreviousPage={goToPreviousPage}
                page={currentPage!}
                onPageChange={onPageChange}
                totalPages={totalPages} />
            }
        </Pane>

        <Table {...rest} width="100%">
            { children }

            {!smallLoader && isLoading &&
              <TableLoadingSpinner />
            }

            {isLoading === false && isEmpty(data) && emptyState }
        </Table>

    </Pane>
}

StandardTable.HeaderCell = HeaderCell;

export default StandardTable;

type TableLoadingSpinnerProps = {
    title?: string;
}

export function TableLoadingSpinner({ title = undefined }: TableLoadingSpinnerProps) {
    return (
        <Block className="min-h-[10rem] items-center flex-col gap-2" fullWidth center>
            <Spinner/>
            { title && <Text>{title}</Text>}
        </Block>
    )
}

