import { ReactNode, useEffect, useRef } from "react";
import { useApi } from "../context/AxiosContext";
import { BootstrapContext } from "../context/BootstrapContext";
import { useAuth } from "../context/AuthContext";
import { useApp } from "../context/AppContext";

export type AxiosProviderProps = {
  children?: ReactNode;
}

export function BootstrapProvider({ children }: AxiosProviderProps) {
  const { apiInstance } = useApi();
  const { user, setUser, jwt } = useAuth();
  const { setInitialized, initialized } = useApp();

  function ping() {
    return apiInstance!.auth.ping();
  }

  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (jwt && !user) {
      const bootstrap = async () => {
        try {
          const {user} = await apiInstance!.auth.profile();
          await ping();

          // every half hour - do a ping.
          if (!intervalRef.current) {
            intervalRef.current = setInterval(ping, 1800000);
          }

          setUser!(user);
        } finally {
          setInitialized(true);
        }
      }

      bootstrap();
    } else if (!localStorage.getItem('@grofalex-portal:jwt')) {
      setInitialized(true);
    }
  }, [initialized, user, jwt])

  return <BootstrapContext.Provider value={{}}>
    {children}
  </BootstrapContext.Provider>
}