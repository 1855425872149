import { Pane, Tab, TabNavigation } from "evergreen-ui";
import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { difference, isEmpty, last } from 'lodash';
import { classNames } from "../../lib/functions";

export default function TopSubNav({ menuItems, ...rest }) {
    const location = useLocation();

    const isSelected = useCallback((tabItem) => {
        const subLocsTab = tabItem.pathname.split('/');
        const subLocsHere = location.pathname.split('/')

        const diff = difference(subLocsHere, subLocsTab);

        return last(subLocsTab) === subLocsHere[subLocsTab.length - 1] || isEmpty(diff);
    }, [location.pathname])

    return (
        <Pane {...rest} background="tint2" className="py-5 shadow">
            <TabNavigation className="sub-nav ml-3 flex gap-3">
                {menuItems.map((tab, index) => (
                    <Link key={tab.pathname} to={tab.pathname}>
                        <Pane key={tab.pathname} className={classNames(isSelected(tab) ? "inner-shadow-lg !font-bold bg-white": "", "ml-2 flex items-center py-3 px-5 rounded-md !text-gray-600")} id={tab.label}>
                            { tab.icon &&
                              <span className="mr-2">
                                  { tab?.icon }
                              </span>
                            }
                            <span className="!text-md">{tab.label}</span>
                        </Pane>
                    </Link>
                ))}
            </TabNavigation>
        </Pane>
    )
}
