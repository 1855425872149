import { Pane } from "evergreen-ui";
import ProjectFilter from "../../../components/shared/filters/ProjectFilter";
import DateRangeFilter from "../../reports/_components/DateRangeFilter";
import { parseISO } from "date-fns";

export default function BookkeepingFilters({ filterLogic }) {
    const {
        handleInput,
        filterMap,
    } = filterLogic;

    return <Pane className="flex gap-2 flex-wrap lg:flex-nowrap">
        <ProjectFilter filterMap={filterMap} handleInput={handleInput} />

        {filterMap.fromApprovedAtDate?.value && filterMap.toApprovedAtDate?.value &&
          <DateRangeFilter
            filterMap={filterMap}
            startAt={filterMap.fromApprovedAtDate?.value ? parseISO(filterMap.fromApprovedAtDate.value) : null}
            endAt={filterMap.toApprovedAtDate?.value ? parseISO(filterMap.toApprovedAtDate.value) : null}
            fromDateFilter={filterMap.fromApprovedAtDate}
            toDateFilter={filterMap.toApprovedAtDate}
            handleInput={handleInput}
            fromLabel={"Goedgekeurd op datum"}
            toLabel={"Goedgekeurd t/m datum"}
          />
        }

        <DateRangeFilter
            filterMap={filterMap}
            startAt={filterMap.fromDate?.value ? parseISO(filterMap.fromDate.value) : null}
            endAt={filterMap.toDate?.value ? parseISO(filterMap.toDate.value) : null}
            fromDateFilter={filterMap.fromDate}
            toDateFilter={filterMap.toDate}
            handleInput={handleInput}
            fromLabel={"Gelopen op datum"}
            toLabel={"Gelopen t/m datum"}
        />
    </Pane>
}
