import { useParams } from "react-router-dom";
import { useApi } from "../context/AxiosContext";
import { useMemo, useState } from "react";
import { Period } from "../types/apiTypes";
import { isEmpty } from "lodash";
import { useLoadResource } from "../lib/request-hooks";
import { eachDayOfInterval, parseISO } from "date-fns";

export default function usePeriod() {
    const { periodId } = useParams();
    const { apiInstance } = useApi();

    const [ period, setPeriod] = useState<Period | null>(null);
    const [ dailyInterval, setDailyInterval] = useState<Date[]>([]);

    const shouldFetch = useMemo(() => {
        return !isEmpty(periodId)
    }, [periodId, period]);

    const find = () => apiInstance!.adminPeriods.findOne(periodId!);

    function setData(period) {
        setPeriod(period);

        setDailyInterval(eachDayOfInterval({
            start: parseISO(period.startAt),
            end: parseISO(period.endAt),
        }))
    }

    return {
        ...useLoadResource(find, setData, shouldFetch),
        period,
        dailyInterval,
    };
}
