import { IssueIcon, Pane, PaperclipIcon, SettingsIcon, UserIcon } from "evergreen-ui";
import { Outlet, useParams } from "react-router-dom";
import { __r, WORKER_SHIFTS_CHANGES_PAGE, WORKER_SHIFTS_DETAIL_PAGE, WORKER_SHIFTS_REPORT_PAGE } from "../../RouteMap";
import TopSubNav from "../common/TopSubNav";
import { BsBinoculars } from "react-icons/bs";

export default function WorkerShiftDetailsLayout() {
  const { shiftId } = useParams();

  const menuItems = [
    {
      label: 'Details',
      pathname: __r(WORKER_SHIFTS_DETAIL_PAGE, { shiftId }),
      icon: <BsBinoculars />
    },
    {
      label: 'Rapport',
      pathname:  __r(WORKER_SHIFTS_REPORT_PAGE, { shiftId }),
      icon: <PaperclipIcon />
    },
    {
      label: 'Wijzigen doorgeven',
      pathname: __r(WORKER_SHIFTS_CHANGES_PAGE, { shiftId }),
      icon: <IssueIcon color="muted" />
    },
  ];

  return (
    <Pane className="h-full">
      <TopSubNav menuItems={menuItems} />

      <Pane className="h-full">
        <Outlet />
      </Pane>
    </Pane>
  );
}