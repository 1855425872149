import { Alert, Button, FormField, Heading, majorScale, Pane, TextInputField, } from "evergreen-ui";
import Block from "src/components/common/Block";
import { useContext, useState } from "react";
import { useDoRequest } from "../../lib/request-hooks";
import { useApi } from "../../context/AxiosContext";
import { useAuth } from "../../context/AuthContext";
import { Address } from "../../types/apiTypes";
import { OnBoardingContext } from "../../components/layouts/OnBoardingLayout";

function useOnBoardingForm(formContext) {
    const { apiInstance } = useApi();

    const {
        handle,
        setIsLoading: setIsSubmitting,
        isLoading: isSubmitting,
        validationErrors,
        setValidationErrors,
        errorMessage,
    } = useDoRequest();

    const [ firstName, setFirstName] = useState<string>("");
    const [ lastName, setLastName] = useState<string>("");
    const [ password, setPassword ] = useState<string | null>(null);
    const [ address, setAddress] = useState<Address | null>(null);

    const doSubmit = async () => {
        const data = {
            email: formContext?.invite.email,
            token: formContext.token,
            firstName,
            lastName,
            password,
        }

        const request = apiInstance!.users.register(data);

        return await handle(request);
    }

    return {
        address,
        setAddress,
        firstName,
        setFirstName,
        setLastName,
        lastName,
        password,
        setPassword,
        doSubmit,
        submitContext: {
            doSubmit,
            setIsSubmitting,
            isSubmitting,
            errorMessage,
            validationErrors,
        },
    }
}


export default function UserCreationForm({ onComplete }) {
    const {
        state,
    } = useContext(OnBoardingContext);

    const { setJwt, setUser } = useAuth();

    const {
        address,
        firstName,
        setFirstName,
        setLastName,
        lastName,
        setPassword,
        doSubmit,
        submitContext,
    } = useOnBoardingForm(state);

    if (!state?.invite) {
        return <></>
    }

    const submit = async () => {
        const { jwt, user } = await doSubmit();

        setJwt!(jwt);
        setUser!(user)

        onComplete({ user, jwt, address });
    }

    return <Pane className={'flex flex-col gap-5 justify-center align-items'}>
        <Heading>Login details</Heading>

        <Block className="flex flex-col">
            <FormField>
                <TextInputField
                    disabled
                    inputHeight={40}
                    name="email"
                    label=""
                    placeholder="Email"
                    value={state?.invite.email}
                />
            </FormField>

            <TextInputField
                inputHeight={40}
                label="Password"
                name="password"
                placeholder="Password"
                type="password"
                isInvalid={!!submitContext.validationErrors?.password}
                validationMessage={submitContext.validationErrors?.password?.join(', ')}
                onChange={(e) => setPassword(e.target.value)}
            />

            <TextInputField
                inputHeight={40}
                label="First name"
                name="firstName"
                placeholder=""
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                isInvalid={!!submitContext.validationErrors?.firstName}
                validationMessage={submitContext.validationErrors?.firstName?.join(', ')}
            />

            <TextInputField
                inputHeight={40}
                label="Last name"
                name="lastName"
                placeholder=""
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                isInvalid={!!submitContext.validationErrors?.lastName}
                validationMessage={submitContext.validationErrors?.lastName?.join(', ')}
            />
        </Block>

        {submitContext.errorMessage &&
          <Alert
            intent="danger"
            title={submitContext.errorMessage}
            marginBottom={32}
          />
        }

        <Block className={"flex justify-end"}>
            <Button
                intent="success"
                appearance="primary"
                onClick={submit}
                isLoading={!!submitContext.isSubmitting}
                height={majorScale(5)}>
                Register!</Button>
        </Block>
    </Pane>
}