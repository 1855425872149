import { Avatar, Card, Heading, Pane } from "evergreen-ui";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import DateDiffValue from "../../common/DateDiffValue";
import { ReportStatusIconMap } from "./components/ReportFieldsRow";
import { DisplayReportAddress } from "./components/DisplayReportAddress";
import { ShiftReport } from "../../../types/apiTypes";
import DurationValue from "../../common/DurationValue";
import TravelDistanceValue from "../../common/TravelDistanceValue";

type ShiftReportSummaryProps = {
    children?: ReactNode,
    shiftReport: ShiftReport,
    showWorker?: boolean,
    showProject?: boolean,
}

export function ShiftReportSummary({children, shiftReport, ...rest}: ShiftReportSummaryProps) {
    const {
        showWorker = true,
        showProject = false,
    } = rest;

    const { t } = useTranslation();
    const { workerShift } = shiftReport;

    if (!workerShift) {
        return <></>;
    }

    return <Card elevation={1} padding={16} marginBottom={16}>
        <Pane className="flex pt-2 gap-1">
            <Pane className='flex grow flex-col gap-2 w-full'>
                <Pane>
                    {showProject &&
                      <Pane className="flex py-2 gap-1">
                        <Heading size={500}>Project:</Heading>
                        <Heading className="!font-normal" size={500}>
                            {shiftReport.project.name}
                        </Heading>
                      </Pane>
                    }
                    <Pane className="flex py-2 gap-1">
                        <Heading size={500}>Gebied:</Heading>
                        <Heading className="!font-normal" size={500}>
                            { workerShift.areaActivityType.area.name }
                        </Heading>
                    </Pane>

                    <Pane className="flex py-2 gap-1">
                        <Heading size={500}>Activiteit:</Heading>
                        <Heading className="!font-normal" size={500}>{workerShift.areaActivityType.activityType.name}</Heading>
                    </Pane>
                </Pane>
            </Pane>

            <Pane className='flex shrink flex-col gap-2 w-full'>
                <Pane className="flex gap-1 justify-end">
                    <Heading size={300}>Status:</Heading>
                    <Heading size={200}>{t('common.' + shiftReport.status)}</Heading>
                    {ReportStatusIconMap[shiftReport.status]}
                </Pane>

                <Pane className="flex gap-1 justify-end">
                    <Heading size={300}>Diensttijd:</Heading>
                    <Heading className="!font-normal" size={200}>
                        <DateDiffValue
                            leftDate={shiftReport.workerShift.startedAt}
                            rightDate={shiftReport.workerShift.endedAt}
                        />
                    </Heading>
                </Pane>

                <Pane className="flex gap-1 justify-end">
                    <Heading size={300}>Reistijd:</Heading>
                    <Heading className="!font-normal" size={200}>
                        <DurationValue
                            seconds={shiftReport.workerShift.travelDuration}
                        />
                    </Heading>
                </Pane>

                <Pane className="flex gap-1 justify-end">
                    <Heading size={300}>Rijdt zelf:</Heading>
                    <Heading className="!font-normal" size={200}>
                        {shiftReport.workerShift.isDriving ? 'Ja' : 'Nee'}
                    </Heading>
                </Pane>

                {shiftReport.workerShift.isDriving &&
                  <Pane className="flex gap-1 justify-end">
                    <Heading size={300}>Afstand:</Heading>
                    <Heading className="!font-normal" size={200}>
                        <TravelDistanceValue meters={shiftReport.workerShift.distanceToArea} />
                    </Heading>
                  </Pane>
                }

                {showWorker &&
                  <Pane className="self-end items-center">
                    <Pane className="flex gap-1 pb-2">
                      <Heading size={300}>Veldwerker:</Heading>

                      <Heading size={200}>
                          {shiftReport.worker.user.firstName} {shiftReport.worker.user.lastName}
                      </Heading>
                    </Pane>

                    <Pane className="flex justify-end self-end">
                      <Avatar
                        className="hover:opacity-90 ml-auto"
                        src={shiftReport.worker.user!.profilePictureThumbnail ?? ""}
                        size={80}
                      />
                    </Pane>
                  </Pane>
                }
            </Pane>
        </Pane>

        <Pane className="py-4">
            <Heading size={500}>Adres:</Heading>

            <DisplayReportAddress
                minHeightMap={250}
                address={workerShift.address} />
        </Pane>

        { children && children }
    </Card>
}
