import { Dialog } from 'evergreen-ui';
import { useTranslation } from "react-i18next";

export default function PendingChangesDialog({ doAction, ...rest }) {
    const { t } = useTranslation();

    return (
        <Dialog
            title={rest?.title ?? t('dialog_pending_changes.title')}
            intent="danger"
            confirmLabel={rest?.confirmLabel ?? t('dialog_pending_changes.confirm')}
            cancelLabel={rest?.cancelLabel ?? t('dialog_pending_changes.cancel')}
            onConfirm={doAction}
            {...rest}
        >
            { rest?.text ?? t('dialog_pending_changes.text')}
        </Dialog>
    )
}