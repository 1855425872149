import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { PostData } from "../types/appTypes";

export default class WorkerApi extends BaseApi {
  findMe(): Promise<WorkerType> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/worker/me`
    });
  }

  updateMe(data: PostData): Promise<WorkerApi> {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/worker/me`,
      data: data,
    });
  }
}