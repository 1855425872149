import { Pane } from "evergreen-ui";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { WORKER_PERIODS } from "../../RouteMap";

export default function UnAuthLayout() {
  const auth = useAuth();
  const location = useLocation();

  if (auth.user) {
    if (auth.user.role === 'worker') {
      return <Navigate to={WORKER_PERIODS} state={{from: location}} replace />;
    }

    if (auth.user.role === 'admin') {
      return <Navigate to="/projects" state={{from: location}} replace />;
    }
  }

  return (
    <Pane display="flex">
      <Outlet />
    </Pane>
  );
}