export const projectTypeOptions = [
    {
        value: 'private',
        label: 'Particulier', // or private?
        default: true,
    },
    {
        value: 'organisation',
        label: 'Organisatie',
    },
]
