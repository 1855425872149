import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { ApiAxiosResponse } from "../types/apiTypes";

export default class Media extends BaseApi {
  uploadToShiftReportTmp(formData: FormData): Promise<Media> {
    return doRequest(this._axiosInstance!, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      method: 'post',
      url: `/media/shift-report/upload-tmp`
    });
  }

  uploadToAreaTmp(formData: FormData): Promise<Media> {
    return doRequest(this._axiosInstance!, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      method: 'post',
      url: `/media/areas/upload-tmp`
    });
  }
}