import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    Dialog,
    DialogProps,
    FormField,
    Heading,
    majorScale,
    Pane,
    Switch,
    TickIcon
} from "evergreen-ui";

import { Carpool, WorkerApiType } from "src/types/apiTypes";
import { usePlannerDialog } from "../../../pages/planner/_components/logic/PlannerDialogContext";
import { useNavigate, useParams } from "react-router-dom";
import { __r, PLANNER_PERIOD_PLANNING_PAGE, PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY } from "../../../RouteMap";

interface ManageCarPoolingDialogProps extends DialogProps {
    title: string;
    worker: WorkerApiType;
}

export default function ManageCarpoolingDialog({ title, worker, ...rest }: ManageCarPoolingDialogProps) {
    const { t } = useTranslation();

    const {
        state,
        dispatch,
        helpers,
    } = usePlannerDialog();

    const {
        attachedWorkers,
        carpoolsAvailable,
        attachedWorkers: workers,
    } = state;

    const carpool: Carpool | null | undefined = useMemo(() => {
        return worker.isDriving ? carpoolsAvailable.find(v => v.driver.id === worker.id) : null;
    }, [worker, workers, carpoolsAvailable])

    const drivers = useMemo(() => {
        // filter out the ones without a carpool yet, but will be created ones saved.
        return workers.filter(w => w.isDriving && !carpoolsAvailable.find(v => v.driver.id === w.id))
    }, [workers, carpoolsAvailable]);

    function addOrRemoveWorkerToCarpool(worker: WorkerApiType, driverId: number | null, carpoolId?: number | null) {
        if (worker.driverId === driverId) {
            helpers!.addWorkerToCarpool(worker, null, null);
        } else {
            helpers!.addWorkerToCarpool(worker, driverId, carpoolId);
        }
    }

    return <Dialog title={title} {...rest}>
        <FormField label={t('carpooling_dialog.is_driving')}>
            <Switch checked={worker.isDriving} onChange={(e) => helpers!.toggleIsDriving(worker)} />

            {worker.isDriving &&
              <Pane className="py-4">
                <OneCarpool
                  worker={worker}
                  carpool={carpool}
                />
              </Pane>
            }

            {!worker.isDriving &&
              <Pane className="my-2">
                  {drivers?.length !== 0 &&
                    <Heading size={400}>Rijdt mee met:</Heading>
                  }

                <Pane className="pt-2 flex-col flex grow gap-2">
                    {/*These are drivers in the current shift - no carpool is created for them yet. */}
                    {drivers.map(driver => (
                        <Pane
                            className="flex gap-2 cursor-pointer items-center pl-2 bg-gray-50 hover:bg-grofalex-forest-light btn-contrast hover:!text-white !text-left border !text-gray-700"
                            onClick={() => addOrRemoveWorkerToCarpool(worker, driver.id)}
                            key={driver.id}
                        >
                            <Checkbox checked={worker.driverId === driver.id ?? false}/>

                            <span>
                                <span className="!font-bold !text-xs overflow-ellipsis capitalize pr-1">
                                    {`${driver.user?.firstName}`}
                                </span>
                            <span className="!font-bold !text-xs overflow-ellipsis capitalize">
                                {`${driver.user?.lastName}`}
                            </span>
                        </span>
                        </Pane>
                    ))}

                  <CarpoolsAvailable
                    carpools={carpoolsAvailable}
                    addWorkerToCarpool={addOrRemoveWorkerToCarpool}
                    worker={worker}
                  />

                </Pane>
              </Pane>
            }
        </FormField>
    </Dialog>
}

function CarpoolsAvailable({ carpools, addWorkerToCarpool, worker }) {
    return <>
        {carpools.map(carpool => (
            <Pane
                className="flex gap-2 cursor-pointer items-center pl-2 bg-gray-50 hover:bg-grofalex-forest-light btn-contrast hover:!text-white !text-left border !text-gray-700"
                onClick={() => addWorkerToCarpool(worker, carpool.driver.id, carpool.id)}
                key={carpool.driver.id}>

                <Checkbox onClick={e => addWorkerToCarpool(worker, carpool.driver.id, carpool.id)} checked={worker.driverId === carpool.driver.id ?? false}/>

                <span>
                    <span className="!font-bold !text-xs overflow-ellipsis capitalize pr-1">
                        {`${carpool.driver.user?.firstName}`}
                    </span>
                    <span className="!font-bold !text-xs overflow-ellipsis capitalize">
                        {`${carpool.driver.user?.lastName}`}
                    </span>
                </span>
            </Pane>
        ))}
    </>
}


function OneCarpool({ carpool, worker }) {
    const { shiftId, periodId } = useParams()
    const navigate = useNavigate();

    const passengers = useMemo(() => {
        if (!carpool?.workers) {
            return [];
        }

        return carpool.workers.filter((carpoolWorker) => {
            return carpoolWorker.id !== worker.id;
        });

    }, [carpool]);

    function onClick(worker: WorkerApiType & { shiftId: number, areaActivityTypeId: number }) {
        const planning = __r(PLANNER_PERIOD_PLANNING_PAGE, {
            periodId: periodId,
        });

        const overlay = __r(PLANNER_PERIOD_PLANNING_SHIFT_DETAIL_OVERLAY, {
            areaActivityTypeId: worker.areaActivityTypeId,
            shiftId: worker.shiftId,
        })

        const pathname = [planning, overlay].join('/')

        // full page load; will in prove in the future. but you will need to route to multiple pages
        // but first close the overlay.
        window.location.href = pathname;
    }

    return <>
        { passengers.map((passenger) => (
            passenger.id !== worker.id &&
            <Pane className="flex w-full gap-1 items-center ml-2 py-2">
              <TickIcon color="success" size={18} />

              <Pane className="flex w-full">
                <Pane className="grow">
                  <span className="!font-bold !text-xs overflow-ellipsis capitalize pr-1">
                        {`${passenger.user?.firstName}`}
                    </span>
                  <span className="!font-bold !text-xs overflow-ellipsis capitalize">
                        {`${passenger.user?.lastName}`}
                    </span>
                </Pane>

                <Pane className="shrink">
                  {passenger.shiftId !== parseInt(shiftId!) &&
                    <Button
                      onClick={() => onClick(passenger)}
                      height={majorScale(4)}>
                      Naar dienst
                    </Button>
                  }
                </Pane>
              </Pane>
            </Pane>
        ))}
    </>
}