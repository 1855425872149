import {
    Alert,
    Avatar,
    Button,
    CalendarIcon,
    FormField, HistoryIcon,
    LogInIcon,
    majorScale,
    Pane,
    Select,
    Spinner,
    TextInputField,
    toaster
} from "evergreen-ui";
import { User, WorkerApiType } from "../../types/apiTypes";
import { useState } from "react";
import { useDoRequest, useLoadResource } from "../../lib/request-hooks";
import { useApi } from "../../context/AxiosContext";
import { useNavigate, useParams } from "react-router-dom";
import Block from "../../components/common/Block";
import Form from "../../components/common/Form";
import Actions from "../../components/common/Actions";
import { useAuth } from "../../context/AuthContext";
import { roleOptions } from "../../components/config/roles";
import PageTitle from "../../components/common/PageTitle";
import WorkerDetailsForm, { useWorkerDetailsForm } from "../../components/shared/WorkerDetailsForm";
import Divider from "../../components/common/Divider";
import { __r, ADMIN_USERS_PAGE, ADMIN_WORKER_PERIODS_PAGE, ADMIN_WORKER_SHIFT_HISTORY_PAGE } from "../../RouteMap";
import Page from "src/components/common/Page";
import { useLoginAs } from "./UsersPage";

const useUserFormFields = function () {
    const { userId } = useParams();
    const { apiInstance } = useApi();

    const [ firstName, setFirstName] = useState<string>("");
    const [ lastName, setLastName] = useState<string>("");
    const [ email, setEmail] = useState<string>("");
    const [ role, setRole] = useState<string>("");
    const [ newPassword, setNewPassword ] = useState<string | null>(null);
    const [ worker, setWorker] = useState<WorkerApiType | null>(null);
    const [ profilePicture, setProfilePicture ] = useState<string | null>(null);

    const submitContext = useDoRequest();

    const request = () => apiInstance!.adminUsers.findOne(userId!);

    const setData = (user: User) => {
        setFirstName(user.firstName)
        setLastName(user.lastName)
        setEmail(user.email)
        setRole(user.role)
        setWorker(user?.worker ?? null)
        setProfilePicture(user?.profilePicture)
    }

    const doSubmit = async () => {
        const data = {
            firstName,
            lastName,
            role,
            email,
            ...( newPassword && { newPassword }),
        }

        const request = apiInstance!.adminUsers.update(userId!, data);

        await submitContext.handle(request);

        toaster.success('Saved!');
    }

    return {
        ...useLoadResource(request, setData),
        submitContext,
        doSubmit,
        email,
        firstName,
        lastName,
        role,
        setEmail,
        setFirstName,
        setLastName,
        setRole,
        newPassword,
        setNewPassword,
        worker,
        profilePicture,
    };
}

export default function UsersEditPage() {
    const { apiInstance } = useApi();
    const { user, setUser } = useAuth();

    const navigate = useNavigate();
    const { userId } = useParams();

    const {
        isLoading,
        email,
        setEmail,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        submitContext,
        doSubmit,
        role,
        setRole,
        newPassword,
        setNewPassword,
        worker,
        profilePicture,
    } = useUserFormFields();

    const { doLoginAs } = useLoginAs();

    const submit = async () => {
        if (worker) {
            await detailsForm.doSubmit();
        }

        await doSubmit();

        // reload user if they edited their own details
        if (userId && user!.id === parseInt(userId)) {
            const {user} = await apiInstance!.auth.profile();
            setUser!(user);
        }

        navigate(ADMIN_USERS_PAGE)
    }


    const detailsForm = useWorkerDetailsForm(worker, true);

    function navToAvailability() {
        navigate(
            __r(ADMIN_WORKER_PERIODS_PAGE, {workerId: worker!.id}), {
                state: {
                    backPath: window.location.pathname + window.location.search,
                },
            })
    }

    function navToShiftHistory() {
        navigate(
            __r(ADMIN_WORKER_SHIFT_HISTORY_PAGE, {workerId: worker!.id}), {
                state: {
                    backPath: window.location.pathname + window.location.search,
                },
            })
    }

    return <Page>
        <Block className="flex gap-2 items-center w-full">
            <Pane className="grow">
                <PageTitle backPath={ADMIN_USERS_PAGE} marginBottom={20}>{ !userId ? "Maak een nieuwe ": "Wijzig "}
                    Gebruiker
                </PageTitle>

                {isLoading &&
                  <Block className="flex justify-end">
                    <Spinner size={20} />
                  </Block>
                }
            </Pane>

            {worker &&
              <Pane className="flex gap-1 shrink">
                <Button onClick={navToShiftHistory} iconBefore={HistoryIcon}>
                  History
                </Button>

                <Button onClick={() => doLoginAs(userId!)} iconBefore={LogInIcon}>
                  Login als
                </Button>

                  <Button onClick={navToAvailability} iconBefore={CalendarIcon}>
                    Bekijk beschikbaarheid
                  </Button>
              </Pane>
            }
        </Block>

        {isLoading === false &&
          <Form>
            <>
              <Pane className="flex justify-center py-2">
                <Avatar
                  className="hover:opacity-90"
                  src={profilePicture ?? ""}
                  name={""}
                  size={100}
                />
              </Pane>

              <FormField>
                <TextInputField
                  required
                  name="firstName"
                  label="First name"
                  placeholder=""
                  isInvalid={!!submitContext.validationErrors?.firstName}
                  validationMessage={submitContext.validationErrors?.firstName?.join(', ')}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  marginBottom={0}
                  inputHeight={40}
                />
              </FormField>

              <FormField>
                <TextInputField
                  required
                  name="lastName"
                  label="Last name"
                  placeholder=""
                  isInvalid={!!submitContext.validationErrors?.lastName}
                  validationMessage={submitContext.validationErrors?.lastName?.join(', ')}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  marginBottom={0}
                  inputHeight={40}
                />
              </FormField>

              <FormField
                isInvalid={!!submitContext.validationErrors?.role}
                label="Role">
                <Select
                  value={role}
                  onChange={e => setRole(e.target.value)}
                  width={240} height={40}>
                    { roleOptions.map((roleOption) => (
                        <option key={roleOption.value} value={ roleOption.value }>
                            { roleOption.label}
                        </option>
                    )) }
                </Select>
              </FormField>

              <FormField>
                <TextInputField
                  required
                  name="email"
                  label="Email"
                  placeholder=""
                  isInvalid={!!submitContext.validationErrors?.email}
                  validationMessage={submitContext.validationErrors?.email?.join(', ')}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  marginBottom={0}
                  inputHeight={40}
                />
              </FormField>

              <FormField>
                <TextInputField
                  name="newPassword"
                  label="New password"
                  type="password"
                  placeholder=""
                  isInvalid={!!submitContext.validationErrors?.newPassword}
                  validationMessage={submitContext.validationErrors?.newPassword?.join(', ')}
                  value={newPassword ?? ""}
                  onChange={(e) => setNewPassword(e.target.value)}
                  marginBottom={0}
                  inputHeight={40}
                />
              </FormField>

                {worker &&
                  <Pane>
                    <Divider title="Your Details">
                      <WorkerDetailsForm showActionButtons={false} useForm={detailsForm} />
                    </Divider>
                  </Pane>
                }

                {submitContext.errorMessage &&
                  <Pane>
                    <Alert
                      marginTop={10}
                      intent="danger"
                      title={submitContext.errorMessage}
                    />
                  </Pane>
                }

              <Actions marginTop={10} marginBottom={10}>
                <Actions.Button
                  onClick={() => navigate(`/admin/users`)}
                  disabled={!!submitContext.isLoading}
                  height={majorScale(5)}>Cancel
                </Actions.Button>
                <Actions.Button
                  onClick={submit}
                  isLoading={!!submitContext.isLoading}
                  appearance="primary"
                  intent="success"
                  height={majorScale(5)}>Save
                </Actions.Button>
              </Actions>
            </>
          </Form>
        }
    </Page>
}