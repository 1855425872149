import ApiError from "../api/ApiError";
import { useEffect, useRef, useState } from "react";
import { ValidationErrorsType } from "../types/apiTypes";
import { useSearchParams } from "react-router-dom";
import { toaster } from "evergreen-ui";

export const useDoRequest = () => {
    const [isLoading, setIsLoading] = useState<boolean | null>(null);
    const isLoadingRef = useRef<boolean | null>(null);

    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorsType | null>({});
    const [successMessage, setSuccessMessage] = useState<string | null>(null);

    const handle = (promise, setData: Function | null = null) => {
        isLoadingRef.current = false;

        setIsLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);
        setValidationErrors({})

        return promise.then((data) => {
            if (setData) {
                setData(data).finally(() => {
                    setIsLoading(false);
                })
            } else {
                setIsLoading(false);
            }

            return data;
        }).catch((e) => {
            setIsLoading(false);

            if (e instanceof ApiError) {
                if (e.code !== 404) {
                    if (e.messages) {
                        toaster.danger(e.messages[0], {id: 'api-error'})
                    } else {
                        toaster.danger('An error occurred. Try to refresh this page and try again.', {
                            id: 'api-error',
                        })
                    }
                }

                if (e.messages) {
                    setErrorMessage(e.messages[0]);
                }

                if (e.validationErrors) {
                    setValidationErrors(e.validationErrors)

                    toaster.danger('Unable to perform this action - some fields are incorrect or missing', {
                        id: 'api-error',
                    })
                }
            } else {
                toaster.danger('An error occurred. Try to refresh this page and try again.', {
                    id: 'api-error',
                })
            }

            throw e;
        })
    }

    return {
        handle,
        isLoading,
        setIsLoading,
        errorMessage,
        successMessage,
        setErrorMessage,
        validationErrors,
        setSuccessMessage,
        setValidationErrors,
    }
}

export const useLoadResource = function(request, setData, enabled = true) {
    const [searchParams] = useSearchParams();
    const [ meta, setMeta ] = useState({});

    // can be used to trigger a rerender of a child component if you set this as the key of the child component.
    const [ refreshCount, setRefreshCount ] = useState(0);

    const refresh = () => {
        setRefreshCount(refreshCount + 1);
    }

    const { handle, isLoading, setIsLoading, errorMessage, successMessage } = useDoRequest();

    function setDataCb(data, mounted = true) {
        return new Promise(( resolve ) => {
            if (mounted) {
                // todo could be wrong to assume data.data
                setData(data?.data || data);
                setMeta(data?.metadata);
                resolve(data)
            }
        })
    }

    useEffect(() => {
        let mounted = true;

        async function load() {
            const setD = (data) => {
                return new Promise(( resolve ) => {
                    if (mounted) {
                        // todo could be wrong to assume data.data
                        setData(data?.data || data);
                        setMeta(data?.metadata);
                        resolve(data)
                    }
                })
            }

            return await handle(request(), setD);
        }

        if (enabled) {
            load();
        } else {
            setIsLoading(false)
        }

        return () => {
            mounted = false;
        }
    }, [searchParams, refreshCount, enabled])

    return {
        errorMessage,
        isLoading,
        meta,
        refresh,
        refreshCount,
        setRefreshCount,
        successMessage,
        setIsLoading,
        handle,
        setMeta,
        setDataCb,
    }
}
