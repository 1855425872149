import { createContext, MutableRefObject, useContext } from "react";
import { PaneProps } from "evergreen-ui";

interface AppContextType {
  setInitialized: Function;
  initialized: boolean;
  contentRef: MutableRefObject<HTMLDivElement | null>,
  layoutProps: PaneProps,
  setLayoutProps: Function,
  sidebarIsOpen: boolean,
  setSidebarIsOpen: Function,
}

export function useApp() {
  return useContext(AppContext);
}

export const AppContext = createContext({
  layoutProps: {},
} as AppContextType);
