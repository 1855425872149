import { CrossIcon, Heading, IconButton, Pane } from "evergreen-ui";
import { Id, SelectOption } from "src/types/apiTypes";
import SelectInputField from "src/components/formfields/SelectInputField";
import { memo, useEffect, useMemo, useState } from "react";
import { FilterMapType } from "src/types/appTypes";
import useCarpools from "src/hooks/useCarpools";
import { usePlannerDialog } from "src/pages/planner/_components/logic/PlannerDialogContext";

interface CarpoolFilterProps {
    availableOnDate: string | null;
    timeslotUid: string | null;
    periodId: Id;
    filterMap: FilterMapType;
    handleInput: Function;
}

export default memo(function CarpoolFilter({ availableOnDate, timeslotUid, periodId, filterMap, handleInput }: CarpoolFilterProps) {
    const [ carpool, setCarpool ] = useState<SelectOption | null>(null);

    const {
        helpers,
        state,
    } = usePlannerDialog();

    const { carpools } = useCarpools({
        date: availableOnDate,
        timeslotUid: timeslotUid,
        periodId: periodId,
        carpoolId: filterMap.carpoolId.value!,
        setCarpool
    });

    const carpoolOptions = useMemo(function() {
        return carpools.map(v => ({
            value: v.id,
            label: v.driver.user.firstName + ' ' + v.driver.user.lastName,
            driverId: v.driver.id,
        }));
    }, [carpools])

    function setSelected(value) {
        setCarpool(value)

        handleInput(filterMap.carpoolId, value?.value ?? null);
        handleInput(filterMap.workerId, value?.driverId ?? null);
        // afterSelect(value.value)
        helpers!.setSelectedDriverId(value?.driverId ?? null);
        helpers!.setSelectedCarpoolId(value?.value ?? null);

    }

    useEffect(() => {
        helpers!.setCarpoolsAvailable(carpools);
    }, [carpools]);

    useEffect(() => {
        if (availableOnDate === 'ignore' || timeslotUid === 'ignore') {
            setSelected(null);

            helpers!.setSelectedDriverId(null);
            helpers!.setSelectedCarpoolId(null);
        }
    }, [availableOnDate, timeslotUid]);

    const emptySelectText = useMemo(() => {
        if (availableOnDate === 'ignore' || timeslotUid === 'ignore') {
            return 'Selecteer een datum en tijdslot';
        }

        return 'Filter op carpools...';
    }, [availableOnDate, timeslotUid]);

    return <Pane className="flex flex-col gap-1">
        <Pane className="flex justify-between h-4">
            <Heading className={carpool ? "!text-black !font-bold": ""} size={400}>
                { filterMap.carpoolId.label }
            </Heading>

            {carpool &&
              <IconButton
                icon={CrossIcon}
                appearance="minimal"
                height={24}
                onClick={() => setSelected(null)}
              />
            }
        </Pane>
        <SelectInputField
            buttonProps={{
                disabled: availableOnDate === 'ignore' || timeslotUid === 'ignore'
            }}
            closeOnSelect
            selected={carpool}
            setSelected={setSelected}
            title="Select type"
            emptySelectText={emptySelectText}
            options={carpoolOptions}
        />
    </Pane>
});
