import { Heading, Pane } from 'evergreen-ui';
import { classNames } from "../../lib/functions";

export default function Divider(props) {
    const {
        title,
        children,
        small = false,
        ...rest
    } = props

    return (
        <Pane {...rest}>
            <Pane className={'w-full flex gap-2 py-2 items-center'} {...rest}>
                <Pane className={classNames(small ? 'w-[3rem]': 'w-1/2', 'h-[1px] bg-gray-200')} />
                { title && <Heading className={"text-center w-[7rem]"} size={100}>{ title }</Heading> }
                <Pane className={classNames(small ? 'w-[3rem]': 'w-1/2', 'h-[1px] bg-gray-200')} />
            </Pane>
            { children }
        </Pane>
    )
}