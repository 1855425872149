import {
    Button,
    Card,
    CircleIcon,
    EditIcon,
    EyeOpenIcon,
    Heading,
    InfoSignIcon,
    LockIcon,
    Pane, Paragraph,
    PlusIcon,
    Position,
    Text,
    TickCircleIcon,
    Tooltip,
    WarningSignIcon
} from "evergreen-ui";
import { Trans, useTranslation } from "react-i18next";
import { classNames, formatDateDiff } from "../../lib/functions";
import { useMemo } from "react";
import DateValue, { formatDate } from "../common/DateValue";
import { parseISO, subDays } from "date-fns";
import DateDiffValue, { getDiffValue } from "../common/DateDiffValue";

function PeriodStatusIcon({ period }) {
    const { t } = useTranslation();

    if (period.isFilledIn) {
        return <TickCircleIcon color="success"/>
    }

    if (period.isLocked && !period.isFilledIn) {
        return <Tooltip position={Position.RIGHT} content={t('worker_requests_page.missed_deadline_tooltip', { date: formatDate(period.expiresAt) } )}>
          <Pane className="!justify-self-end cursor-pointer">
            <WarningSignIcon color="warning" />
          </Pane>
        </Tooltip>
    }

    if (period.isUrgent) {
        return <Tooltip position={Position.RIGHT} content={t('worker_requests_page.missed_deadline_tooltip', { date: formatDate(period.expiresAt) } )}>
            <Pane className="!justify-self-end cursor-pointer">
                <WarningSignIcon color="warning" />
            </Pane>
        </Tooltip>
    }

    if (!period.isLocked && !period.isFilledIn) {
        return <CircleIcon color="muted" />
    }

    return <></>
}

export default function AvailabilityPeriodCard({ period, navTo }) {
    const { t } = useTranslation();

    const cardClasses = useMemo(() => {
        const map = {
            isFilledIn: period.isFilledIn ? "border-l-2 border-green-500 p-4": "border-l-2 border-gray-300 p-4",
            isLocked: period.isLocked ? "bg-gray-50": "hover:bg-gray-50",
            isProblem: !period.isFilledIn && period.isLocked ? "border border-orange-200": "",
            isUrgent: period.isUrgent ? "border border-l-4 border-orange-500": "",
        }

        return classNames(
            map.isFilledIn,
            map.isLocked,
            map.isProblem,
            map.isUrgent,
            "cursor-pointer");
    }, [period])

    return <Card elevation={1} className={cardClasses} onClick={() => navTo(period)}>
        <Pane className="flex justify-between grow py-2">
            <Pane className="flex gap-2 items-center">
                <PeriodStatusIcon period={period} />

                <Text className="font-bold" size={800}>
                    { period.name }
                </Text>

            </Pane>

            <Pane>
                {period.isLocked ?
                  <Tooltip position={Position.RIGHT} content={t('worker_requests_page.is_locked_tooltip', { date: formatDate(period.expiresAt) } )}>
                    <Pane className="flex justify-self-end gap-1 cursor-pointer">
                      <LockIcon color="muted" />
                    </Pane>
                  </Tooltip>
                    :
                  <Tooltip position={Position.RIGHT} content={t('worker_requests_page.is_open_tooltip', { date: formatDate(period.expiresAt) } )}>
                    <Pane className="flex justify-self-end gap-1 cursor-pointer">
                      <EditIcon color="muted"/>
                    </Pane>
                  </Tooltip>
                }
            </Pane>
        </Pane>

        <Pane className={'flex gap-5 flex-wrap items-center'}>
            <Pane className="flex flex-col">
                <Pane className="flex py-2">
                    <Pane marginLeft="auto">
                        <Tooltip position={Position.RIGHT} content={t('worker_requests_page.you_have_until_tooltip', {
                            date: formatDate(period.expiresAt)
                        })}>
                            <Pane>
                                <Pane className="flex gap-1">
                                    { period.isLocked ?
                                        <Heading size={400}>Invulperiode verstreken.</Heading>
                                        :
                                        <>
                                            <Heading size={400}>Invullen kan tot en met:</Heading>

                                            <Heading className="!font-normal" size={400}>
                                                <DateValue
                                                    formatStr={'eeeeee d MMM'}
                                                    date={subDays(parseISO(period.expiresAt), 1)}
                                                />
                                            </Heading>

                                            <InfoSignIcon color="disabled"/>
                                        </>
                                    }

                                </Pane>

                                {period.isUrgent &&
                                  <Pane>
                                    <Paragraph className="!text-red-700" size={400}>
                                      <Trans
                                        values={{time: getDiffValue(new Date(), period.expiresAt)}}
                                        i18nKey="worker_requests_page.remaining_time_tooltip"
                                      />
                                    </Paragraph>
                                  </Pane>
                                }
                            </Pane>
                        </Tooltip>
                    </Pane>
                </Pane>
            </Pane>

            <Pane className={'min-w-[2rem] mx-2 h-[1px] bg-gray-400'} />
            <Pane className="flex gap-1">
                <Text>
                    <span className="font-bold">{ t('common.period')}:</span>
                </Text>
                <Text>
                    <DateValue formatStr={'eeeeee d MMM'} date={period.startAt} /> - <DateValue formatStr={'eeeeee d MMM'} date={period.endAt}/>
                </Text>
            </Pane>

            <Pane marginLeft="auto">
                {!period.isLocked ?
                    <Button iconBefore={PlusIcon}>
                        {t('worker_requests_page.fill_in_availability')}
                    </Button>
                    :
                    <Button iconBefore={EyeOpenIcon}>
                        {t('worker_requests_page.see_availability')}
                    </Button>
                }
            </Pane>
        </Pane>
    </Card>
}