import Auth from "./_auth";
import { AxiosInstance } from "axios";
import AdminAreas from "./_adminAreas";
import AdminProjects from "./_adminProjects";
import AdminUsers from "./_adminUsers";
import AdminActivityTypes from "./_adminActivityTypes";
import Media from "./_media";
import Users from "./_users";
import Resources from "./_resources";
import AdminInvites from "./_adminInvites";
import Invites from "./_invites";
import WorkerApi from "./_worker";
import AdminPeriods from "./_adminPeriods";
import WorkerAvailabilityRequests from "./_workerAvailabilityRequests";
import WorkerAvailability from "./_workerAvailability";
import AdminPlanning from "./_adminPlanning";
import AdminWorkers from "./_adminWorkers";
import AdminShifts from "./_adminShifts";
import AdminWorkerAvailability from "./_adminWorkerAvailability";
import AdminAreaActivityTypes from "./_adminAreaActivityTypes";
import WorkerShifts from "./_workerShifts";
import AdminWorkerShiftRequests from "./_adminWorkerShiftRequest";
import WorkerShiftReport from "./_workerShiftReport";
import AdminShiftReports from "./_adminShiftReports";
import AdminWorkerBookkeeping from "./_adminWorkerBookkeeping";
import AdminCarpool from "./_adminCarpools";
import AdminWorkerShifts from "./_adminWorkerShifts";

export type defaultApi = {
  create?: Function,
  findMany?: Function,
  findOne?: Function,
  remove?: Function,
  update?: Function,
}

export const apiObject = {
  auth: new Auth(),
  adminUsers: new AdminUsers(),
  adminInvites: new AdminInvites(),
  adminPeriods: new AdminPeriods(),
  adminShiftReports: new AdminShiftReports(),
  adminProjects: new AdminProjects(),
  adminWorkers: new AdminWorkers(),
  adminWorkerShifts: new AdminWorkerShifts(),
  adminCarpool: new AdminCarpool(),
  adminWorkerAvailability: new AdminWorkerAvailability(),
  adminAreas: new AdminAreas(),
  adminActivityTypes: new AdminActivityTypes(),
  adminAreaActivityTypes: new AdminAreaActivityTypes(),
  adminShifts: new AdminShifts(),
  adminPlanning: new AdminPlanning(),
  adminWorkerShiftRequests: new AdminWorkerShiftRequests(),
  adminWorkerBookkeeping: new AdminWorkerBookkeeping(),
  media: new Media(),
  users: new Users(),
  resources: new Resources(),
  invites: new Invites(),
  worker: new WorkerApi(),
  workerAvailabilityRequests: new WorkerAvailabilityRequests(),
  workerAvailability: new WorkerAvailability(),
  workerShifts: new WorkerShifts(),
  workerShiftReport: new WorkerShiftReport(),
};

export default function ApiInstance(axiosInstance: AxiosInstance): typeof apiObject {
  for (const [_, value] of Object.entries(apiObject)) {
    value.axiosInstance = axiosInstance
  }

  return apiObject;
}