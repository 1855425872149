import { ActivityTypeOption } from "src/types/apiTypes";
import { CrossIcon, Heading, IconButton, Pane } from "evergreen-ui";
import { useMemo, useState } from "react";

import useActivityTypeOptions from "src/hooks/useActivityTypeOptions";
import SelectInputField from "../../formfields/SelectInputField";
import { FilterProps } from "./types";

export default function ActivityTypeFilter({ filterMap, handleInput }: FilterProps) {
    const {
        activityTypeOptions,
        isLoading: staticIsLoading
    } = useActivityTypeOptions();

    const [selectedType, setSelectedType] = useState<ActivityTypeOption | null>(null)

    function setSelected(value) {
        setSelectedType(value)
        handleInput(filterMap.activityType, value?.value || null);
    }

    const selected: ActivityTypeOption | null = useMemo(() => {
        if (activityTypeOptions && !selectedType && filterMap.activityType?.value) {
            return activityTypeOptions[filterMap.activityType.value];
        } else {
            return selectedType;
        }
    }, [activityTypeOptions, filterMap.activityType?.value]);

    return <Pane className="flex flex-col gap-1">
        <Pane className="flex justify-between h-4">
            <Heading className={selected ? "!text-black !font-bold": ""} size={200}>Activity Type</Heading>
            {selected &&
              <IconButton icon={CrossIcon} appearance="minimal" height={24} onClick={() => setSelected(null)}/>
            }
        </Pane>
        <SelectInputField
            closeOnSelect
            selected={selected ?? null}
            setSelected={setSelected}
            title="Select type"
            emptySelectText="Filter op activiteitstype..."
            options={Object.values(activityTypeOptions)}
        />
    </Pane>
}

