import {
    Alert, Avatar,
    BanCircleIcon,
    Button,
    Card,
    DriveTimeIcon,
    EyeOpenIcon,
    FileCard,
    Heading,
    InfoSignIcon,
    Pane,
    Paragraph,
    Position,
    Text,
    TickCircleIcon, TickIcon,
    TimeIcon,
    Tooltip,
    UploadIcon
} from "evergreen-ui";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { createElement, useMemo } from "react";
import { Media, WorkerApiType, WorkerShift } from "../../../types/apiTypes";
import { TimeslotMapById } from "../../../hooks/useTimeslotResources";
import { __r, WORKER_SHIFTS_REPORT_PAGE } from "../../../RouteMap";
import { calcEndTime, calcStartTime, classNames, shortenString } from "../../../lib/functions";
import { DateFormat } from "../../../components/common/DateFormat";
import { ReportStatusIconMap } from "../../../components/shared/report/components/ReportFieldsRow";
import { parseISO } from "date-fns";
import { Link } from "react-router-dom";
import { DisplayReportAddress } from "../../../components/shared/report/components/DisplayReportAddress";
import { TimeslotIconMap } from "../../../components/config/iconMaps";
import DurationValue from "../../../components/common/DurationValue";
import TravelDistanceValue from "../../../components/common/TravelDistanceValue";
import ClipboardWrapper from "../../../components/common/ClipboardWrapper";

type WorkerShiftCardProps = {
    workerShift: WorkerShift,
    onClick: () => void,
    timeslotMapById: TimeslotMapById,
    elevation: number,
    showMap?: boolean,
    showDetailsLink?: boolean,
    showPassengerTable?: boolean,
}

export const ShiftStatusIconMap = {
    pending: <TimeIcon color="muted" size={14} />,
    planned: <TimeIcon color="muted" size={14} />,
    submitted: <TickCircleIcon color="success" size={14} />,
    published: <TickCircleIcon color="success" size={14} />,
    cancelled: <BanCircleIcon color="danger" size={14} />,
}

const relativeToMap = {
    'sunrise': 'sunrise',
    'morning': 'morning',
    'midnight': 'midnight',
}

export function WorkerShiftDetails({ workerShift, onClick, timeslotMapById, elevation = 0, ...rest }: WorkerShiftCardProps) {
    const {
        showMap = false,
        showDetailsLink = true,
        showPassengerTable = false,
    } = rest;

    const { t } = useTranslation();

    const shiftReport = workerShift.shiftReport ?? null;
    const timeslot = workerShift.shift.timeslot;

    const startTime = useMemo(() => {
        if (!timeslot) {
            return parseISO(workerShift.shift.date);
        }

        const shiftDate = parseISO(workerShift.shift.date);

        return calcStartTime(shiftDate, timeslot)
    }, [timeslotMapById]) ;

    const endTime = useMemo(() => {
        if (!timeslot) {
            return parseISO(workerShift.shift.date);
        }

        const shiftDate = parseISO(workerShift.shift.date);

        return calcEndTime(shiftDate, timeslot)
    }, [timeslotMapById]) ;

    const classes = useMemo(() => classNames(
        workerShift.shiftReport?.status === 'rejected' ? "border-l-4 border-red-600" : "")
    , [workerShift])

    const passengers = useMemo(() => {
        if (!workerShift.carpool?.workers) {
            return [];
        }

        return workerShift.carpool.workers.filter((worker) => {
            return worker.id !== workerShift.workerId;
        });
    }, [workerShift]);

    const iconKey = workerShift?.shift?.timeslotUid ? timeslotMapById[workerShift.shift.timeslotUid]?.icon: null
    const icon = iconKey ? TimeslotIconMap[iconKey]: null;

    const driverId = workerShift.carpool?.driverId;

    return <Card className={classes} elevation={1} padding={16} marginBottom={16}>
        <Pane className="flex flex-wrap pb-1 gap-1 items-end">
            <Pane className="flex justify-between w-full">
                <Pane className="flex flex-col gap-1">
                    <Heading className="!font-normal" size={600}>
                        { workerShift.areaActivityType.area.name }
                        <Heading size={100}>
                            { workerShift.areaActivityType.activityType.name }
                        </Heading>
                    </Heading>

                    {workerShift.shiftReport?.status === 'rejected' &&
                      <Alert intent="danger">
                        Je rapport is afgekeurd, bekijk de feedback en probeer het opnieuw.
                      </Alert>
                    }

                    {workerShift.workerShiftRequest?.status === 'pending' &&
                      <Alert intent="warning">
                        Je annulering is in behandeling
                      </Alert>
                    }

                    {workerShift.workerShiftRequest?.status === 'declined' &&
                      <Alert intent="warning">
                        <Heading size={400}>
                          Je annulering is afgekeurd:
                        </Heading>

                        <Paragraph size={400}>
                            {workerShift.workerShiftRequest?.adminNotes}
                        </Paragraph>
                      </Alert>
                    }

                    <Pane className="pt-1">
                        {icon && createElement(icon, {size: 65}) }
                    </Pane>

                    <Pane className="flex flex-wrap gap-1">
                        <Heading size={100}>
                            {workerShift.status !== 'cancelled' ?
                                startTime &&
                              <DateFormat
                                className='!normal-case'
                                size={100}
                                formatStr="PPPPp"
                                date={startTime!}
                              />
                                :
                                <Text>-</Text>
                            }
                        </Heading>
                    </Pane>
                </Pane>

                <Pane className="flex justify-end gap-1 flex-wrap">
                    {showDetailsLink &&
                      <Button className="shrink" appearance="primary" intent="info" onClick={onClick} iconBefore={EyeOpenIcon}>
                          {t('worker_shifts_page.see_shift_details')}
                      </Button>
                    }

                    {workerShift.reportIsSubmittable  &&
                      <Link to={__r(WORKER_SHIFTS_REPORT_PAGE, { shiftId: workerShift.originalShiftEntryId } )}>
                        <Button appearance="primary" intent="success" className="self-end shrink" iconBefore={UploadIcon}>
                            {t('worker_shifts_page.submit_report')}
                        </Button>
                      </Link>
                    }
                </Pane>
            </Pane>

            <Pane className={'w-full h-[1px] bg-gray-200'} />

        </Pane>

        <Pane className="flex flex-wrap md:flex-nowrap pt-2 gap-2">
            <Pane className='flex flex-col gap-2 w-full'>
                <Pane>
                    <Heading size={400}>Activiteit:</Heading>
                    <Text>
                        { workerShift.areaActivityType.activityType.name }
                    </Text>
                </Pane>

                <Pane>
                    <Heading size={400}>Type: </Heading>
                    <Text>
                        { workerShift.areaActivityType.activityType.type.label }
                    </Text>
                </Pane>

                <Pane>
                    <Pane className="flex gap-1">
                        <Heading size={400}>Min. starttijd:</Heading>

                        {workerShift.status !== 'cancelled' &&
                          <Tooltip position={Position.RIGHT}
                                   content={
                              t('worker_shifts_page.start_time_info_tooltip', {
                                  startHours: timeslot.start ? (timeslot.start === 0 ? 'meteen' : timeslot.start > 0 ? `+${timeslot.start} uur` : `${timeslot.start} uur`) : '',
                                  beforeOrAfter: timeslot.start < 0 ? 'voor' : 'na',
                                  relativeTo: t('worker_shifts_page.relative_to.' + timeslot.relativeTo),
                                  endHours: timeslot.end,
                                  endBeforeOrAfter: timeslot.end < 0 ? 'voor' : 'na',
                              })}
                          >
                            <Pane className="!justify-self-end cursor-pointer">
                              <InfoSignIcon color="muted"/>
                            </Pane>
                          </Tooltip>
                        }
                    </Pane>
                    <Pane className="flex items-end">
                        <Text className="!text-gray-200 normal-case" size={400}>
                            {workerShift.status !== 'cancelled' ?
                                startTime &&
                              <DateFormat
                                className='normal-case'
                                size={100}
                                formatStr="PPPPp"
                                date={startTime!}
                              />
                                :
                                <Text>-</Text>
                            }
                        </Text>
                    </Pane>

                </Pane>

                <Pane>
                    <Heading size={400}>Max. eindtijd:</Heading>
                    <Pane className="flex items-end">
                        <Text className="!text-gray-200 normal-case" size={400}>
                            {workerShift.status !== 'cancelled' ?
                                endTime &&
                              <DateFormat
                                className="normal-case"
                                size={100}
                                formatStr="PPPPp"
                                date={endTime!}
                              />
                                :
                                <Text>-</Text>
                            }
                        </Text>
                    </Pane>
                </Pane>

                <Pane>
                    <Heading className="flex items-center gap-1" size={400}>
                        <TimeIcon color="muted" size={14} />
                        Berekende reistijd (met auto):
                    </Heading>

                    <Text className="!font-normal" size={400}>
                        <DurationValue seconds={workerShift.travelDuration}
                        />
                    </Text>
                </Pane>

                <Pane>
                    <Heading className="flex items-center gap-1" size={400}>
                        <DriveTimeIcon />
                        Afstand tot locatie:
                    </Heading>
                    <Text className="!font-normal" size={400}>
                        <TravelDistanceValue meters={workerShift.distanceToArea} />
                    </Text>
                </Pane>

                <Pane className="flex gap-4">
                    {!showPassengerTable &&
                        <Carpool
                            workerShift={workerShift}
                            passengers={passengers}
                            driverId={driverId}
                        />
                    }
                </Pane>

            </Pane>
            <Pane className="w-full">
                <Pane className='flex flex-col gap-2 w-full'>
                    <Pane>
                        <Pane className="flex self-end items-center gap-1">
                            <Heading size={400}>Dienst status:</Heading>

                            {workerShift.shiftHasEnded &&
                              <Heading size={200}>{t('worker_shift.ended.' + workerShift.status)}</Heading>
                            }

                            {!workerShift.shiftHasEnded &&
                              <Heading size={200}>{t('worker_shift.' + workerShift.status)}</Heading>
                            }
                            {ShiftStatusIconMap[workerShift.status]}
                        </Pane>
                    </Pane>
                    <Pane>
                        {shiftReport &&
                          <Pane className="flex self-end items-center gap-1">
                            <Heading size={400}>Rapport status:</Heading>
                            <Heading size={200}>{t('shift_report.' + shiftReport.status)}</Heading>
                              {ReportStatusIconMap[shiftReport.status]}
                          </Pane>
                        }
                    </Pane>

                    <Pane>
                        <Notes workerShift={workerShift} />
                    </Pane>

                    <Pane>
                        <DocumentsList workerShift={workerShift} />
                    </Pane>

                </Pane>
            </Pane>
        </Pane>

        {showPassengerTable &&
          <Pane className="py-2">
            <Heading className="py-2" size={400}>Carpool contact informatie:</Heading>
            <Pane className="max-w-4xl">
              <PassengersTable
                  // workerShift={workerShift}
                passengers={passengers}
                driverId={driverId}
              />
            </Pane>
          </Pane>
        }
        <Pane>
            <Pane className="py-2">
                <Heading size={400}>Adres: </Heading>
                <DisplayReportAddress
                    showMap={showMap}
                    minHeightMap={500}
                    address={workerShift.address}
                />
            </Pane>
        </Pane>
    </Card>
}

function Notes({ workerShift }: { workerShift: WorkerShift }) {
    return <Pane className="self-end items-center gap-1">
        <Heading size={400}>Notities:</Heading>
        <Paragraph className="border-l-1 border-gray-500 whitespace-pre-wrap text-gray-500" size={100}>
            { workerShift.areaActivityType.area.notes || "-" }
        </Paragraph>
    </Pane>
}

function DocumentsList({ workerShift }: { workerShift: WorkerShift }) {
    return <>
        <Heading className="pt-2 pb-2" size={400}>Gebied documenten:</Heading>
        {!isEmpty(workerShift.areaActivityType.area.media) ?
            <AttachedMediaFileList mediaFiles={workerShift.areaActivityType.area.media}/>
            :
            <Text className="text-gray-500">-</Text>
        }
    </>
}

function Carpool({ workerShift, passengers, driverId }: { workerShift: WorkerShift, passengers: WorkerApiType[], driverId?: number }) {
    return <>
        <Pane>
            <Heading className="flex items-center gap-1" size={400}>Rijdt zelf:</Heading>
            <Text className="!font-normal" size={400}>
                {workerShift.isDriving ? 'Ja' : 'Nee'}
            </Text>
        </Pane>
        <Pane>
            <Heading className="flex items-center gap-1" size={400}>Carpool:</Heading>
            {isEmpty(passengers) &&
              <Text className="!font-normal" size={400}>
                -
              </Text>
            }
            <Pane className="py-1 !font-normal flex gap-2">
                { passengers.map(worker => (
                    <Pane className="flex gap-1 items-center">
                        <Avatar
                            className="hover:opacity-90"
                            src={worker.user!.profilePictureThumbnail ?? ""}
                            name={""}
                            size={22}
                        />

                        {worker.id === driverId &&
                          <span>
                            <DriveTimeIcon size={14} color="muted" />
                          </span>
                        }
                        <Text className={classNames(worker.id === driverId ? "!font-bold": "", "grow")}>
                            {worker.user.firstName} {worker.user.lastName}
                            {worker.id === driverId &&
                              <Text className="!text-sm">(bestuurder)</Text>
                            }
                        </Text>
                    </Pane>
                ))}
            </Pane>
        </Pane>
    </>
}

function PassengersTable({ passengers, driverId }: { passengers: WorkerApiType[], driverId?: number}) {
    return <table className="data-table w-full">
        <thead>
        <tr>
            <th style={{width: '15em', textAlign: 'left'}}>
                Naam
            </th>
            <th style={{textAlign: 'left'}}>
                Contact informatie
            </th>
            {/*<th style={{width: '15em', textAlign: 'center'}}>*/}
            {/*    Telefoonnummer*/}
            {/*</th>*/}
        </tr>
        </thead>
        <tbody>
        {passengers.map(worker => (
            <tr key={worker.id}>
                <td className="flex gap-1 items-center">
                    <Avatar
                        className="hover:opacity-90"
                        src={worker.user!.profilePictureThumbnail ?? ""}
                        name={""}
                        size={25}
                    />

                    <Pane className="flex flex-col">
                        {worker.id === driverId &&
                          <Pane className="flex flex-wrap gap-1">
                            <DriveTimeIcon size={14} color="muted"/>
                            <Text size='small'>(bestuurder)</Text>
                          </Pane>
                        }

                        <Text className={classNames(worker.id === driverId ? "!font-bold": "", "")}>
                            {worker.user.firstName} {worker.user.lastName}
                        </Text>
                    </Pane>

                </td>
                <td>
                    <Pane className="flex gap-1 flex-wrap text-center">
                        <ClipboardWrapper>
                            {worker.address &&
                                `${worker.address.formattedAddress}`
                            }
                        </ClipboardWrapper>
                        <ClipboardWrapper>
                            {worker.phone}
                        </ClipboardWrapper>
                    </Pane>
                </td>
            </tr>
        ))}
        </tbody>
    </table>
}

export function AttachedMediaFileList({ mediaFiles, maxLengthFileName = 300 }) {
    function doDownload(media: Media) {
        const a = document.createElement('a');

        a.target = "_blank";
        a.style.display = 'none';
        a.href = media.src;

        a.download = media.originalFileName;

        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(media.src);

        document.body.removeChild(a)
    }


    return (
        <Pane className="flex flex-col gap-1">
            {mediaFiles.map((media, index) => (
                <Pane className="hover:underline cursor-pointer" onClick={() => doDownload(media)} key={`${media.originalFileName}-${index}`}>
                    {/*<a rel="noreferrer" download={media.originalFileName} className="hover:underline cursor-pointer" target="_top" href={media.src}>*/}
                        <FileCard
                            isInvalid={false}
                            name={shortenString(media.originalFileName, maxLengthFileName)}
                            paddingRight={10}
                            //@ts-ignore
                            sizeInBytes={media.size}
                            type={media.mimeType}
                            isLoading={false}
                            src={media.src}
                        />
                    {/*</a>*/}
                </Pane>
            ))}

            { isEmpty(mediaFiles) &&
              <Paragraph>Geen bestanden toegevoegd...</Paragraph>
            }
        </Pane>
    );
}

