import { useTranslation } from "react-i18next";
import { useReportForm } from "../../../../pages/worker-shifts/WorkerShiftReportPage";
import { FormField, Pane, Text, Select, Checkbox } from "evergreen-ui";
import { ReportFormFieldProps } from "src/types/appTypes";

export function AnimalNest({ reportKey, editMode }: ReportFormFieldProps ) {
    const { t } = useTranslation();
    const { onChange, formState } = useReportForm();

    function setValue(value) {
        onChange(reportKey.key, !!value);
    }

    return <>
        <Pane className="basis-full" />
        <Pane className="grow">
            <FormField
                hint={editMode ? "Vink aan als je een nest hebt gezien": null}
                label={t('report_fields.' + reportKey.key)}>
                {editMode ?
                    <Checkbox
                        onChange={e => setValue(e.target.checked)}
                        checked={formState?.reportFields?.animalNest?.value ?? false}
                    />
                    :
                    <Text>{formState?.reportFields?.animalNest?.value ? "Ja": "Nee"}</Text>
                }
            </FormField>
        </Pane>
    </>
}

