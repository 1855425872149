import { DashboardIcon, Pane, UserIcon } from "evergreen-ui";
import { Outlet, useParams } from "react-router-dom";
import TopSubNav from "../common/TopSubNav";
import { REPORTS_NEW_LIST_PAGE, REPORTS_RESULTS_PAGE } from "src/RouteMap";

export default function ReportsLayout() {
    const menuItems = [
        {
            label: 'Rapporten',
            pathname: REPORTS_NEW_LIST_PAGE,
            icon: <UserIcon />
        },
        {
            label: 'Resultaten',
            pathname: REPORTS_RESULTS_PAGE,
            icon: <DashboardIcon />
        },
    ];

    return (
        <Pane className="h-full">
            <TopSubNav menuItems={menuItems} />

            <Pane className="h-full">
                <Outlet />
            </Pane>
        </Pane>
    );
}