import { Area } from "../../types/apiTypes";

export const AreaActivityTypesDisplayCell = function( { area }: ActivityTypesCellProps)  {
    const display = area.activityTypes.map((activityType) => {
        return activityType.name;
    }).join(', ')

    return <>
        { display }
    </>
}

export type ActivityTypesCellProps = {
    area: Area,
}
