import React, { Suspense } from 'react';
import './App.css';
import { AxiosProvider } from "./providers/AxiosProvider";
import { AppProvider } from "./providers/AppProvider";
import { AuthProvider } from "./providers/AuthProvider";
import AppRoutes from "./AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { BootstrapProvider } from "./providers/BootstrapProvider";
import { ThemeProvider, defaultTheme, mergeTheme } from "evergreen-ui";

function App() {
    const grofalexTheme = {
            TableHead: {
                baseStyle: {
                    paddingRight: 0
                }
            },
        //     Button: {
        //         baseStyle: {
        //             // color: 'white',
        //             // paddingX: 12,
        //             // paddingY: 8,
        //             // borderRadius: 5,
        //             // backgroundColor: 'indianred',
        //             // _hover: {
        //             //     backgroundColor: 'firebrick',
        //             // },
        //             // _active: {
        //             //     backgroundColor: 'darkred',
        //             // },
        //             // _focus: {
        //             //     boxShadow: '0 0 0 2px lightcoral',
        //             // },
        //         },
        //     },
        // }
    }


    //@ts-ignore
    const theme = mergeTheme(defaultTheme, grofalexTheme);

    return (
        <Suspense>
            <ThemeProvider value={theme}>
                <AppProvider>
                    <AuthProvider>
                        <AxiosProvider>
                            <BootstrapProvider>
                                <BrowserRouter>
                                    <AppRoutes />
                                </BrowserRouter>
                            </BootstrapProvider>
                        </AxiosProvider>
                    </AuthProvider>
                </AppProvider>
            </ThemeProvider>
        </Suspense>
  );
}

export default App;
