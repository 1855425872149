import { Alert, Button, FormField, majorScale, Pane, Paragraph, Switch, TextInputField, } from "evergreen-ui";
import Block from "src/components/common/Block";
import { useState } from "react";
import { useDoRequest, useLoadResource } from "src/lib/request-hooks";
import { useApi } from "src/context/AxiosContext";
import Divider from "src/components/common/Divider";
import { Address } from "src/types/apiTypes";
import { useTranslation } from "react-i18next";
import MapBoxAddressInputField from "../formfields/MapBoxAddressInputField";
import { useAuth } from "../../context/AuthContext";

/**
 * Pass the adminEdit if we an admin is editing the worker. This will change the update and find function.
 * 
 * @param worker
 * @param adminEdit
 */
export function useWorkerDetailsForm(worker, adminEdit = false) {
    const { apiInstance } = useApi();

    const {
        handle,
        setIsLoading: setIsSubmitting,
        isLoading: isSubmitting,
        validationErrors,
        errorMessage,
    } = useDoRequest();

    const [ iban, setIban ] = useState<string | null>(null);
    const [ ibanOwner, setIbanOwner ] = useState<string | null>(null);
    const [ hasTransport, setHasTransport] = useState<boolean>(false);
    const [ hasDrivingLicense , setHasDrivingLicense] = useState<boolean>(false);
    const [ phone, setPhone ] = useState<string | null>(null);
    const [ address, setAddress] = useState<Address | null>(null);

    let find;

    if (adminEdit) {
        find = () => apiInstance!.adminWorkers.findOne(worker.id);
    } else {
        find = () => apiInstance!.worker.findMe();
    }

    function setData(worker) {
        setIban(worker.iban)
        setIbanOwner(worker.ibanOwner)
        setHasTransport(worker.hasTransport)
        setHasDrivingLicense(worker.hasDrivingLicense)
        setPhone(worker.phone)
        setAddress(worker.address)
    }

    const doSubmit = async () => {
        const data = {
            iban,
            ibanOwner,
            hasTransport,
            hasDrivingLicense,
            phone,
            address,
        }

        let request;

        if (adminEdit) {
            request = apiInstance!.adminWorkers.update(worker.id, data);
        } else {
            request = apiInstance!.worker.updateMe(data);
        }
        return await handle(request);
    }

    return {
        ...useLoadResource(find, setData, !!worker),
        iban,
        setIban,
        ibanOwner,
        setIbanOwner,
        // profilePicture,
        // setProfilePicture,
        hasTransport,
        setHasTransport,
        hasDrivingLicense ,
        setHasDrivingLicense,
        address,
        setAddress,
        doSubmit,
        phone,
        setPhone,
        submitContext: {
            doSubmit,
            setIsSubmitting,
            isSubmitting,
            errorMessage,
            validationErrors,
        },
    }
}

export default function WorkerDetailsForm({ useForm, showActionButtons = true, ...rest }) {
    const { t } = useTranslation();
    const { user } = useAuth();

    const {
        iban,
        setIban,
        address,
        setAddress,
        ibanOwner,
        setIbanOwner,
        hasTransport,
        setHasTransport,
        hasDrivingLicense ,
        setHasDrivingLicense,
        doSubmit,
        phone,
        setPhone,
        submitContext,
        isLoading,
    } = useForm;

    return <Pane>
        {isLoading === false &&
          <Block className={'flex flex-col gap-5 justify-center align-items'}>
            <TextInputField
              autoFocus={showActionButtons}
              marginBottom={10}
              inputHeight={40}
              label={t('common.mobile_phone')}
              name="phone"
              inputMode="tel"
              hint={t('worker_details_onboarding_page.mobile_phone_hint')}
              placeholder="+316 ... "
              value={phone ?? ""}
              onChange={(e) => setPhone(e.target.value)}
              isInvalid={!!submitContext.validationErrors?.phone}
              validationMessage={submitContext.validationErrors?.phone?.join(', ')}
            />

            <Divider title="Address">
              <FormField
                label={t('worker_details_onboarding_page.full_address').toString()}
                marginBottom={20}>
                <Paragraph className="!text-xs" color="muted">{ t('worker_details_onboarding_page.full_address_hint') }</Paragraph>
                  <MapBoxAddressInputField
                    setAddress={setAddress}
                    address={address}
                    minMapHeight={400}
                    textBoxWidth={200}
                  />
                {/*<AddressInputField*/}
                {/*  address={address}*/}
                {/*  inputLabel={t('worker_details_onboarding_page.full_address').toString()}*/}
                {/*  setIsEditing={setIsEditingAddress}*/}
                {/*  setAddress={setAddress}*/}
                {/*  validationErrors={submitContext.validationErrors?.address}*/}
                {/*/>*/}
              </FormField>
            </Divider>

            <Divider title="Payment details">
              <Pane className="flex w-full gap-3 flex-wrap sm:flex-nowrap">
                <TextInputField
                  marginBottom={10}
                  width={"100%"}
                  hint={t('worker_details_onboarding_page.iban_hint')}
                  inputHeight={40}
                  label="IBAN"
                  name="iban"
                  value={iban ?? ""}
                  placeholder="NL99ING0000000"
                  onChange={(e) => setIban(e.target.value?.toUpperCase())}
                  isInvalid={!!submitContext.validationErrors?.iban}
                  validationMessage={submitContext.validationErrors?.iban?.join(', ')}
                />
                <TextInputField
                  marginBottom={10}
                  width={"100%"}
                  inputHeight={40}
                  label={t('common.iban_owner')}
                  name="ownerIban"
                  placeholder="..."
                  value={ibanOwner ?? ""}
                  onChange={(e) => setIbanOwner(e.target.value)}
                  isInvalid={!!submitContext.validationErrors?.ibanOwner}
                  validationMessage={submitContext.validationErrors?.ibanOwner?.join(', ')}
                />
              </Pane>
            </Divider>

            <FormField label={t('worker_details_onboarding_page.driving_license')}>
              <Switch
                marginBottom={16}
                checked={hasDrivingLicense}
                onChange={(e) => setHasDrivingLicense(e.target.checked)}
              />
            </FormField>

            <FormField
              hint={t('worker_details_onboarding_page.own_transportation_hint')}
              label={t('worker_details_onboarding_page.own_transportation')}>
              <Switch
                marginBottom={16}
                checked={hasTransport}
                onChange={(e) => setHasTransport(e.target.checked)}
              />
            </FormField>

              {submitContext.errorMessage &&
                <Alert
                  intent="danger"
                  title={submitContext.errorMessage}
                  marginBottom={32}
                />
              }

              {showActionButtons &&
                <Block className={"flex justify-end"}>
                  <Button
                    disabled={!address || !phone || !iban || !ibanOwner || !user!.profilePicture}
                    intent="success"
                    appearance="primary"
                    onClick={doSubmit}
                    isLoading={!!submitContext.isSubmitting}
                    height={majorScale(5)}>{t('worker_details_onboarding_page.save_label')}
                  </Button>
                </Block>
              }
          </Block>
        }
    </Pane>

}
