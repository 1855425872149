import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { CsvDataUrl, Id, User } from "../types/apiTypes";
import { PostData, SearchParams } from "../types/appTypes";
import { LoginAsData } from "../types/apiDataTypes";

export default class AdminUsers extends BaseApi {
  findOne(id: Id): Promise<User> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/users/${id}`
    });
  }

  loginAs(userId: Id): Promise<LoginAsData> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/users/${userId}/login-as`
    });
  }

  findMany(searchParams: SearchParams = {}): Promise<User> {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/users${query}`,
    });
  }

  exportUsersCsv(searchParams : SearchParams = {}): Promise<CsvDataUrl> {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/users/csv${query}`,
    });
  }


  update(id: Id, data: PostData): Promise<User> {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/admin/users/${id}`,
      data: data,
    });
  }

  remove(id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/admin/users/${id}`,
    });
  }
}