import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { PostData, SearchParams } from "../types/appTypes";
import { ActivityType, Id } from "../types/apiTypes";

export default class AdminActivityTypes extends BaseApi {
  findOne(id: Id): Promise<ActivityType> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/activity-types/${id}`
    });
  }

  update(id: Id, data: PostData): Promise<ActivityType> {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/admin/activity-types/${id}`,
      data: data,
    });
  }

  findMany(projectId: Id, searchParams: SearchParams = {}): Promise<ActivityType[]>  {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/projects/${projectId}/activity-types${query}`,
    });
  }

  createForProject(projectId: Id, data: PostData): Promise<ActivityType[]> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/projects/${projectId}/activity-types`,
      data: data,
    });
  }

  remove(id: Id): Promise<{ success: boolean}> {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/admin/activity-types/${id}`,
    });
  }
}