import { Pane } from "evergreen-ui";
import DateRangeFilter from "src/pages/reports/_components/DateRangeFilter";
import { parseISO } from "date-fns";

export default function WorkerShiftHistoryFilters({ filterLogic }) {
    const {
        handleInput,
        filterMap,
    } = filterLogic;

    return <Pane className="flex gap-2 flex-wrap lg:flex-nowrap">
        {filterMap.fromDate?.value && filterMap.toDate?.value &&
          <DateRangeFilter
            filterMap={filterMap}
            handleInput={handleInput}
            startAt={filterMap.fromDate?.value ? parseISO(filterMap.fromDate.value) : null}
            endAt={filterMap.toDate?.value ? parseISO(filterMap.toDate.value) : null}
            fromDateFilter={filterMap.fromDate}
            toDateFilter={filterMap.toDate}
          />
        }
    </Pane>
}
