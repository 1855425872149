import { EmptyState, Heading, SearchIcon, Table } from "evergreen-ui";
import Block from "../../../components/common/Block";
import Actions from "../../../components/common/Actions";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLoadResource } from "../../../lib/request-hooks";
import { useApi } from "../../../context/AxiosContext";
import { Area } from "../../../types/apiTypes";
import StandardTable from "src/components/table/StandardTable";
import { AddressCellDisplay } from "../../../components/addresses/AddressCellDisplay";
import { AreaActivityTypesDisplayCell } from "../../../components/activity-types/AreaActivityTypesDisplay";
import { useWindowSearchParams } from "../../../hooks/useWindowSearchParams";

const useAreas = function () {
    const searchParams = useWindowSearchParams({
        limit: '100',
    });

    const { projectId } = useParams();
    const [ areas, setAreas] = useState<Area[] | null>(null);
    const { apiInstance } = useApi();


    const request = () => apiInstance!.adminAreas.findManyForProject(projectId!, searchParams);

    const setData = (areas: Area[]) => {
        setAreas(areas);
    }

    return {
        ...useLoadResource(request, setData, true),
        areas,
    };
}

export default function AreasPage() {
    const { projectId } = useParams();

    const { areas, isLoading, meta, refresh  } = useAreas();
    const navigate = useNavigate();

    const navTo = (area) => {
        navigate(`/projects/${projectId}/areas/${area.id}`);
    }

    return <>
        <Block>
            <Actions marginBottom={10}>
                <Link to={`/projects/${projectId}/areas/add`}>
                    <Actions.Button
                        appearance="primary">Nieuw gebied
                    </Actions.Button>
                </Link>
            </Actions>
            <StandardTable refresh={refresh} meta={meta} isLoading={isLoading} data={areas} emptyState={<EmptyAreasState />}>
                <Table.Head>
                    <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Activity Types</Table.TextHeaderCell>
                    <Table.TextHeaderCell>Address</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body height="auto">
                    {(areas || []).map((area) => (
                        <Table.Row key={area.id} isSelectable onSelect={() => { navTo(area)}}>
                            <Table.TextCell>{area.name}</Table.TextCell>
                            <Table.TextCell>
                                <AreaActivityTypesDisplayCell area={area} />
                            </Table.TextCell>
                            <Table.TextCell>
                                <AddressCellDisplay area={area} />
                            </Table.TextCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </StandardTable>
        </Block>
    </>
}

function EmptyAreasState() {
    return (
        <EmptyState
            background="light"
            title="Geen gebieden"
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="Er zijn nog geen gebieden toegevoegd. Klik op de knop 'Nieuw gebied' om een gebied toe te voegen."
        />
    )
}
