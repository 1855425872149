import { CrossIcon, Heading, IconButton, Pane, Select, useTheme } from "evergreen-ui";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectFilter from "../../../components/shared/filters/ProjectFilter";
import WorkerFilter from "../../../components/shared/filters/WorkerFilter";
import DateRangeFilter from "./DateRangeFilter";
import { parseISO } from "date-fns";

const reportStatusOptions = [
    { value: 'pending', label: 'In afwachting' },
    { value: 'approved', label: 'Goedgekeurd' },
    { value: 'rejected', label: 'Afgekeurd' },
];

function ReportStatusFilter( { filterMap, handleInput }) {
    const [status, setStatus] = useState<string | null>();
    const { colors } = useTheme();

    function setStatusFilter(value) {
        setStatus(value);
        handleInput(filterMap.status, value ?? null);
    }

    const selected = useMemo(() => {
        if (!status && filterMap.status?.value) {
            return filterMap.status.value;
        } else {
            return status;
        }
    }, [status, filterMap.status?.value]);

    return <Pane className="flex flex-col gap-1">
        <Pane className="flex justify-between h-4">
            <Heading className={selected ? "!text-black !font-bold": ""} size={200}>Status</Heading>
            {selected &&
              <IconButton icon={CrossIcon} appearance="minimal" height={24} onClick={() => setStatusFilter(null)}/>
            }
        </Pane>
        <Pane maxWidth={200} height={40}>
            <Select
                onChange={e => setStatusFilter(e.target.value)}
                value={selected ?? ''}
                background={selected ? colors.blue50: ""}
                border={selected ? colors.selected: ""}
                height={40}
            >
                <option key="-" value="">
                    Filter op report status
                </option>
                { reportStatusOptions.map((option) => (
                    <option key={option.value} value={ option.value }>
                        { option.label}
                    </option>
                )) }
            </Select>
        </Pane>
    </Pane>
}


export default function ReportFilters({ filterLogic }) {
    const {
        handleInput,
        filterMap,
    } = filterLogic;

    return <Pane className="flex gap-2 flex-wrap lg:flex-nowrap">
        <ReportStatusFilter filterMap={filterMap} handleInput={handleInput} />
        <DateRangeFilter
            filterMap={filterMap}
            handleInput={handleInput}
            startAt={filterMap.fromDate?.value ? parseISO(filterMap.fromDate.value) : null}
            endAt={filterMap.toDate?.value ? parseISO(filterMap.toDate.value) : null}
            fromLabel={"Ingediend vanaf:"}
            toLabel={"Ingediend t/m:"}
            fromDateFilter={filterMap.fromDate}
            toDateFilter={filterMap.toDate}
        />
        {/*<ProjectFilter filterMap={filterMap} handleInput={handleInput} />*/}
        {/*<WorkerFilter filterMap={filterMap} handleInput={handleInput} />*/}
    </Pane>
}
