import { formatDistance, intervalToDuration, parseISO } from "date-fns";
import { formatDiff } from "../../lib/functions";
import { nl } from 'date-fns/locale'

type DateValueProps = {
   leftDate: Date | string;
   rightDate: Date | string;
   formatStr?: string;
}

export const getDiffValue = (leftDate: string | Date, rightDate: string | Date) => {
    let start = leftDate;
    let end = rightDate;

    if (typeof leftDate === 'string') {
        start = parseISO(leftDate);
    }

    if (typeof rightDate === 'string') {
        end = parseISO(rightDate);
    }

    const duration = formatDistance(start as Date, end as Date, { locale: nl });

    return duration;
}

export default function DateDiffValue({leftDate, rightDate, formatStr = "PP"}: DateValueProps) {
    let start = leftDate;
    let end = rightDate;

    if (typeof leftDate === 'string') {
        start = parseISO(leftDate);
    }

    if (typeof rightDate === 'string') {
        end = parseISO(rightDate);
    }

    const duration = intervalToDuration({
        //@ts-ignore
        start: start,
        //@ts-ignore
        end: end,
    })

    return <>
        { formatDiff(duration) }
    </>
}