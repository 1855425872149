import { Area } from "../../types/apiTypes";

export const AddressCellDisplay = function( { area }: AddressCellDisplayProps)  {
    const display = area.addresses.map((address) => {
        return address.formattedAddress;
    }).join(', ')

    return <>
        { display }
    </>
}

export type AddressCellDisplayProps = {
    area: Area,
}
