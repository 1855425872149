import { memo } from "react";
import { Text, TextProps } from "evergreen-ui";
import { format, isValid } from "date-fns";
import * as locale from 'date-fns/locale/nl'

interface DateFieldPropType extends TextProps {
    date: Date | null;
    formatStr?: string;
}

export const DateFormat = memo(function DateFormat({ date, formatStr = "PP", ...rest }: DateFieldPropType) {
    if (!isValid(date)) {
        return <Text {...rest}>
             -
        </Text>
    }

    return <Text {...rest}>
        {date ? format(date, formatStr, {locale: locale.default}) : "-" }
    </Text>
})
