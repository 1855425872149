import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { PostData, SearchParams } from "../types/appTypes";
import { Id, Period } from "../types/apiTypes";

export default class AdminPeriods extends BaseApi {
  findOne(id: Id, params : SearchParams = {}): Promise<Period> {
    const query = params ? `?${createSearchParams(params)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/periods/${id}${query}`
    });
  }

  findMany(searchParams : SearchParams = {}): Promise<Period[]> {
    const query = searchParams ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/admin/periods${query}`,
    });
  }

  create(data: PostData): Promise<Period> {
    return doRequest(this._axiosInstance!, {
      method: 'post',
      url: `/admin/periods`,
      data: data,
    });
  }

  update(id: Id, data: PostData): Promise<Period> {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/admin/periods/${id}`,
      data: data,
    });
  }

  remove(id: Id): Promise<{ success: boolean }> {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/admin/periods/${id}`,
    });
  }
}