import { useTranslation } from "react-i18next";
import { useReportForm } from "../../../../pages/worker-shifts/WorkerShiftReportPage";
import { Pane, TextareaField } from "evergreen-ui";
import { ReportFormFieldProps } from "src/types/appTypes";

export function ReportNotes({ reportKey, editMode }: ReportFormFieldProps) {
    const { t } = useTranslation();
    const { submitContext, onChange, formState } = useReportForm();

    function setValue(value) {
        // setNotes(value);
        onChange(reportKey.key, value);
    }

    return <Pane className="w-full grow">
        <TextareaField
            required
            disabled={!editMode}
            hint="Vertel wat je gezien hebt"
            name="notes"
            width={'100%'}
            label={t('report_fields.' + reportKey.key)}
            placeholder="..."
            isInvalid={!!submitContext.validationErrors?.notes}
            validationMessage={submitContext.validationErrors?.notes?.join(', ')}
            //@ts-ignore
            value={formState?.reportFields?.notes?.value || ""}
            onChange={(e) => setValue(e.target.value)}
            marginBottom={0}
            inputHeight={40}
            className="!text-base"
        />
    </Pane>
}

