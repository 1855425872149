import { useMemo, useRef } from "react";

import {
    CrossIcon,
    Dialog,
    EmptyState,
    FormField,
    Heading,
    IconButton,
    Pane, Paragraph,
    SearchIcon,
    Select, SettingsIcon
} from "evergreen-ui";

import Collapse from "src/components/common/Collapse";
import DatePicker from "src/components/formfields/DatePicker";
import FilterWorkerTable from "./filters/FilterWorkerTable";
import Form from "src/components/common/Form";
import PendingChangesDialog from "src/components/common/PendingChangesDialog";
import WorkerFilters, { RefWorkerFilter } from "./filters/WorkerFiltersForPlanner";
import WorkerItemWithPopover from "./WorkerItemWithPopover";
import usePlannerDialogLogic from "./logic/usePlannerDialogLogic";
import useSafeLeave from "src/hooks/useSafeLeave";

import { TableLoadingSpinner } from "src/components/table/StandardTable";
import { useTranslation } from "react-i18next";

import { AreaActivityType, WorkerApiType } from "src/types/apiTypes";
import { PlannerDialogContext, usePlannerDialog } from "./logic/PlannerDialogContext";
import { InfoBox } from "../../projects/activity-types/ActivityTypeEditPage";

export default function PlannerDialog() {
    const { t } = useTranslation();
    const WorkerFilterRef = useRef<RefWorkerFilter>(null);

    const {
        areaActivityType,
        date,
        period,
        doSubmit,
        handleTimeslotChange,
        notAfterDate,
        notBeforeDate,
        onCloseDialog,
        selectedTimeslot,
        shiftIsLoading,
        timeslotUid,
        timeslotsAvailable,
        setDate,
        datePickerHint,
        submitContext,
        state,
        dispatch,
        helpers,
    } = usePlannerDialogLogic();

    const {
        closeSafeLeaveDialog,
        doLeave,
        onLeave,
        safeLeaveDialogShown,
    } = useSafeLeave({ onConfirm: onCloseDialog, isDirty: state.isDirty })

    function setDateCb(date: Date) {
        if (WorkerFilterRef.current) {
            WorkerFilterRef.current.setAvailableOnDate(date);
        }

        setDate(date)
    }

    function setTimeslotCb(value: string) {
        if (WorkerFilterRef.current) {
            WorkerFilterRef.current.setTimeslotUid(value);
        }

        handleTimeslotChange(value);
    }

    const dateInQuery = useMemo(() => {
        return new URLSearchParams(window.location.search).get('date');
    }, [date]);

    return <Dialog
        width={1500}
        contentContainerProps={{
            paddingRight: 0,
            position: 'relative',
            height: "100%",
            overflowY: "unset",
        }}
        isShown={true}
        isConfirmLoading={!!submitContext.isLoading}
        shouldCloseOnOverlayClick={false}
        header={
            <DialogHeader
                close={onLeave}
                areaActivityType={areaActivityType}
            />
        }
        title={areaActivityType?.activityType.name}
        onCloseComplete={onCloseDialog}
        onCancel={onLeave}
        onConfirm={doSubmit}>

        <PlannerDialogContext.Provider value={ { state, dispatch, helpers } }>
            <Pane className="flex gap-2 relative">
                <PendingChangesDialog
                    doAction={doLeave}
                    isShown={safeLeaveDialogShown}
                    onCloseComplete={closeSafeLeaveDialog}
                    isConfirmLoading={!!submitContext.isLoading}
                />

                  <Pane className="w-1/5 h-full pr-2 border-r border-gray-200 min-w-[15rem]">
                      <Pane className="flex pb-4 gap-1">
                          <SettingsIcon size={16} color="#C1C4D6" marginRight={8}/>
                          <Heading size={100}>Filters</Heading>
                      </Pane>

                      {!shiftIsLoading && date && areaActivityType && selectedTimeslot ?
                          <WorkerFilters
                              key={date?.toString() || 'no-date'}
                              ref={WorkerFilterRef}
                              shiftDate={date}
                              timeslot={selectedTimeslot}
                              address={areaActivityType?.area.addresses?.[0]}
                              area={areaActivityType.area}
                          />
                          :
                          <Pane className="py-2">
                              <InfoBox
                                  description="Selecteer een datum en tijdslot om de beschikbare filters te zien."
                              />
                          </Pane>
                      }
                  </Pane>

                <Pane className="flex flex-col w-4/5">
                    <Form className="flex gap-2 pr-5" marginY={50}>
                        <>
                            <FormField className="self-start" label="Plannen op datum:" hint={datePickerHint}>
                                {notBeforeDate && notAfterDate && period &&
                                  <DatePicker
                                    key={notAfterDate?.toDateString()}
                                    datePickerOptions={{
                                        disabled: {
                                            before: notBeforeDate,
                                            after: notAfterDate,
                                        }
                                    }}
                                    disabled={false}
                                    dateValue={date}
                                    defaultMonth={notBeforeDate}
                                    setDateValue={setDateCb}
                                  />
                                }
                            </FormField>

                            <FormField className="self-start" label="Tijdslot">
                                <Select
                                    value={timeslotUid}
                                    onChange={(e) => setTimeslotCb(e.target.value)}
                                    width={240}
                                    height={40}>
                                    <option key='-'>
                                        -
                                    </option>
                                    { timeslotsAvailable.map((option) => (
                                        <option key={option.value} value={ option.value }>
                                            {t(option.label)}
                                        </option>
                                    )) }
                                </Select>
                            </FormField>
                        </>
                    </Form>

                    <Pane className="py-4">
                        <AttachedWorkers areaActivityType={areaActivityType} />
                    </Pane>

                    <Pane>
                        <FilterWorkerTable />
                    </Pane>

                    { shiftIsLoading === null && <TableLoadingSpinner title="Loading workers" />}

                    { shiftIsLoading === false && date === null &&
                      <Pane>
                        <EmptyWorkersState />
                      </Pane>
                    }
                </Pane>
            </Pane>
        </PlannerDialogContext.Provider>
    </Dialog>
}

function AttachedWorkers({ areaActivityType }: { areaActivityType: AreaActivityType | null}) {
    const {
        dispatch,
        state,
        helpers,
    } = usePlannerDialog();

    const {
        attachedWorkers,
        setPopupShownId,
        popupShownId,
    } = state;

    function setDriver(worker: WorkerApiType, driverId: number | null) {
        helpers!.updateWorker(worker, { driverId })
    }

    function setIsDriving(worker: WorkerApiType) {
        const isDriving = !worker.isDriving;

        helpers!.updateWorker(worker, {
            isDriving: !worker.isDriving,
            driverId: isDriving ? worker.id : null,
        })
    }

    function onDelete(worker: WorkerApiType) {
        helpers!.doUnAssignWorker(worker);
    }

    return <Pane>
        <Heading size={100}>
            {attachedWorkers.length} / {areaActivityType?.nWorkers} veldwerker(s) toegewezen
        </Heading>
        <Collapse defaultIsOpen={true} title={"Veldwerkers"}>
            <Pane className="flex flex-wrap gap-2">
                { attachedWorkers.map((worker) => (
                    <WorkerItemWithPopover
                        worker={worker}
                        onDelete={onDelete}
                        setIsDriving={setIsDriving}
                        setDriver={setDriver}
                        setPopupShownId={setPopupShownId}
                        popupShownId={popupShownId}
                        key={'workeritem' + worker.id}
                    />
                ))}
            </Pane>
        </Collapse>
    </Pane>
}

function EmptyWorkersState() {
    return (
        <EmptyState
            background="light"
            title="De minimale dag waarop deze shift gepland kan worden valt buiten deze periode."
            orientation="horizontal"
            icon={<SearchIcon color="#C1C4D6"/>}
            iconBgColor="#EDEFF5"
            description="No workers available"
        />
    )
}

function DialogHeader({ areaActivityType, close }) {
    return <Pane className="flex w-full">
        <Pane className="flex grow gap-3 items-center">
            <Heading size={800}>{areaActivityType?.activityType.name}</Heading>
            <Heading className="pt-1" size={100}>in {areaActivityType?.area.name}</Heading>
        </Pane>

        <Pane className="shrink">
            <IconButton
                onClick={close}
                appearance="minimal"
                icon={CrossIcon}
                type="button"
            />
        </Pane>
    </Pane>
}