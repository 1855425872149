import { Dialog, Text, Pane, TextareaField, toaster } from "evergreen-ui";
import { useParams } from "react-router-dom";
import { useApi } from "src/context/AxiosContext";
import { useState } from "react";
import { useDoRequest } from "src/lib/request-hooks";
import { useTranslation } from "react-i18next";
import { ShiftReport } from "../../../types/apiTypes";
import Form from "src/components/common/Form";

function useApproveReportForm({ shiftReport }) {
    const { t } = useTranslation();
    const { shiftReportId } = useParams();
    const {apiInstance} = useApi();

    const [notes, setNotes] = useState<string>(shiftReport.notes);

    const {
        handle,
        setIsLoading: setIsSubmitting,
        isLoading: isSubmitting,
        validationErrors,
        setValidationErrors,
        errorMessage: submitErrorMessage,
    } = useDoRequest();

    const doSubmit = async () => {
        const data = {
            notes: notes ?? null,
        }

        const request = apiInstance!.adminShiftReports.approve(shiftReportId!, data);
        await handle(request);

        toaster.success('Goedgekeurd!');
    }

    return {
        submitContext: {
            doSubmit,
            setIsSubmitting,
            isSubmitting,
            submitErrorMessage,
            validationErrors
        },
        notes,
        setNotes,
        doSubmit,
    }
}

export default function ApproveReportDialog({ shiftReport, isShown, close, afterSubmit }: { shiftReport: ShiftReport, isShown: boolean, close: () => void, afterSubmit: () => void }) {
    const {
        submitContext,
        notes,
        setNotes,
        doSubmit,
    } = useApproveReportForm({ shiftReport });

    async function onConfirm() {
        await doSubmit();
        setNotes('')

        afterSubmit();
    }

    return <Dialog
        confirmLabel="Goedkeuren"
        intent="success"
        isShown={isShown}
        onCloseComplete={close}
        onConfirm={onConfirm}
        shouldCloseOnOverlayClick={false}
        title="Rapport goedkeuren"
    >
        <Pane>
            <Form onSubmit={onConfirm}>
                <>
                    <Text className="py-4">Geef evt. een extra notitie op de goedkeuring.</Text>
                    <TextareaField
                        autoFocus
                        name="notes"
                        label="Notities"
                        placeholder="..."
                        isInvalid={!!submitContext.validationErrors?.notes}
                        validationMessage={submitContext.validationErrors?.notes?.join(', ')}
                        value={notes || ""}
                        onChange={(e) => setNotes(e.target.value)}
                        marginBottom={0}
                        inputHeight={40}
                        className="!text-base"
                    />
                </>
            </Form>
        </Pane>
    </Dialog>
}
