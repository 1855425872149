import { Pane } from "evergreen-ui";

export default function Page({ children, ...rest}) {
    return <Pane
        paddingX={20}
        paddingBottom={100}
        marginTop={20}
        {...rest}
    >{ children }</Pane>
};
