import { doRequest } from "./_helpers";
import BaseApi from "./_baseApi";
import { createSearchParams } from "react-router-dom";
import { isEmpty } from 'lodash';
import { Id, Period } from "../types/apiTypes";
import { PostData, SearchParams } from "../types/appTypes";

export default class WorkerAvailabilityRequests extends BaseApi {
  findOne(id: Id): Promise<Period> {
    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/worker/periods/${id}`
    });
  }

  findMany(searchParams: SearchParams  = {}): Promise<Period[]> {
    const query = !isEmpty(searchParams) ? `?${createSearchParams(searchParams)}`: '';

    return doRequest(this._axiosInstance!, {
      method: 'get',
      url: `/worker/periods${query}`,
    });
  }

  update(id: Id, data: PostData): Promise<Period> {
    return doRequest(this._axiosInstance!, {
      method: 'put',
      url: `/worker/periods/${id}`,
      data: data,
    });
  }

  remove(id: Id): Promise<{ success: boolean}> {
    return doRequest(this._axiosInstance!, {
      method: 'delete',
      url: `/worker/periods/${id}`,
    });
  }
}