import { Heading, Text, Pane } from "evergreen-ui";
import { Area } from "../../../types/apiTypes";
import { memo } from "react";

const PlannerAggregations = memo(function PlannerAggregations({ planning }: { planning: Area[] }) {
    const totalShifts = planning.reduce((acc, area) => {
        return acc + area.totalShifts;
    }, 0);

    const totalUnplannedShifts = planning.reduce((acc, area) => {
        return acc + area.totalUnplannedShifts;
    }, 0);

    const meanShiftsPerPeriod = planning.reduce((acc, area) => {
        return acc + area.meanShiftsPerPeriod;
    }, 0);

    const meanUnplannedShiftsPerPeriod = planning.reduce((acc, area) => {
        return acc + area.meanUnplannedShiftsPerPeriod;
    }, 0);

    const yellowCount = planning.reduce((acc, area) => {
        return acc + area.areaActivityTypes.reduce((acc, areaActivityType) => {
            if (areaActivityType.activityType.unplannedShiftCount === 0) {
                return acc;
            }

            const legRoom = areaActivityType.activityType.legRoom;

            return acc + (legRoom <= 10 && legRoom >= 5 ? 1 : 0);
        }, 0);
    }, 0);

    const orangeCount = planning.reduce((acc, area) => {
        const r = area.areaActivityTypes.reduce((acc, areaActivityType) => {
            if (areaActivityType.activityType.unplannedShiftCount === 0) {
                return acc;
            }

            const legRoom = areaActivityType.activityType.legRoom;

            return acc + (legRoom <= 5 && legRoom > 0 ? 1 : 0);
        }, 0);

        return acc + r;
    }, 0);

    const redCount = planning.reduce((acc, area) => {
        return acc + area.areaActivityTypes.reduce((acc, areaActivityType) => {
            if (areaActivityType.activityType.unplannedShiftCount === 0) {
                return acc;
            }

            const legRoom = areaActivityType.activityType.legRoom;
            return acc + (legRoom <= 0 ? 1 : 0);
        }, 0);
    }, 0);

    const percentagePlanned = (meanShiftsPerPeriod * 100) / meanUnplannedShiftsPerPeriod;

    return <Pane className="flex gap-2 flex-wrap lg:flex-nowrap">
        <Pane className="flex gap-2">
            <Pane className="bg-gray-300 w-[2px] h-[2.5em]"></Pane>
            <Pane>
                <Heading size={300}>Totaal gepland:</Heading>
                <Text>{totalShifts}</Text>
            </Pane>

            <Pane>
                <Heading size={300}>Nog te plannen:</Heading>
                <Text>{totalUnplannedShifts}</Text>
            </Pane>

            <Pane className="bg-gray-300 mx-2 w-[2px] h-[2.5em]"></Pane>

            <Pane>
                <Heading size={300}>Geel:</Heading>
                <Text>{yellowCount}</Text>
            </Pane>

            <Pane>
                <Heading size={300}>Oranje:</Heading>
                <Text>{orangeCount}</Text>
            </Pane>

            <Pane>
                <Heading size={300}>Rood:</Heading>
                <Text>{redCount}</Text>
            </Pane>

            <Pane className="bg-gray-300 mx-2 w-[2px] h-[2.5em]"></Pane>

            <Pane>
                <Heading size={300}>Gemiddeld gepland per week</Heading>
                <Text>{meanShiftsPerPeriod.toFixed(2)}</Text>
            </Pane>

            <Pane>
                <Heading size={300}>Gemiddeld te plannen per week</Heading>
                <Text>{meanUnplannedShiftsPerPeriod.toFixed(2)}</Text>
            </Pane>

            <Pane>
                <Heading size={300}>Target:</Heading>
                <Text>{percentagePlanned.toFixed(2)}%</Text>
            </Pane>
        </Pane>
    </Pane>
});

export default PlannerAggregations;