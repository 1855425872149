import { Heading, Pane, Text } from "evergreen-ui";
import DurationValue from "../../../components/common/DurationValue";
import TravelDistanceValue from "../../../components/common/TravelDistanceValue";
import { useMemo } from "react";

export function WorkerShiftAggregations({ workerShifts }) {
    const [totalShiftDuration, totalTravelDuration, totalDistanceTraveled] = useMemo(() => {
        let totalDuration = 0;

        const travelDurationMap = new Map<string, number>()
        const distanceMap = new Map<string, number>()

        for (const workerShift of workerShifts) {
            const timeslotUid = workerShift?.shift?.timeslotUid;
            const date = workerShift?.shift?.date;

            totalDuration += parseInt(workerShift.shiftDuration) || 0;

            const travelDuration = workerShift.travelDuration || 0;

            if (travelDuration) {
                travelDurationMap.set(date + timeslotUid, travelDuration);
            }

            if (workerShift.isDriving) {
                const distanceToArea = workerShift.distanceToArea;

                if (distanceToArea) {
                    distanceMap.set(date + timeslotUid, distanceToArea);
                }
            }
        }

        const totalTravelDuration = Array.from(travelDurationMap.values()).reduce((acc, val) => acc + val, 0);
        const totalDistance = Array.from(distanceMap.values()).reduce((acc, val) => acc + val, 0);

        return [totalDuration, totalTravelDuration, totalDistance * 2];
    }, [workerShifts]);

    return <Pane>
        <Pane className="flex gap-4">
            <Pane className="flex flex-col">
                <Text>Gewerkt</Text>
                <Heading size={500}>
                   <DurationValue seconds={totalShiftDuration} />
                </Heading>
            </Pane>
            <Pane className="border-r-4" />
            <Pane className="flex flex-col">
                <Text>Reistijd</Text>
                <Heading size={500}>
                    <DurationValue seconds={totalTravelDuration} />
                </Heading>
            </Pane>
            <Pane className="border-r-4" />
            <Pane className="flex flex-col">
                <Text>Totale afstand</Text>
                <Heading size={500}>
                    <TravelDistanceValue meters={totalDistanceTraveled} />
                </Heading>
            </Pane>
        </Pane>
    </Pane>
}

